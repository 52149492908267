import { Button, Typography} from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    outerDiv: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "15px"
    },
    innerDiv: {
        maxWidth: "800px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    header: {
        //paddingBottom: "15px",
        fontWeight: "bold"
    },
    textHeader: {
        marginTop: "30px",
        fontWeight: "bold",
        width: "100%"
    },
    sourceList: {
        listStyle: "square",
        marginLeft: "35px"
    },
    textJustify: {
        textAlign: "justify"
    },
}))



export default function Organisation() {
    // This component shows the information page about the usage of the application

    const classes = useStyles();

    return (
        <div className={classes.outerDiv}>
            <div className={classes.innerDiv}>

                <Typography gutterBottom className={classes.header} align="center" variant="h3">
                    Aufbauorganisation
                </Typography>
                <br />
                <Typography variant="subtitle1" className={classes.textJustify}>
                    Die Einführung von Smart Services erfordert eine Umstrukturierung der teils historisch geprägten Unternehmensstrukturen.
                    Mit den Smart Service-Aufbauorganisationsmuster wird ein weitreichender Überblick zu möglichen Unternehmensstrukturen vermittelt.
                    Im wesentlichen lässt sich die Smart Service-Aufbauorganisation hinsichtlich des Zentralisations- sowie Integrationsgrads beschreiben.
                    Der Integrationsgrad beschreibt, inwiefern das antizipierte Smart Service-Geschäft in die Unternehmensstruktur integriert wird.
                    Dabei werden die Extrema Separation und Integration unterschieden.
                    Insgesamt enthält der Integrationsgrad fünf Ausbaustufen, das Smart Service-Geschäft im Unternehmen zu verankern.
                    Der Zentralisationsgrad beinhaltet die Ausprägungen Zentralisation und Dezentralisation.
                    Eine zentrale Unternehmensstruktur zeichnet sich dadurch aus, dass die Verantwortlichkeit für das Smart Service-Geschäft an einer Stelle in der Organisation gebündelt wird.
                    Eine dezentrale Unternehmensstruktur hingegen verteilt entsprechende Verantwortlichkeiten im Unternehmen.
                </Typography>
                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Grundlegende Vorgehensweise
                </Typography>
                <Typography variant="subtitle1" className={classes.textJustify}>
                    Die Bewertung des Integrations- und Zentralisationsgrads bildet die Grundlage für den Umgang mit den Mustern der Aufbauorganisation.
                    Durch die Kombination beider Grade ergeben sich zehn Optionen zur Ausgestaltung der Aufbauorganisation für ein erfolgreiches Geschäft mit Smart Services.
                    <br />
                    <br />
                </Typography>
                <img style={{ width: "800px" }} src="/organisation/centralisation.png" alt="Bild nicht gefunden." />

                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Integrationsgrad
                </Typography>
                <Typography variant="subtitle1" className={classes.textJustify}>


                    <table>
                        <thead>
                            <tr>
                                <th
                                    style={{ paddingRight: "109px" }}
                                >Geschäftsaktivitäten: </th>
                                <td>Derzeitig ausgeführte Aktivitäten zur Erbringung einer konkreten Marktleistung.</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th
                                    style={{ paddingRight: "109px" }}
                                >Geschäftskompetenzen:&nbsp; &nbsp; </th>
                                <td>Ein überlegender, wertschöpfender Mechanismus der es erlaubt in einem Geschäftsfeld Nutzenpotentiale zu erschließen.</td>
                            </tr>
                            <tr>
                                <th
                                    style={{ paddingRight: "109px" }}
                                >Operative Aufgaben: </th>
                                <td>Durchgeführte Prozessschritte gemäß des Smart Service-Referenzprozesses (vgl.Prozessmuster).</td>
                            </tr>
                        </tbody>
                    </table>

                </Typography>


                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Zentralisationsgrad
                </Typography>
                <Typography variant="subtitle1" className={classes.textJustify}>



                    <table style={{ tableLayout: "auto" }}>

                        <thead>
                            <tr>
                                <th
                                    style={{ paddingRight: "40px" }}
                                >Synergien zu Geschäftsfeldern und der Infrastruktur:</th>
                                <td>Derzeitige Synergien zu den Geschäftsdimensionen „Wer?“ (Kundensegment), „Was?“ (Marktleistung) und „Wie?“ (Prozesse)</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th
                                    style={{ paddingRight: "30px" }}
                                >Anforderungen an die organisationale Reaktionsfähigkeit:</th>
                                <td>Grad der organisationalen Struktur auf negative Ereignisse zu reagieren.Darunter zählen unter anderem die Kommunikations-wege innerhalb der Organisation.
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <br />
                    <br />
                </Typography>

                <img style={{ width: "1000px", marginBottom: "20px" }} src="/organisation/examplePattern.png" alt="Bild nicht gefunden." />
                <Link to="/organisation/patterns">
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                    >
                        Gesamte Mustersammlung anschauen
                    </Button>
                </Link>
            </div>
        </div >
    );
}
