import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import WorkdesignImage from './workdesignimage.js';

const useStyles = makeStyles((theme) => ({
    outerDiv: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "15px"
    },
    innerDiv: {
        maxWidth: "800px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    header: {
        //paddingBottom: "15px",
        fontWeight: "bold"
    },
    headerarbeitsgestaltung: {
        color: "black",
        backgroundColor: "#e3e3e3",
        borderRadius: "10px",
        paddingTop: "25px",
        paddingBottom: "25px",
        width: "100%"
      },
    textHeader: {
        marginTop: "30px",
        marginBottom: "20px",
        fontWeight: "bold",
        width: "100%"
    },
    textJustify: {
        textAlign: "justify",   
        fontSize: [16, "!important"]
      },
    sourceList: {
        listStyle: "square",
        marginLeft: "35px",
        fontSize: [16, "!important"]    
      },
    sourceLitList: {
        listStyle: "square",
        marginLeft: "15px",
        marginBottom: "10px",
        fontSize: [14, "!important"]    
      },
    imageSourceText: {
        color: "grey",
        fontSize: [10, "!important"],
        textAlign: "center"
      }
}))

export default function WorkDesign() {
    // This component shows the information page about the usage of the application

    const classes = useStyles();

    return (
        <div className={classes.outerDiv}>
            <div className={classes.innerDiv}>

            <Typography gutterBottom className={classes.headerarbeitsgestaltung} align="center" variant="h4">
                    Arbeitsgestaltung
                </Typography>

                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Einführung
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    Die Implementierung von Smart Services im Unternehmen bewirken strukturelle Änderungen im Kontext des sozio-technischen Ansatzes, die zumeist erst in der Praxis ersichtlich werden. Davon ist insbesondere die Arbeitsorganisation, die zu Veränderungen der Arbeitsmethoden und Arbeitsweisen führt, betroffen.             
                    <br />
                    Um diesen Veränderungen zu begegnen, wurden auf Basis des Mosaiks der Arbeitsgestaltung Musterkarten für smarte Mitarbeiter:innen, wie Smarte Vertriebler:innen und Smart Service-Owner:innen (siehe hierzu Fischer, 2021) entwickelt, die allen Interessierten, speziell kleinen und mittleren Unternehmen (KMU), praxisnahe Möglichkeiten zur Gestaltung der Arbeit aufzeigen. 
                    <br />                
                    Das Mosaik der Arbeitsgestaltung gliedert sich in die „klassischen“ Bereiche der Arbeitsgestaltung sowie in die dazugehörigen Aspekte & Beeinflussungsfaktoren der zukünftigen Arbeitsgestaltung. Die Gestaltungsbereiche (innerer Ring) sowie die Aspekte & Beeinflussungsfaktoren (äußerer Ring) sind ringförmig um den zentralen Aspekt der Arbeitsgestaltung angeordnet. Die Aspekte & Beeinflussungsfaktoren tangieren die jeweiligen Gestaltungsbereiche, die einen inhaltlichen Bezug aufweisen. Für jeden Gestaltungsbereich sowie für deren Aspekte & Beeinflussungsfaktoren wurde eine Musterkarte (Muster der Arbeitsgestaltung) erstellt.                    
                    <br />
                    An die Muster der Arbeitsgestaltung gliedern sich die Methoden der Arbeitsgestaltung an, die u. a. den Kompetenzaufbau im Unternehmen prozessintegriert unterstützen. Methoden der Arbeitsgestaltung stellen hierbei z. B. Virtuelles Job-Shadowing, AR / VR – Technologien und Work-Life-Integration dar.     
                    <br />
                    <br />
                    <WorkdesignImage />
                    <br />
                    <p align="middle"> Abb.: 1 &ndash; Mosaik der Arbeitsgestaltung </p>
                </Typography>
                <Typography className={classes.imageSourceText}>
                        (eigene Darstellung in Anlehnung an Jäger 2015, in Petry 2016)<br /><br />
                      </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">   
                    Die Aufbereitung der Informationen erfolgte in Form von Wissenshäppchen, um eine schnelle und anwendungsnahe Rezeption der Inhalte zu ermöglichen. Jede Musterkarte umfasst Informationen zu vertiefender Literatur, um den Anwender:innen den Zugang zum jeweiligen Themenfeld zu erleichtern. Zudem zeigen die Karten Herausforderungen auf oder halten praxisnahe Beispiele bereit.
                    <br />
                    Das Ziel der Musterkarten stellt die Bereitstellung von generischen Empfehlungen und Hilfestellungen zur Orientierung im Kontext der Arbeitsgestaltung für KMU dar. Dabei werden insbesondere Unternehmen angesprochen, die sich im Transformations-prozess zum Smart Service-Anbieter befinden und vor der Herausforderung stehen, ihre Arbeitsgestaltung an die damit einhergehenden veränderten Prozesse anzupassen.                     
                    <br />
                    Als Zielgruppe wird hierbei das mittlere Management adressiert, wobei jede:r Interessierte:r die Musterkarten zu rate ziehen kann.
                    <br />
                    Die Karten können situationsbedingt angewendet werden und müssen nicht einer Reihenfolge entsprechen.
                    <br />
                </Typography>
                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Weiterführende Literatur
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                    <p>&#10146; <b>Fischer, L. M. (2021).</b> Von Kompetenzentwicklung zum Smart Service Anbieter – ein anpassungsfähiger Kompetenzentwicklungsprozess für KMU. In GfA e.V. (Hrsg.), Arbeit HUMAINE gestalten. 67. Frühjahrskongress der Gesellschaft für Arbeitswissenschaft e.V. GfA-Press. Article B.11.3.</p>
                </ol>
                </Typography><br />
                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Literaturverzeichnis
                </Typography>
                                <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                    <p className={classes.sourceLitList}>
                        <b>Altun, U. & Koczy, A. (2018). </b>
                        Checkliste zur Gestaltung orts- und zeitflexibler Arbeit. In ARBEIT(S).WISSEN.SCHAF(F)T Grundlage für Management & Kompetenzentwicklung: 64. Kongress der Gesellschaft für Arbeitswissenschaft : FOM Hochschule für Oekonomie & Management, 21.-23. Februar 2018. GfA-Press. Beitrag C.4.1.
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Arbeitskreis Smart Service Welt / acatech. (2015). </b>
                        Smart Service Welt – Umsetzungsempfehlungen für das Zukunftsprojekt Internet basierte Dienste für die Wirtschaft. Abschlussbericht. Berlin. 

                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Bundesanstalt für Arbeitsschutz und Arbeitsmedizin. (2019). </b>
                        Flexible Arbeitszeitmodelle - Überblick und Umsetzung (2. Aufl.). 
  
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Daum, M., Wedel, M., Zinke-Wehlmann, C. & Ulbrich, H. (Hrsg.). (2020). </b>
                        Gestaltung vernetzt-flexibler Arbeit: Beiträge aus Theorie und Praxis für die digitale Arbeitswelt. Springer Vieweg. 
   
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Deutsche Gesetzliche Unfallversicherung e.V. (2016). </b>
                        Büroraumplanung - Hilfen für das systematische Planen und Gestalten von Büros. DGUV Information, S. 215–441.  
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Dupley, S. (2021). </b>
                        Job shadowing during a pandemic. https://www.universityaffairs.ca/career-advice/responsibilities-may-include/job-shadowing-during-a-pandemic/ .                        
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Fischer, L. M. (2021). </b>
                        Von Kompetenzentwicklung zum Smart Service Anbieter – ein anpassungsfähiger Kompetenzentwicklungsprozess für KMU. In GfA e.V. (Hrsg.), Arbeit HUMAINE gestalten. 67. Frühjahrskongress der Gesellschaft für Arbeitswissenschaft e.V. GfA-Press. Article B.11.3.
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Fortmann, H. R. & Kolocek, B. (Hrsg.). (2018). </b>
                        Arbeitswelt der Zukunft: Trends – Arbeitsraum – Menschen – Kompetenzen. Springer Gabler.                         
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Franken, S. (2016). </b>
                        Demokratisierung der Führung. In S. Franken (Hrsg.), Führen in der Arbeitswelt der Zukunft (S. 119–143). Springer Fachmedien.                    
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Griffith University (Hrsg.). (2022). </b>
                        JOB SHADOWING - Specialist Programs. https://intranet.secure.griffith.edu.au/employment/learning-and-development/specialist-programs/job-shadowing .
   
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Grubert, J., Ofek, E., Pahud, M. & Kristensson, P. O. (2018). </b>
                        The Office of the Future: Virtual, Portable, and Global. IEEE computer graphics and applications, 38(6), S. 125–133.
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Haner, U. E. & Wackernagel, S. (2019). </b>
                        ORGATEC Sonderbericht zur Studie "Wirksame Büro- und Arbeitswelten": Erfolgsfaktoren für eine wirksame Gestaltung von Büro- und Arbeitswelten. Fraunhofer-Institut für Arbeitswirtschaft und Organisation IAO.
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Hasenbein, M. (2020). </b>
                        Der Mensch im Fokus der digitalen Arbeitswelt: Wirtschaftspsychologische Perspektiven und Anwendungsfelder. Springer.                         
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Kaudela-Baum, S. & Altherr, M. (2020). </b>
                        Freiheiten bewusst organisieren – oder: Wie führe ich eine Organisation in die Selbstorganisation? Ansatzpunkte autonomiefördernder Führung. In O. Geramanis & S. Hutmacher (Hrsg.), uniscope. Publikationen der SGO Stiftung. Der Mensch in der Selbstorganisation (S. 125–141). Springer Fachmedien.
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Klötzer, S., Hardwig, T. & Boos, M. (2017).</b>
                        Gestaltung internetbasierter kollaborativer Team- und Projektarbeit. Gruppe. Interaktion. Organisation. Zeitschrift für Angewandte Organisationspsychologie (GIO), 48(4), S. 293–303.
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Kordsmeyer, A.‑C., Mette, J., Harth, V. & Mache, S. (2019). </b>
                        Wie können virtuelle Teams leistungsfördernd geführt werden? Zentralblatt für Arbeitsmedizin, Arbeitsschutz und Ergonomie, 69(5), S. 313–318.
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Lager, H. (2019). </b>
                        Anpassungsfähigkeit in Zeiten der Digitalisierung. Springer Fachmedien. 
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Lang, B. (2021). </b>
                        27 VR Apps for Remote Work, Education, Training, Design Review, and More. https://www.roadtovr.com/vr-apps-work-from-home-remote-office-design-review-training-education-cad-telepresence-wfh/ .  
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Mohokum, M. & Dördelmann, J. (2018). </b>
                        Betriebliche Gesundheitsförderung: Ein Leitfaden für Physiotherapeuten (1. Aufl.). Springer.                         
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Nerdinger, F. W., Blickle, G., Schaper, N. & Solga, M. (2019). </b>
                        Arbeits- und Organisationspsychologie (4. Aufl.). Springer-Lehrbuch. Springer. 
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Paulus, S. (2020). </b>
                        Work-Life-Balance 4.0 – Zentrale Herausforderungen. In S. Wörwag & A. Cloots (Hrsg.), Human Digital Work – Eine Utopie? (S. 269–283). Springer Fachmedien.
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Petry, T. (Hrsg.) (2016). </b>
                        Digital Leadership. Erfolgreich Führen in Zeiten der Digital Economy. Haufe Verlag.
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Prümper, J. (2017). </b>
                        Mobiles Arbeiten 4.0 Anforderungen und Kompetenzen. ATZextra, 22(4), S. 14–15.
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Randel, S. & Schlabbers, M. (2019). </b>
                        Remote Job Shadowing als Scrum Master: voneinander lernen. https://www.inovex.de/de/blog/remote-job-shadowing-scrum-master/ .                        
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Reipschlager, P., Flemisch, T. & Dachselt, R. (2021). </b>
                        Personal Augmented Reality for Information Visualization on Large Interactive Displays. IEEE transactions on visualization and computer graphics, 27(2), S. 1182–1192.
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Reuschl, A. J. & Bouncken, R. B. (2017). </b>
                        Coworking-Spaces als neue Organisationsform in der Sharing Economy. In M. Bruhn & K. Hadwich (Hrsg.), Dienstleistungen 4.0 (S. 185–208). Springer Fachmedien.
                        
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Rothe, C. (Hrsg.). (2009). </b>
                        Haufe-Praxisratgeber. Arbeitsschutz von A - Z: Aktuelle Informationen zum Nachschlagen (5. Aufl.). Haufe. 
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Rüffert, D., Heß, J. & Bullinger-Hoffmann, A. C. (2017). </b>
                        Ergonomie am wissensintensiven Arbeitsplatz - Problemstellung und Lösungsansätze. In H. Breit, A. C. Bullinger-Hoffmann & U. Cantner (Hrsg.), Produktivität von industriellen Dienstleistungen in der betrieblichen Praxis (S. 163–179). Springer Fachmedien.     
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Sandrock, S. & Niehues, S. (2020). </b>
                        CHECKLISTE zur ergonomischen Bewertung von Tätigkeiten, Arbeitsplätzen, Arbeitsmitteln & Arbeitsumgebung. In ifaa - Institut für angewandte Arbeitswissenschaft e.V. (Hrsg.), ifaa-Checklisten (3. Aufl.).                      
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Schlick, C., Bruder, R. & Luczak, H. (2010 ). </b>
                        Arbeitswissenschaft (3. Aufl.). Springer.    
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Tegtmeier, P. (2016). </b>
                        Review zu physischer Beanspruchung bei der Nutzung von Smart Mobile Devices. Bundesanstalt für Arbeitsschutz und Arbeitsmedizin (BAuA). Dortmund / Berlin / Dresden.     
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Thiemann, D., Müller, M. & Kozica, A. (2020). </b>
                        Selbstorganisation in komplexen digitalen Arbeitswelten. In O. Geramanis & S. Hutmacher (Hrsg.), uniscope. Publikationen der SGO Stiftung. Der Mensch in der Selbstorganisation (S. 337–350). Springer Fachmedien.
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Weber, M.‑A., Koczy, A., Stahn, C. & Jeske, T. (2018). </b>
                        Auswirkungen von Produktivitätsmanagement und Digitalisierung auf die Work-Life-Balance. aw&I Conference, 3.
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Werther, S. (Hrsg.). (2021). </b>
                        Coworking als Revolution der Arbeitswelt: Von Corporate Coworking bis zu Workation. Springer.                        
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Winkler, K., Heim, N. & Heinz, T. (2020). </b>
                        Transformationale Führung im Zeitalter der Digitalisierung. In S. Wörwag & A. Cloots (Hrsg.), Human Digital Work – Eine Utopie? (S. 189–204). Springer Fachmedien. 
                   </p>
                   <p className={classes.sourceLitList}>
                        <b>Wörwag, S. & Cloots, A. (Hrsg.). (2020). </b>
                        Human Digital Work - eine Utopie? Erkenntnisse aus Forschung und Praxis zur digitalen Transformation der Arbeit. Springer Gabler.    
                   </p>


                    
                </ol>
                </Typography>

            </div>
        </div >
    );
}
