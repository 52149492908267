import React from 'react';
import { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Tabs, Tab, Menu, IconButton, Snackbar, SvgIcon, Button } from '@material-ui/core';

import StrategyView from './strategyview';
import PatternView from './patternview';
import ModelView from './modelview';
import SaveView from './saveview';
import TutorialView from './tutorialview';
import ValueView from './valueview';
import patternData from './data/pattern.json'
import strategyCompatibility from './data/strategycompatibility.json'
import { pdf, Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/Person';
import RedeemIcon from '@material-ui/icons/Redeem';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import BuildIcon from '@material-ui/icons/Build';
import PeopleIcon from '@material-ui/icons/People';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import StarsIcon from '@material-ui/icons/Stars';
import WarningIcon from '@material-ui/icons/Warning';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { ReactComponent as HandShakeIcon } from '../icons/handshake-outline.svg'
import { ReactComponent as ValueStructureIcon } from '../icons/chemical-weapon.svg'
import { ReactComponent as CostStructureIcon } from '../icons/circle-multiple-outline.svg'

// key for local storage of state
const LOCAL_STORAGE_KEY = 'bmpApp.state'

// This array describes the busines model canvas and each of its elements
const BUSINESS_MODEL = [
  [
    {
      name: "Kundensegmente", id: 0,
      description: "Beschreibt, welche Kunden mit dem Geschäftsmodell bedient werden sollen"
    },
    {
      name: "Nutzenversprechen", id: 2,
      description: "Beschreibt den Nutzen, den die Marktleistung generiert"
    },
    {
      name: "Marktleistung", id: 1,
      description: "Übersetzt die Geschäftsidee in marktfähige Leistungen"
    }],
  [
    {
      name: "Marketingkanäle", id: 3,
      description: "Beschreibt den Weg, über den das Unternehmen mit dem potentiellen Kunden in Kontakt tritt und die Marktleistung erbringt"
    },
    {
      name: "Kundenbeziehung", id: 4,
      description: "Beschreibt die Art und den Intensitätsgrad der zum Kunden aufzubauenden Beziehung"
    }],

  [
    {
      name: "Schlüsselaktivitäten", id: 5,
      description: "Beschreibt die wichtigsten Aufgaben, die zur Realisierung des Nutzenversprechens durchzuführen sind"
    },
    {
      name: "Schlüsselressourcen", id: 6,
      description: "Für die rentable Erfüllung des Nutzenversprechens benötigte Vermögenswerte"
    },
    {
      name: "Wertschöpfungsstruktur", id: 7,
      description: "Legt die Unternehmensposition in der Wertschöpfungskette fest"
    },
    {
      name: "Schlüsselpartner", id: 8,
      description: "Zur Erbringung des Nutzenversprechens benötigte Partner"
    }],

  [
    {
      name: "Kostenstruktur", id: 9,
      description: "Beinhaltet die wichtigsten Kostentreiber im Zusammenhang mit Aufbau und Betrieb des Geschäftsmodells"
    },
    {
      name: "Erlöskonzept", id: 10,
      description: "Beschreibt, wie das Unternehmen das Nutzenversprechen in Erlöse transformiert"
    }],

  [
    {
      name: "Vorteile für den Betreiber", id: 11,
      description: "Vorteile, die nicht durch die anderen Komponenten des Dokumentationsschemas erfasst werden"
    },
    {
      name: "Anreize für den Partner", id: 12,
      description: "Nutzen, der für den Partner durch die Beteiligung am Geschäftsmodell entsteht"
    }
  ],
  {
    name: "Risiken", id: 13,
    description: "Risiken, die mit der Umsetzung des Geschäftsmodells einhergehen"
  }];

// This array contains the coresponding icon for each business model element (sorted by ID)
const ELEMENT_ICONS = [
  <PersonIcon />,
  <ShoppingCartIcon />,
  <RedeemIcon />,
  <MergeTypeIcon />,
  <SvgIcon>
    <HandShakeIcon />
  </SvgIcon>,
  <VpnKeyIcon />,
  <BuildIcon />,
  <SvgIcon>
    <ValueStructureIcon />
  </SvgIcon>,
  <PeopleIcon />,
  <SvgIcon>
    <CostStructureIcon />
  </SvgIcon>,
  <LocalAtmIcon />,
  <TrendingUpIcon />,
  <StarsIcon />,
  <WarningIcon />
]
// This array contains the partial models of the business model
const PARTIAL_MODELS = [
  { name: "Angebotsmodell", id: 0, count: 3 },
  { name: "Kundenmodell", id: 1, count: 2 },
  { name: "Wertschöpfungsmodell", id: 2, count: 4 },
  { name: "Finanzmodell", id: 3, count: 2 },
  { name: "Anreizmodell", id: 4, count: 2 },
]

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    margin: 15,
  },
  tabBarContainer: {
    display: "flex",
    justifyContent: "center",
    justifyItems: "center"
  },
  tab: {
    paddingRight: 20,
    paddingLeft: 20
  },
  contentPaper: {
    margin: 15,
    paddingBottom: "20px",
    height: `calc(100vh - 126px)`,
    overflow: "auto",
  },
  menuContainer: {
    padding: "15px",
  },
  menuIcon: {
    marginBottom: "3px",
    marginRight: "10px"
  },
  snackBar: {
    marginBottom: "10px",
  },
  nextButton: {
    position: "fixed",
    bottom: "10px",
    //left: "50%",
    left: `calc(50vw + 150px)`,
    transform: "translate(-50%, 0%)"
  },
  backButton: {
    position: "fixed",
    bottom: "10px",
    //left: "50%",
    left: `calc(50vw - 150px)`,
    transform: "translate(-50%, 0%)"
  },
});

// Styling for the pdf document
const stylesPDF = StyleSheet.create({
  landscapePage: {
    alignItems: "center",
    alignContents: "center",
    display: "flex",
    flexDirection: "column",
    padding: "60px",
  },
  textPage: {
    alignItems: "left",
    alignContents: "left",
    display: "flex",
    flexDirection: "column",
    padding: "80px",
  },
  bibPage: {
    padding: "80px",
    display: "table-column"
  },
  strategyPage: {
    padding: "80px"
  },
  publicationShortView: {
    top: 0,
    height: "100%",

  },
  publicationShort: {
    fontSize: 16,
    fontFamily: "Roboto",
    width: "120px"
  },
  publication: {
    textAlign: "justify",
    fontSize: 16,
    fontFamily: "Roboto",
    width: "100%",
    marginBottom: 16
  },
  contentsShort: {
    fontSize: 16,
    fontFamily: "Roboto",
  },
  contentsPageNumber: {
    textAlign: "right",
    fontSize: 16,
    fontFamily: "Roboto",
    width: "100%"
  },
  horizontalView: {
    alignItems: "center",
    alignContents: "center",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "row",
  },
  pModelContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    alignContents: "center",
    fontFamily: "Roboto",
  },
  partialModels: {
    background: "lightgrey",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  elementPaper: {
    marginBottom: "5px",
    marginRight: "10px",
    padding: "0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#f2f2f2",
  },
  elementPaperInner: {
    width: "17vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  elementTitleBar: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: "5px",
    color: "#ffffff",
    backgroundColor: "#8ca8cf",
    border: "2px solid #8ca8cf",
    borderRadius: "0px",
    width: "100%"
  },
  icon: {
    maxHeight: "12px",
  },
  impressImageContainer: {
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
    alignContent: "center",
  },
  partialModelTitle: {
    fontSize: 10,
    margin: "5px",
    marginBottom: "5px",
    marginTop: "0px",
    fontFamily: "RobotoBold",
  },
  header: {
    fontSize: 24,
    marginBottom: "20px",
    fontFamily: "RobotoBold",
  },
  headerLandscape: {
    fontSize: 24,
    marginBottom: "10px",
    marginTop: "10px",
    fontFamily: "RobotoBold",
  },
  textBlock: {
    textAlign: "justify",
    fontSize: 16,
    fontFamily: "Roboto",
  },
  smallHeader: {
    fontSize: 18,
    marginBottom: "15px",
    marginTop: "15px",
    fontFamily: "RobotoBold",
  },
  elementTitle: {
    fontSize: 10,
    margin: "5px",
    fontFamily: "RobotoBold",
  },
  elementText: {
    textAlign: "justify",
    fontSize: 8,
    margin: "5px",
    marginBottom: "10px"
  },
  patternPaper: {
    backgroundColor: "#c7c7c7",
    borderBottom: "1px solid #000000",
    minWidth: "17vw",
  },

  userTextPaper: {
  },
  images: {
    maxHeight: "39vh",
    padding: "5px",
    margin: "2px",
    border: "1px solid #000000",
    borderRadius: "1px",
  },
  headLineRight: {
    position: 'absolute',
    fontSize: 12,
    top: 45,
    left: 80,
    right: 80,
    textAlign: 'right',
    color: 'grey',
  },
  headLineLeft: {
    borderBottom: "1px solid grey",
    position: 'absolute',
    fontSize: 12,
    top: 45,
    left: 80,
    right: 80,
    textAlign: 'left',
    color: 'grey',
  },
  copyright: {
    position: 'absolute',
    fontSize: 12,
    bottom: 45,
    left: 0,
    right: 80,
    textAlign: 'right',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 45,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  source: {
    position: 'absolute',
    fontSize: 12,
    bottom: 45,
    left: 80,
    right: 0,
    textAlign: 'left',
    color: 'grey',
  },
});


// Font, which is used in the exported pdf
Font.register({
  family: 'Roboto',
  src: '/Fonts/Roboto-Regular.ttf'
});

Font.register({
  family: 'RobotoBold',
  src: '/Fonts/Roboto-Bold.ttf'
});

Font.register({
  family: 'RobotoSC',
  src: '/Fonts/Roboto-SC.ttf'
});



// icon images for the exported pdf
const ELEMENT_ICON_IMAGES = [
  <Image style={stylesPDF.icon} src={"customicons/account.png"} />,
  <Image style={stylesPDF.icon} src={"customicons/cart.png"} />,
  <Image style={stylesPDF.icon} src={"customicons/wallet-giftcard.png"} />,
  <Image style={stylesPDF.icon} src={"customicons/call-merge.png"} />,
  <Image style={stylesPDF.icon} src={"customicons/handshake-outline.png"} />,
  <Image style={stylesPDF.icon} src={"customicons/key.png"} />,
  <Image style={stylesPDF.icon} src={"customicons/wrench.png"} />,
  <Image style={stylesPDF.icon} src={"customicons/chemical-weapon.png"} />,
  <Image style={stylesPDF.icon} src={"customicons/account-multiple.png"} />,
  <Image style={stylesPDF.icon} src={"customicons/circle-multiple-outline.png"} />,
  <Image style={stylesPDF.icon} src={"customicons/localatm.png"} />,
  <Image style={stylesPDF.icon} src={"customicons/trending-up.png"} />,
  <Image style={stylesPDF.icon} src={"customicons/star-circle.png"} />,
  <Image style={stylesPDF.icon} src={"customicons/alert.png"} />,
]

// get date for generic filename
const current = new Date();
const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`;




export default function MainTabs() {
  // This is the main component, which consists of the programs tabs (Normstrategie, Geschäftsmodellmuster, ...) 
  // Each tab (and also the save/import menu) is a component in a seperate javascript file
  // The data state of the program is managed in this component by using react hooks
  // Underlying components can manipulate the data state by functions given to the component as props
  // The function, which computes the cards, that are shown in the middle of the Geschäftsmodellmuster tab,
  // is also in this main component, because it needs to be used by multiple components


  // use defined styling
  const classes = useStyles();

  // Reference to the ContentPaper (used for automatic scrolling)
  const contentPaperRef = React.createRef();


  // data state variables, used to store essential data such as the selected business model patterns
  const [tabState, setTabState] = React.useState(0);
  const [selectedFilter, setSelectedFilter] = React.useState(-1);
  const [shownPatterns, setShownPatterns] = React.useState(patternData);
  const [selectedPatterns, setSelectedPatterns] = React.useState([]);
  const [selectedStrategy, setSelectedStrategy] = React.useState(-1);
  const [userText, setUserText] = React.useState([]);
  const [currCoveredElements, setCurrCoveredElements] = React.useState([]);
  const [projectName, setProjectName] = React.useState("");
  // workaround to force update of local storage, by changing this variable you can force to write the current state into the local storage
  const [forceUpdate, setForceUpdate] = React.useState(false);

  // Those two functions are used to store the current state locally, so a page refresh
  // is possible, without losing data
  // The local storage is also used for the download functionality

  // This function is only executed once at the program start
  // It itinitalizes the local storage
  useEffect(() => {
    const storedState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
    if (storedState && storedState[0] !== null && storedState[1] !== null) {
      setSelectedPatterns(storedState[0]);
      setShownPatterns(computeShownCards(storedState[0], storedState[1]));
      setSelectedStrategy(storedState[1]);
      setUserText(storedState[2]);
      setProjectName(storedState[3]);
    }
  }, [])

  // This function is executed, whenever the relevant data state changes
  // It updates the local storage
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify([selectedPatterns, selectedStrategy, userText, projectName]))
  }, [selectedPatterns, selectedStrategy, userText, forceUpdate, projectName])

  const computeShownCards = (patternSelection, strategy) => {

    // This function computes the shown patterns in the middle section of the Geschäftsmodellmuster tab
    // It is given a pattern selection and a strategy from which it computes the set of patterns, which can possibly be selected


    // Find patterns with a good compatibility and exclude patterns, which are already selected
    // Every pattern object possesses an array called consistentPatterns with pattern ids of compatible patterns
    var coveredElements = new Set();
    var compatibleIDs = new Set();
    for (var h = 0; h < patternSelection.length; h++) {
      for (var g = 0; g < patternSelection[h].consistentPatterns.length; g++) {

        compatibleIDs.add(patternSelection[h].consistentPatterns[g]);
      }
      // Calculate the element ids of elements, which are already covered by patterns
      for (var content in patternSelection[h].contents)
        coveredElements.add(content)
    }

    setCurrCoveredElements([...coveredElements]);
    // Get compatible pattern object from pattern id
    var compatibles = [];
    for (var j = 0; j < patternData.length; j++) {
      // reset compatible border color of every pattern
      patternData[j].isCompatible = false;

      if (compatibleIDs.has(patternData[j].id)) {
        var currPattern = patternData[j];
        // set border color boolean for compatible patterns
        currPattern.isCompatible = true;
        compatibles.push(currPattern);
      }
    }

    // Filter compatible patterns, so that the following applies to every pair of compatible and selected pattern:
    // Either the compatible pattern only consists of elements which are not covered by the selected pattern OR
    // the compatible pattern is listed as a compatible in the selected patterns consistentPatterns array
    compatibles = compatibles.filter(function (pattern) {
      // boolean, which decides if current pattern complies with the condition
      var complies = true;

      // Iterate over each pattern in patternSelection
      for (var f = 0; f < patternSelection.length; f++) {
        var alreadyCovered = false;
        var currPattern = patternSelection[f];

        // Check if pattern has an element, which is already covered by an selected pattern
        for (var patternContent in pattern.contents) {
          for (var selectionContent in currPattern.contents) {
            if (patternContent === selectionContent)
              alreadyCovered = true
          }
        }
        // If its already covered, check if its listed as consistent
        if (alreadyCovered && !currPattern.consistentPatterns.includes(pattern.id)) {
          // If this is also not the case, the pattern does not comply to the condition
          complies = false;
          break;
        }
      }
      return complies
    })


    // Find non-conflicting patterns in regards to the chosen strategy and sort them in arrays, which contain patterns of one suitability
    var suitabilityZero = [];
    var suitabilityOne = [];
    var suitabilityTwo = [];
    // Iterate over every possible pattern
    pattern_iterator:
    for (var l = 0; l < patternData.length; l++) {
      currPattern = patternData[l]
      // reset strategy border color
      currPattern.suitability = 0;

      // Ignore patterns, which have elements that are already covered
      for (var currContent in currPattern.contents) {
        if (coveredElements.has(currContent)) {
          continue pattern_iterator;
        }
      }
      // Ignore compatible patterns
      if (!compatibleIDs.has(currPattern.id)) {
        // If no strategy is chosen, the suitability is always zero
        if (strategy === -1)
          suitabilityZero.push(currPattern);
        else {
          // Sort pattern in arrays according to the suitability
          for (var m = 0; m < strategyCompatibility.length; m++) {
            if (strategyCompatibility[m].patternID === currPattern.id
              && strategyCompatibility[m].strategyID === strategy) {
              switch (strategyCompatibility[m].suitability) {
                case 0:
                  suitabilityZero.push(currPattern);
                  break;
                case 1:
                  currPattern.suitability = 1;
                  suitabilityOne.push(currPattern);
                  break;
                case 2:
                  currPattern.suitability = 2;
                  suitabilityTwo.push(currPattern);
                  break;
                default:
                  break;
              }
            }
          }
        }
      }
    }



    // Patterns with a good suitability to the chosen strategy are prioritized in the border color and shown order over compatible patterns
    // This means, that a compatible patterns has to be moved, if it would also be in suitabilityOne or suitabilityTwo
    // Also compatibles that don't fit to the chosen strategy, need to be filtered out 
    if (strategy !== -1) {
      compatibles = compatibles.filter(function (pattern) {


        for (var m = 0; m < strategyCompatibility.length; m++) {
          if (strategyCompatibility[m].patternID === pattern.id
            && strategyCompatibility[m].strategyID === strategy) {
            switch (strategyCompatibility[m].suitability) {
              case 1:
                pattern.isCompatible = false;
                pattern.suitability = 1;
                suitabilityOne.push(pattern);
                return false;
              case 2:
                pattern.isCompatible = false;
                pattern.suitability = 2;
                suitabilityTwo.push(pattern);
                return false;
              case 0:
                pattern.suitability = 0;
                return true
              default:
                break;
            }
          }
        }
        return false
      })
    }


    // Return result in right order
    return [...suitabilityTwo, ...suitabilityOne, ...compatibles, ...suitabilityZero];
  }


  const sortPatterns = (patterns) => {
    // This function can be used to sort contents of a set of patterns into an array


    // This array will contain the selected pattern contents, which are sorted in business model elements
    var sortedPatterns = [[], [], [], [], [], [], [], [], [], [], [], [], []]

    // Insert pattern content into right business model element array
    // For instance: Contents concerning the customer segment will be stored in sortedPatterns[0],
    // because customer segment has the business model element id 0
    for (var i = 0; i < patterns.length; i++) {
      for (var currContent in patterns[i].contents) {
        sortedPatterns[currContent].push(patterns[i]);
      }
    }
    return sortedPatterns;
  }



  // PDF document to be generated (draws the business model using react-pdf components)
  const PDFdocument = () => {

    // This array will contain the selected pattern contents, which are sorted in business model elements
    var sortedPatterns = sortPatterns(selectedPatterns);

    // generate object for the headline consisting of date and the current filename

    var dateHeader = current.getDate() + "." + (current.getMonth() + 1) + "." + current.getFullYear()

    var headLine = (
      <>
        <Text style={stylesPDF.headLineLeft} fixed>
          {projectName !== "" ? projectName : "bm2vc-Projekt"}
        </Text>
        <Text style={stylesPDF.headLineRight} fixed>
          {dateHeader}
        </Text>
      </>)


    // generate object for the copyright in the left corner
    var copyright = (
      <Text style={stylesPDF.copyright} fixed>
        {"© Universität Paderborn"}
      </Text>
    )

    // divide selected patterns into groups of 4, so that each page contains 4 patterns
    var selectedPatternsDivided = [];
    var exit = false;
    var i = 0;

    while (!exit) {
      var newArray = [];
      for (var j = 0; j < 4; j++) {
        if (!selectedPatterns[i + j]) {
          exit = true;
          break;
        }
        newArray.push(selectedPatterns[i + j])

      }
      i = i + 4;
      if (newArray.length !== 0)
        selectedPatternsDivided.push(newArray);
    }


    // calculate page numbers for the bottom of every page
    var pageCount = selectedPatternsDivided.length + 4 + (selectedStrategy !== -1);

    var currPage = 1;
    function getPageNumber(increment) {
      currPage = currPage + increment;
      return currPage - increment;
    };
    function resetPageNumber() {
      currPage = 1;
    };

    // calculate number of pattern pages
    var patternPageCount = selectedPatternsDivided.length;

    var currPatternPage = 1;
    function getPatternPageNumber() {
      currPatternPage++;
      return currPatternPage - 1;
    }

    return (


      <Document>

        <Page size="A3" style={stylesPDF.textPage}>
          {headLine}
          {copyright}
          <Text style={stylesPDF.header}>
            Business Model to Value Creation Implementer
          </Text>
          <Text style={stylesPDF.textBlock}>
            Dieses Dokument wurde mit dem Business Model to Value Creation Implementer generiert.
            Es fasst die Ergebnisse aus den vier Phasen der Applikation zusammen.
            Dazu gehören die ausgewählte Normstrategie, das erarbeitete Geschäftsmodell und die gewählten Geschäftsmodellmuster sowie die dazugehörigen Wertschöpfungsprinzipien.
          </Text>

          {/* just for spacing*/}
          <Text style={stylesPDF.smallHeader}>
          </Text>

          <View style={stylesPDF.horizontalView}>
            <Text style={stylesPDF.smallHeader}>
              Inhaltsverzeichnis
            </Text>
            <Text style={[stylesPDF.smallHeader, { textAlign: "right", width: "100%" }]}>
              Seite
            </Text>
          </View>

          <View style={stylesPDF.horizontalView}>
            <Text style={stylesPDF.contentsShort}>
              Einführung
            </Text>
            <Text style={stylesPDF.contentsPageNumber}>
              {getPageNumber(1)}
            </Text>
          </View>
          <View style={stylesPDF.horizontalView}>
            <Text style={stylesPDF.contentsShort}>
              Hinweise zur Verwendung
            </Text>
            <Text style={stylesPDF.contentsPageNumber}>
              {1}
            </Text>
          </View>
          <View style={stylesPDF.horizontalView}>
            <Text style={stylesPDF.contentsShort}>
              Erarbeitetes Geschäftsmodell
            </Text>
            <Text style={stylesPDF.contentsPageNumber}>
              {getPageNumber(1)}
            </Text>
          </View>
          {(selectedStrategy !== -1) && (<View style={stylesPDF.horizontalView}>
            <Text style={stylesPDF.contentsShort}>
              Ausgewählte Normstrategie
            </Text>
            <Text style={stylesPDF.contentsPageNumber}>
              {getPageNumber(1)}
            </Text>
          </View>)}
          <View style={stylesPDF.horizontalView}>
            <Text style={stylesPDF.contentsShort}>
              {"Ausgewählte Muster und dazugehörige Wertschöpfungsprinzipien"}
            </Text>
            <Text style={stylesPDF.contentsPageNumber}>
              {getPageNumber(patternPageCount)}
            </Text>
          </View>
          <View style={stylesPDF.horizontalView}>
            <Text style={stylesPDF.contentsShort}>
              Handlungsempfehlungen
            </Text>
            <Text style={stylesPDF.contentsPageNumber}>
              {getPageNumber(1)}
            </Text>
          </View>
          <View style={stylesPDF.horizontalView}>
            <Text style={stylesPDF.contentsShort}>
              Literaturverzeichnis
            </Text>
            <Text style={stylesPDF.contentsPageNumber}>
              {getPageNumber(1)}
              {resetPageNumber()}
            </Text>
          </View>




          {/* just for spacing*/}
          <Text style={stylesPDF.smallHeader}>
          </Text>
          <Text style={stylesPDF.smallHeader}>
            Impress Projekt
          </Text>
          <Text style={stylesPDF.textBlock}>
            Das IMPRESS-Projekt unterstützt Unternehmen bei der Transformation vom traditionellen Produkthersteller zum Anbieter von Smart Services.
            Für eine erfolgreiche Transformation muss das jeweilige Unternehmen ihre gegenwärtige Wertschöpfung grundlegend ändern.
            Es wird eine neue Geschäftsstrategie, ein neues Geschäftsmodell sowie eine neue Organisationsstruktur benötigt.
            Zu diesem Zweck stellt IMPRESS ein Instrumentarium von Methoden und wiederverwendbaren Lösungen bereit, die bei dem Transformationsprozess unterstützen und schlussendlich zu konkreten Transformationsprojekten führen.
            Zu dem Instrumentarium gehören erfolgsversprechende Vorgehensweisen und Teillösungen in Form von Normstrategien und Geschäftsmodellmustern.
            Es entsteht ein Referenzprozess zur Transformation zum Smart Service Anbieter.
            {"\n"}
            {"\n"}
            Weitere Informationen zum Projekt finden Sie unter: www.impress-project.com
            {"\n"}
            {"\n"}
            {"\n"}
          </Text>
          {/* just for spacing*/}
          <Text style={stylesPDF.smallHeader}>
          </Text>
          <div style={stylesPDF.impressImageContainer}>
            <Image style={{ width: "60%" }} src={"impress.png"} />
          </div>
        </Page>


        <Page size="A3" style={stylesPDF.textPage}>
          {headLine}
          {copyright}
          <Text style={stylesPDF.pageNumber} fixed>
            {getPageNumber(1) + "/" + pageCount}
          </Text>
          <Text style={stylesPDF.header}>
            Einführung
          </Text>

          <Text style={stylesPDF.textBlock}>
            BM2VC Implementer (Business Model to Value Creation Implementer) für Smart Services erlaubt Ihnen die Bildung Strategie-konformer Handlungsempfehlungen zur Umsetzung von Geschäftsmodellen für Smart Services.
            Das Tool stellt dafür Normstrategien und Geschäftsmodellmuster bereit, aus denen Wertschöpfungsprinzipien abgeleitet werden.
            Diese helfen bei der Planung der Transformation zum Anbieter von Smart Services.
            {"\n"}
            Die folgende Abbildung stellt das methodische Vorgehen zur Herleitung von Handlungsempfehlungen für die Umsetzung von Smart Service-Geschäftsmodellen vor und zeigt auf, inwiefern die Applikation in der jeweiligen Phase unterstützt.

          </Text>

          <Image src={"approach.png"} />


          <Text style={stylesPDF.header}>
            Hinweise zur Verwendung
          </Text>

          <Text style={stylesPDF.textBlock}>
            Mit diesem Dokument halten Sie die von Ihnen erarbeiteten Ergebnisse aus dem BM2VC Implementer in Ihren Händen.
            Es fasst jede Eingabe und Auswahl von Ihnen zusammen und liefert zudem Handlungsempfehlungen für das weitere Vorgehen im Transformationsprozess ihres Unternehmens:
            {"\n"}
            {"\n"}
            • Sie können das Dokument zur Hand nehmen, um ihr fertig spezifiziertes <Text style={{ fontFamily: 'RobotoBold' }}>Geschäftsmodell</Text> zu betrachten und genauere Informationen zu gewählten <Text style={{ fontFamily: 'RobotoBold' }}>Geschäftsmodellmustern</Text> nachzuschlagen.
            {"\n"}
            • Nutzen Sie die gewählte <Text style={{ fontFamily: 'RobotoBold' }}>Normstrategie</Text> als Basis für die Erarbeitung Ihrer zukünftigen Smart Service-Strategie.
            Sie besteht aus konsistenten Ausprägungen von strategischen Stellschrauben, die Sie in Ihrem Unternehmen umsetzen sollten.
            {"\n"}
            • Mit den hergeleiteten <Text style={{ fontFamily: 'RobotoBold' }}>Wertschöpfungsprinzipien</Text> und Ihrer individuellen Geschäftsmodellspezifikation können Sie sich konkrete Projekte für die Transformation erarbeiten.
            Eine Kurzanleitung dazu finden Sie im Abschnitt: "Wertschöpfungsprinzipien".
            {"\n"}
            {"\n"}
            Durch dieses Dokument haben Sie alle notwendigen Informationen zum weiteren Vorgehen im Smart Service-Transformationsprozess an einer Stelle.
            Wenn Sie genauere Informationen benötigen, können Sie in den einzelnen Quellen des Literaturverzeichnisses nachschlagen.
          </Text>

        </Page>


        {/* Insert strategy image page */}
        {
          (selectedStrategy !== -1) &&
          (<Page size="A3" style={stylesPDF.strategyPage}>
            {headLine}
            {copyright}
            <Text style={stylesPDF.pageNumber} fixed>
              {getPageNumber(1) + "/" + pageCount}
            </Text>
            <Text style={stylesPDF.source} fixed>
              Quelle: [Kol21]
            </Text>
            <Text style={stylesPDF.header}>
              Ausgewählte Normstrategie
            </Text>
            <Image src={"/strategyimages/" + selectedStrategy + "info.png"} />
          </Page>)
        }

        {/* Page containing the information from the business model tab */}
        <Page orientation="landscape" size="A3" style={stylesPDF.landscapePage}>
          {headLine}
          {copyright}
          <Text style={stylesPDF.pageNumber} fixed>
            {getPageNumber(1) + "/" + pageCount}
          </Text>
          <Text style={stylesPDF.source} fixed>
            Quelle: [EKG17]
          </Text>
          <Text style={stylesPDF.headerLandscape}>
            Erarbeitetes Geschäftsmodell
          </Text>
          <View >
            <View style={stylesPDF.pModelContainer}>
              {PARTIAL_MODELS.map((pModel) => {
                return (

                  <View key={pModel.id} style={stylesPDF.partialModels}>
                    <Text style={stylesPDF.partialModelTitle}>
                      {pModel.name}
                    </Text>
                    {BUSINESS_MODEL[pModel.id].map((element) => {
                      return (
                        // elementPaper needs different styling depending on position
                        <View key={element.id}
                          style={[stylesPDF.elementPaper,
                          ([0, 3, 5, 9, 11].includes(element.id) && { borderTop: "2px solid #004d90" }),
                          ([11, 12].includes(element.id) && { marginRight: "0px" })]}
                        >
                          <View key={element.id + "inner"} style={stylesPDF.elementPaperInner} >
                            <View style={stylesPDF.elementTitleBar}>
                              {ELEMENT_ICON_IMAGES[element.id]}
                              <Text style={stylesPDF.elementTitle}>
                                {"  " + element.name}
                              </Text>
                            </View>
                            <Text style={[stylesPDF.elementText, { fontFamily: "RobotoBold" }]}>
                              {element.description}
                            </Text>

                            {/* Insert selected pattern contents from the sortedPatterns array */}
                            {sortedPatterns[element.id].map((pattern) => (
                              <View style={stylesPDF.patternPaper}>
                                <Text style={stylesPDF.elementText}>
                                  {pattern.name + ":"}
                                  {"\n"}
                                  {pattern.contents[element.id]}
                                </Text>
                              </View>
                            ))}

                            {/* Insert user input  */}
                            <View style={stylesPDF.userTextPaper}>
                              <Text style={stylesPDF.elementText}>
                                {userText[element.id]}
                              </Text>
                            </View>
                          </View>
                        </View>
                      )
                    })}
                  </View>
                )
              })}
            </View>

            {/* Risk element is drawn here */}
            <View key={"13"} style={stylesPDF.elementPaper}>

              <View style={stylesPDF.elementTitleBar}>
                {ELEMENT_ICON_IMAGES[13]}
                <Text style={stylesPDF.elementTitle}>
                  {BUSINESS_MODEL[5].name}
                </Text>
              </View>
              <Text style={[stylesPDF.elementText, { fontFamily: "RobotoBold", width: "100%", paddingLeft: "5px" }]}>
                {BUSINESS_MODEL[5].description}
              </Text>

              {/* Insert user input  */}
              <Text style={[stylesPDF.elementText, { width: "100%", paddingLeft: "5px" }]}>
                {userText[13]}
              </Text>
            </View>
          </View>
        </Page>

        {/* Insert pattern cards of selected patterns (at most 4 per page) */}
        {
          selectedPatternsDivided.map((arr) => {
            return (

              <Page orientation="landscape" size="A3" style={stylesPDF.landscapePage}>
                {headLine}
                {copyright}
                <Text style={stylesPDF.pageNumber} fixed>
                  {getPageNumber(1) + "/" + pageCount}
                </Text>
                <Text style={stylesPDF.source} fixed>
                  Quellen: [EKG17], [RKS+21]
                </Text>
                <Text style={stylesPDF.headerLandscape}>
                  Ausgewählte Muster und dazugehörige Wertschöpfungsprinzipien
                  {(patternPageCount !== 1) && " (" + getPatternPageNumber() + "/" + patternPageCount + ")"}
                </Text>
                <View style={stylesPDF.horizontalView}>
                  {arr.map((element) => {
                    return (
                      <Image style={stylesPDF.images} src={"/cards/" + element.id + ".png"} />
                    )
                  })}
                </View>
                <View style={stylesPDF.horizontalView}>
                  {arr.map((element) => {
                    return (
                      <Image style={stylesPDF.images} src={"/valuecreationimages/" + element.id + ".png"} />
                    )
                  })}
                </View>
              </Page>
            )
          })
        }

        {/* Insert information text from valueview*/}
        <Page size="A3" style={stylesPDF.textPage}>
          {headLine}
          {copyright}
          <Text style={stylesPDF.pageNumber} fixed>
            {getPageNumber(1) + "/" + pageCount}
          </Text>
          <Text style={stylesPDF.source} fixed>
            Quelle: [RKS+21]
          </Text>
          <Text style={stylesPDF.header}>
            Handlungsempfehlungen
          </Text>
          <Text style={stylesPDF.textBlock}>
            Wertschöpfungsprinzipien beinhalten Aussagen über weitere Akteure, die in Ihrem Wertschöpfungsprozess involviert sind.
            Zudem wird die Rolle Ihres Unternehmens, sowie Wechselwirkungen mit anderen Akteuren im Wertschöpfungsprozess beleuchtet.
            Diese Wertschöpfungsprinzipien bieten eine Basis für die Operationalisierung Ihres Smart Service-Geschäfts.
            Auf dieser Seite wird Ihnen eine allgemeine Handlungsempfehlung für das weitere Vorgehen mit diesen Prinzipien beschrieben.
            Diese besteht aus drei Phasen und resultiert in einem Transformationssetup zur Operationalisierung des Smart Service-Geschäfts.
          </Text>
          <Text variant="h5" style={stylesPDF.smallHeader}>
            Phase 1: Zielbild ausgestalten
          </Text>
          <Text variant="subtitle1" style={stylesPDF.textBlock}>
            In der ersten Phase wird das Zielbild für die zukünftige Wertschöpfung definiert.
            Aus jedem Geschäftsmodellmuster geht ein Wertschöpfungsprinzip hervor, welches charakteristische Interaktionen zwischen den wesentlichen Wertschöpfungselementen beschreibt.
            Um ein erstes Modell des zukünftigen Wertschöpfungssystems zu erhalten, müssen die Wertschöpfungsprinzipien aller Muster kombiniert werden.
            Es entsteht ein initiales Wertschöpfungssystem, das als Grundlage des zukünftigen Wertschöpfungssystems dient.
            Um dieses zu erhalten, muss das initiale Wertschöpfungssystem iterativ um Smart Service-spezifische Wertschöpfungsrollen angereichert werden.
          </Text>


          <Text variant="h5" style={stylesPDF.smallHeader}>
            Phase 2: Bedarfsanalyse durchführen
          </Text>
          <Text variant="subtitle1" style={stylesPDF.textBlock}>
            Ziel der zweiten Phase ist die Definition der zur Transformation notwendigen Anpassungsbedarfe.
            Die Ermittlung der einzelnen Bedarfe erfolgt in zwei Schritten.
            Zunächst findet ein Vergleich des zukünftigen Wertschöpfungssystems (Soll-Zustand) mit der aktuellen Situation statt (Ist-Zustand).
            Identifizierte Lücken werden in einer Liste gesammelt und beschreiben nicht existente Wertschöpfungsrollen der aktuellen Wertschöpfungssituation.
            Dabei werden sämtliche fehlende Aktivitäten, Ressourcen, Wertbeiträge und Interaktionen erfasst.
            Anschließend folgt eine Bewertung der Wertschöpfungslücken.
            Hier wird geprüft, ob Wertschöpfungslücken vom Unternehmen selbst oder durch externe Akteure geschlossen werden sollen.
            Dazu wird die strategische Relevanz (Differenzierungspotential zum Wettbewerb) und die Erreichbarkeit (notwendiger Aufwand, mögliche Partner, etc.) zur Schließung der Wertschöpfungslücke untersucht.
            Es entsteht eine Menge von Handlungsempfehlungen für die Besetzung der einzelnen Wertschöpfungsrollen.
          </Text>


          <Text variant="h5" style={stylesPDF.smallHeader}>
            Phase 3: Transformation planen
          </Text>
          <Text variant="subtitle1" style={stylesPDF.textBlock}>
            In der letzten Phase findet die Umsetzungsplanung statt.
            Hierzu werden die zuvor ermittelten Transformationsbedarfe in operative Projekte überführt.
            Es müssen Projekte aufgestellt werden, die zur Erreichung des zukünftigen Wertschöpfungssystems durchgeführt werden müssen.
            Hierzu werden unter anderem Ziele definiert, eine Ressourcenplanung durchgeführt und eine Projektorganisation aufgesetzt.
            Nach der Durchführung dieser Projekte kann das Smart Service-Geschäft in Betrieb genommen werden.
          </Text>

        </Page>


        {/* Insert bibliography*/}
        <Page size="A3" style={stylesPDF.bibPage}>
          {headLine}
          {copyright}
          <Text style={stylesPDF.pageNumber} fixed>
            {getPageNumber(1) + "/" + pageCount}
          </Text>

          <Text style={stylesPDF.header}>
            Literaturverzeichnis
          </Text>

          <View style={stylesPDF.horizontalView}>

            <View style={stylesPDF.publicationShortView}>
              <Text style={stylesPDF.publicationShort}>
                [EKG17]
              </Text>
            </View>
            <Text style={stylesPDF.publication}>
              <Text style={{ fontFamily: "RobotoSC" }}>
                Echterhoff, B.; Koldewey, C.; Gausemeier, J.:
              </Text>
              Pattern based business model development – identification, structuring and application of business model patterns. In: ISPIM Innovation Forum. März 2017, Toronto, 2017
              {"\n"}
            </Text>
          </View>

          <View style={stylesPDF.horizontalView}>
            <View style={stylesPDF.publicationShortView}>
              <Text style={stylesPDF.publicationShort}>
                [GB04]
              </Text>
            </View>
            <Text style={stylesPDF.publication}>
              <Text style={{ fontFamily: "RobotoSC" }}>
                Gausemeier, J.; Bätzel, D.:
              </Text>
              Entwicklung intelligenter technologieorientierter Geschäfts- und Produktstrategien. Zeitschrift für wirtschaftlichen Fabrikbetrieb 99 (6), Carl Hanser Verlag, München, 2004, S. 290–295
            </Text>
          </View>

          <View style={stylesPDF.horizontalView}>
            <View style={stylesPDF.publicationShortView}>
              <Text style={stylesPDF.publicationShort}>
                [KFG+20]
              </Text>
            </View>
            <Text style={stylesPDF.publication}>
              <Text style={{ fontFamily: "RobotoSC" }}>
                Koldewey, C.; Frank, M.; Gausemeier, J.; Bäsecke, A.; Chohan, N.; Reinhold, J.:
              </Text>
              Systematische Entwicklung von Normstrategien für Smart Services. Zeitschrift für wirtschaftlichen Fabrikbetrieb 115 (7-8), De Gruyter, Berlin, 2020, S. 524–528
            </Text>
          </View>

          <View style={stylesPDF.horizontalView}>
            <View style={stylesPDF.publicationShortView}>
              <Text style={stylesPDF.publicationShort}>
                [Kol21]
              </Text>
            </View>
            <Text style={stylesPDF.publication}>
              <Text style={{ fontFamily: "RobotoSC" }}>
                Koldewey, C.:
              </Text>
              Systematik zur Entwicklung von Smart Service-Strategien im produzierenden Gewerbe. Verlagsschriftenreihe des Heinz Nixdorf Insituts 399, Heinz Nixdorf Institut, Paderborn, 2021
            </Text>
          </View>

          <View style={stylesPDF.horizontalView}>
            <View style={stylesPDF.publicationShortView}>
              <Text style={stylesPDF.publicationShort}>
                [OP10]
              </Text>
            </View>
            <Text style={stylesPDF.publication}>
              <Text style={{ fontFamily: "RobotoSC" }}>
                Osterwalder, A.; Pigneur, Y.:
              </Text>
              Business Model Generation – A Handbook for Visionaries, Game Changers, and Challengers. Wiley, New Jersey, 2010
            </Text>
          </View>

          <View style={stylesPDF.horizontalView}>
            <View style={stylesPDF.publicationShortView}>
              <Text style={stylesPDF.publicationShort}>
                [RKS+21]
              </Text>
            </View>
            <Text style={stylesPDF.publication}>
              <Text style={{ fontFamily: "RobotoSC" }}>
                Reinhold, J.; Ködding, P.; Scholtysik, M.; Koldewey, C.; Dumitrescu, R.:
              </Text>
              Smart Service-Transformation mit Geschäftsmodellmustern. Zeitschrift für wirtschaftlichen Fabrikbetrieb 116 (5), De Gruyter, Berlin, 2021, S. 337–341
            </Text>
          </View>


        </Page>

      </Document >
    );
  }


  // This function generates the pdf document, when the button is clicked
  const generatePdfDocument = async () => {
    const blob = await pdf((
      <PDFdocument />
    )).toBlob();
    var name = (projectName === "") ? date + "_bm2vc_result.pdf" : date + "_" + projectName + ".pdf";
    saveAs(blob, name);
  };

  // Menu and snackbar states
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");


  // Handle menu open
  const handleMenuClick = (event) => {
    setForceUpdate(!forceUpdate);
    setAnchorEl(event.currentTarget);
  };
  // Handle menu close
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle tab changes and set content of tabs
  const handleChange = (event, newValue) => {
    setForceUpdate(!forceUpdate)
    setTabState(newValue);
  }

  // reference for the menu button, so that it can be triggered by other buttons (Ergebnis als PDF Exportieren)
  const menuClickRef = React.useRef(null)

  // Handle arrow click 
  const handleArrowClick = (left) => {
    if (left && (tabState !== 0))
      handleChange(null, tabState - 1)
    if (!left && (tabState !== 4))
      handleChange(null, tabState + 1)
    if (!left && tabState === 4) {
      // create event object with position information of the menu button, so that handleMenuClick has an Anchor to place the menu
      var e = { currentTarget: menuClickRef.current };
      handleMenuClick(e);
    }
  };

  // This array consists of the individual tab components
  // Functions that are needed are passed with props
  const tabs_contents = [
    <TutorialView setTabState={setTabState} contentPaperRef={contentPaperRef} />,
    <StrategyView selectedStrategy={selectedStrategy} setSelectedStrategy={setSelectedStrategy}
      setShownPatterns={setShownPatterns} setSelectedFilter={setSelectedFilter}
      computeShownCards={computeShownCards} selectedPatterns={selectedPatterns}
      setSelectedPatterns={setSelectedPatterns} setTabState={setTabState} contentPaperRef={contentPaperRef} />,
    <PatternView selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter}
      shownPatterns={shownPatterns} setShownPatterns={setShownPatterns}
      selectedPatterns={selectedPatterns} setSelectedPatterns={setSelectedPatterns}
      selectedStrategy={selectedStrategy} setSelectedStrategy={setSelectedStrategy}
      computeShownCards={computeShownCards} forceUpdate={forceUpdate} currCoveredElements={currCoveredElements}
      BUSINESS_MODEL={BUSINESS_MODEL} PARTIAL_MODELS={PARTIAL_MODELS} ELEMENT_ICONS={ELEMENT_ICONS} contentPaperRef={contentPaperRef} />,
    <ModelView selectedPatterns={selectedPatterns} userText={userText} setUserText={setUserText}
      forceUpdate={forceUpdate} setForceUpdate={setForceUpdate} BUSINESS_MODEL={BUSINESS_MODEL}
      PARTIAL_MODELS={PARTIAL_MODELS} ELEMENT_ICONS={ELEMENT_ICONS} sortPatterns={sortPatterns} contentPaperRef={contentPaperRef} />
    , <ValueView selectedPatterns={selectedPatterns} contentPaperRef={contentPaperRef} />];


  // This component renders the save/import menu
  const menuComponent = <SaveView
    setSelectedFilter={setSelectedFilter} setShownPatterns={setShownPatterns}
    setSelectedPatterns={setSelectedPatterns} setSelectedStrategy={setSelectedStrategy}
    setUserText={setUserText} LOCAL_STORAGE_KEY={LOCAL_STORAGE_KEY} setSnackBarOpen={setSnackBarOpen}
    setSnackBarMessage={setSnackBarMessage}
    setTabState={setTabState} handleMenuClose={handleMenuClose} computeShownCards={computeShownCards}
    generatePdfDocument={generatePdfDocument} projectName={projectName} setProjectName={setProjectName} setForceUpdate={setForceUpdate} forceUpdate={forceUpdate} />;


  // Return the whole content, which needs to be rendered
  return (
    <div>
      {/* Paper container for the tab bar at the top */}
      <Paper className={classes.root}>
        <div className={classes.tabBarContainer}>

          {/* Left Arrow-Button*/}
          <IconButton
            disabled={(tabState === 0)}
            onClick={() => { handleArrowClick(true) }}
          >
            <ArrowBackIos
              style={{ ...(tabState === 0 && { color: "white" }) }} />
          </IconButton>

          {/* Navigation-Tabs*/}
          <Tabs
            value={tabState}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"

          >
            <Tab className={classes.tab}
              label="Anleitung"
              value={0} />
            <Tab className={classes.tab}
              label="Normstrategie"
              value={1} />
            <Tab className={classes.tab}
              label="Geschäftsmodellmuster"
              value={2} />
            <Tab className={classes.tab}
              label="Geschäftsmodell"
              value={3} />
            <Tab className={classes.tab}
              label="Wertschöpfungsprinzipien"
              value={4}
            />
          </Tabs>

          {/* Menu-Button */}
          <IconButton
            ref={menuClickRef}
            className={classes.menuIcon}
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </IconButton>

          {/* Right Arrow-Button*/}
          <IconButton
            disabled={(tabState === 4)}
            onClick={() => { handleArrowClick(false) }}
          >
            <ArrowForwardIos
              style={{ ...(tabState === 4 && { color: "white" }) }} />
          </IconButton>

        </div>
      </Paper >


      {/* Main content of a tab is integrated in a container paper called contentPaper
        The tab switch works by changing the tabState value, so tabs_contents[tabState] changes accordingly */}
      < Paper ref={contentPaperRef} className={classes.contentPaper} >
        {tabs_contents[tabState]}
      </Paper >

      {/* Save menu */}
      < Menu
        id="simple-menu"
        anchorEl={anchorEl}

        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }
        }
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <div className={classes.menuContainer} >
          {menuComponent}
        </div>
      </Menu >

      {/* snackbar */}
      < Snackbar
        className={classes.snackBar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={(event, reason) => {
          // Ignore snackbar clickaway
          if (reason === "clickaway")
            return
          setSnackBarOpen(false);
        }}
        message={snackBarMessage}
        action={
          < Fragment >
            <IconButton size="small" color="inherit"
              onClick={() => setSnackBarOpen(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Fragment >
        }
      />

      {/* next button*/}
      <Button
        className={classes.nextButton}
        variant="contained"
        size="small"
        color="default"
        onClick={() => { handleArrowClick(false) }}
      >
        {
          (tabState === 0 && "Normstrategie wählen") ||
          (tabState === 1 && "Geschäftsmodellmuster wählen") ||
          (tabState === 2 && "Geschäftsmodell vervollständigen") ||
          (tabState === 3 && "Wertschöpfungsprinzipien anzeigen") ||
          (tabState === 4 && "Ergebnis als PDF exportieren")
        }
      </Button>

      <a href="/">
        <Button
          className={classes.backButton}
          variant="contained"
          size="small"
          color="default"
        >
          Zurück zum Start
        </Button>
      </a>
    </div >
  );
}