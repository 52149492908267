import { Button, Modal, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    outerDiv: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "15px"
    },
    innerDiv: {
        maxWidth: "1200px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    header: {
        //paddingBottom: "15px",
        fontWeight: "bold"
    },
    textHeader: {
        marginTop: "30px",
        fontWeight: "bold",
        width: "800px",
    },
    sourceList: {
        listStyle: "square",
        marginLeft: "35px"
    },
    textJustify: {
        textAlign: "justify",
        width: "800px"
    },
    modalImage: {
        marginTop: "20px",
        border: "2px solid",
        borderColor: "#000000",
    },
    modalPaper: {
        height: "95vh",
        overflow: "auto",
        overflowY: "auto",
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),

        display: "flex",
        alignItems: "center",
        flexDirection: "column",

        //Position the modal in center of the screen
        position: "fixed",
        top: "50%",
        left: "50%",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
    patternContainer: {
        marginTop: "30px",
        marginBottom: "30px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "1000px",
        height: "95vh",
        overflowY: "scroll"
    },
}))



export default function Competencies() {
    // This component shows the information page about the usage of the application

    const classes = useStyles();

    // States for the information modals
    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalContent, setModalContent] = React.useState(1);


    const patternDescriptions = [
        (
            <>
                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Kompetenzmuster
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    <p>
                        Die Smart Service spezifischen Schlüsselkompetenzen in Form von Kompetenzmuster dienen den zu
                        Smart Service-Anbieter transformierenden Unternehmen als Orientierung und Überblick, womit sich
                        erste Tendenzen in Bezug auf Kompetenzlücken insbesondere auf Mitarbeitenden-Ebene aufzeigen
                        lassen.
                        Die Anwendung dieses Instruments bietet sich insbesondere bei zu entwickelten Rollenprofilen und
                        bevorstehenden GAP-Analysen (siehe KEP) an, die hierbei unterstützend hinzugezogen werden
                        können.
                    </p>
                    <br />
                    <br />
                </Typography>
            </>
        ),
        (
            <>
                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Rollenprofile
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    <p>
                        Bei der Kompetenzentwicklung von Mitarbeitenden (siehe KEP) auf Mitarbeitenden-Ebene) im
                        Unternehmen, wird ausgehend von der Anforderungserhebung (Phase I) die GAP-Analyse (Phase II)
                        durchgeführt und eine Genüberstellung der Soll-Ist-Kompetenzprofile der betreffenden Rolle
                        getätigt. Dadurch ergab sich ein verändertes Rollenprofil – vom klassischen Vertriebler hin zum
                        Smarten Vertriebler. Zusätzlich wurde durch die sich wandelnden Anforderungen innerhalb des
                        Transformationsprozesses zum Smart Service-Anbieter deutlich, dass die Notwendigkeit der
                        Etablierung einer zusätzlichen Rolle – des Smart Service-Owners (SmS-Owner) – besteht. Beide
                        Rollenprofile sind nachfolgend abgebildet.
                    </p>
                    <br />
                    <br />
                </Typography>
            </>
        ),
        (
            <>
                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Lernkatalog
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    <p>
                        Der Lernkatalog stellt KMU praxisnahe Lernkonzepte und Vermittlungsmethoden zum Aufbau von
                        Kompetenzen bei Mitarbeitenden sowie von Lern- und Qualifizierungsprozessen in der Organisation
                        zur Verfügung. Hierbei richtet sich der Lernkatalog insbesondere an Führungskräften und
                        interessierte Mitarbeitende in KMU.
                    </p>
                    <br />
                    <br />
                </Typography>
            </>
        ),
        (
            <>
                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Musterkarten der Arbeitsgestaltung
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    <p>
                        Die Musterkarten der Arbeitsgestaltung unterstützen den Prozess der Entwicklung und Bereitstellung
                        von Smart Services in Hinblick auf die Arbeits- und Organisationsgestaltung in KMU. Dabei werden
                        Smarte Mitarbeitende (z. B. Smarte Vertriebler, Smart Service-Owner (siehe hierzu Phase II im KEP))
                        als Adressaten fokussiert. Das entwickelte Konzept ermöglicht somit die Systematisierung der
                        unterschiedlichen Kernaspekte von Arbeitsgestaltung in Verbindung mit dem Thema Arbeit 4.0.
                    </p>
                    <br />
                    <br />
                </Typography>
            </>
        ),
        (
            <>
                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Recruiting-Box
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    <p>
                        Ab einem gewissen Fortschritt in einem Transformationsprozess stellt sich die Frage, ob neues
                        Personal benötigt wird und wenn ja, wie dieses am besten erreicht werden kann. Im Rahmen des KEP
                        erfolgt nach der Anforderungserhebung (Phase I) und der Definition eines Soll-Kompetenzprofiles die
                        Gegenüberstellung der bisher im Unternehmen vorhandenen Kompetenz und der tatsächlich
                        benötigten (Phase II). Hieraus ergibt sich eine Differenz, die Kompetenzlücke. Hieran schließt die
                        Recruiting-Box an, die Unternehmen strategische sowie auf die Zielgruppe Smarte Mitarbeitende
                        ausgerichtete Möglichkeiten zum Rekrutieren von neuen Mitarbeitenden aufzeigt.
                    </p>
                    <br />
                    <br />
                </Typography>
            </>
        ),
        (
            <>
                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Smart Service-Road-Map
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    <p>
                        Die Smart Service-Road-Map unterstützt KMU bei der Ausgestaltung des Transformationsprozesses
                        vom produzierenden Unternehmen hin zum Smart Service-Anbieter. Dabei wird der Fokus auf die
                        partizipative Prozessgestaltung der Beteiligten und Interessierten sowie auf die Befähigung der
                        Mitarbeitenden unter deren proaktiven Teilnahme im Unternehmen gelegt. Die
                        Moderationsmethoden unterstützen hierbei den Transformationsprozess.
                    </p>
                    <br />
                    <br />
                </Typography>
            </>
        ),
    ]

    const fileNames = [
        "Kompetenzmuster.pdf",
        "Rollenprofile.pdf",
        "Lernkatalog.pdf",
        "Arbeitsgestaltung.pdf",
        "Recruiting-Strategie.pdf",
        "Roadmap.pdf",
    ]

    const patternNumberOfImages = [
        [
            ...Array(25).keys()
        ],
        [
            ...Array(4).keys()
        ],
        [
            ...Array(44).keys()
        ],
        [
            ...Array(87).keys()
        ],
        [
            ...Array(35).keys()
        ],
        [
            ...Array(32).keys()
        ],
    ]

    const getContent = (id) => {
        return (
            <div className={classes.patternContainer}>
                {
                    patternNumberOfImages[id - 1].map((path) =>
                        (<img className={classes.modalImage} width="800px" src={"/competencies/" + id + "/" + path + ".png"} alt="Bild nicht gefunden" />))
                }
            </div>
        )
    }

    const StyledButton = (props) => {
        return (<Button
            size="large"
            variant="contained"
            color="primary"
            {...props}>
            {props.children}
        </Button>)
    }

    // Handle modals, which show further information about the strategy
    const handleModalOpen = () => {
        setModalOpen(true);
    };
    const handleModalClose = () => {
        setModalOpen(false);
    };

    return (
        <div className={classes.outerDiv}>
            <div className={classes.innerDiv}>

                <Typography gutterBottom className={classes.header} align="center" variant="h3">
                    Kompetenzen
                </Typography>
                <br />
                <Typography variant="subtitle1" className={classes.textJustify}>
                    Der Kompetenzentwicklungsprozesses (KEP) stellt einen Prozess dar, womit die auf die Smart Service
                    spezifischen Kompetenzanforderungen sowie Anforderungen im Bereich Arbeit auf Mitarbeitenden-
                    Ebene und Unternehmensebene abgebildet werden können. Der KEP gliedert sich hierbei pro Ebene
                    in drei Phasen, die unternehmensspezifisch, je nach Bedarf durchlaufen werden können und somit
                    individuell anpassbar sind. Weiterhin sind bei den Phasen Musterergebnisse aus dem Projekt
                    IMPRESS hinterlegt, die als zusätzliche Unterstützung beim Durchlaufen des KEP dienen.
                    <br />
                    Die hinterlegten Muster für den Kompetenzentwicklungsprozess können durch das Anklicken der mit einem Rand hervorgehobenen Felder aufgerufen und heruntergeladen werden.
                    <br />
                    <br />
                    <br />
                    <br />
                </Typography>






                <svg
                    id="main"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x={0}
                    y={0}
                    viewBox="0 0 3303.8 2505.3"
                    style={{
                        enableBackground: "new 0 0 3303.8 2505.3",
                    }}
                    xmlSpace="preserve"
                >
                    <style>{".st0{fill:none}"}</style>
                    <image
                        style={{
                            overflow: "visible",
                            enableBackground: "new",
                        }}
                        width={3195}
                        height={2420}
                        xlinkHref="/competencies/kep.png"
                        transform="scale(1.034)"
                    />
                    <g id="clickLayer"
                        fillOpacity="0"
                        stroke="#3b91cf"
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeOpacity="1"
                        strokeWidth="1"

                    >
                        <path
                            onClick={() => {
                                setModalContent(1);
                                handleModalOpen();
                            }}
                            key="kompetenzmodelle"
                            id="svgClickElementThick2"
                            d="m740.5 592.4 98-238.8V365 234.6H1432v126.9l92.4 230.9-129.7 325.8H873.5z"
                        />
                        <path
                            onClick={() => {
                                setModalContent(2);
                                handleModalOpen();
                            }}
                            key="rollenprofile"
                            id="svgClickElementThick2"
                            d="m2026.1 376.9-62.7 216 96.6 325.3h196.2l97.8-325-65.5-216.3z"
                        />
                        <path
                            onClick={() => {
                                setModalContent(3);
                                handleModalOpen();
                            }}
                            key="lernkatalog"
                            id="svgClickElementThick2"
                            d="m2421.9 592.2 63.6-215.3h262.8l63.6 215.3-96.9 325.3h-196.7z"
                        />
                        <path
                            onClick={() => {
                                setModalContent(4);
                                handleModalOpen();
                            }}
                            key="arbeitsgestaltung"
                            id="svgClickElementThick2"
                            d="M2710.4 1716.4h-209.1c-8.9 0-16.1-7.2-16.1-16.1v-53.1c0-8.9 7.2-16.1 16.1-16.1h209.1c8.9 0 16.1 7.2 16.1 16.1v53.1c0 8.9-7.2 16.1-16.1 16.1z"
                        />
                        <path
                            onClick={() => {
                                setModalContent(5);
                                handleModalOpen();
                            }}
                            key="recruitingansaetze"
                            id="svgClickElementThick2"
                            d="M2710.4 1998.1h-209.1c-8.9 0-16.1-7.2-16.1-16.1v-53.1c0-8.9 7.2-16.1 16.1-16.1h209.1c8.9 0 16.1 7.2 16.1 16.1v53.1c0 8.8-7.2 16.1-16.1 16.1z"
                        />
                        <path
                            onClick={() => {
                                setModalContent(6);
                                handleModalOpen();
                            }}
                            key="smsroadmap"
                            id="svgClickElementThick2"
                            d="M689.7 2312.9h2588.7v144.7H689.7z"
                        />
                        {/*
                        <path
                            key="hover1"
                            d="M1073.6 846.2H856.4c-6.6 0-12-5.4-12-12l-.4-339.6c0-6.6 5.4-12 12-12h217.3c6.6 0 12 5.4 12 12l.3 339.6c.1 6.6-5.4 12-12 12z"
                        />
                        <path
                            key="hover2"
                            d="M1414.5 584.2h-216.9c-6.5 0-11.9-5.5-12-12.2l-.5-78c0-6.7 5.3-12.2 11.8-12.2h216.9c6.5 0 11.9 5.5 12 12.2l.5 78c.1 6.7-5.2 12.2-11.8 12.2z"
                        />
                        <path
                            key="hover3"
                            d="M1414.4 847.3h-217c-6.6 0-12.1-5.4-12.1-12.1l-.1-181.8c0-6.7 5.4-12.1 12.1-12.1h217c6.6 0 12.1 5.4 12.1 12.1l.1 181.8c0 6.7-5.4 12.1-12.1 12.1z"
                        />
                        <path
                            key="hover4"
                            d="m1573.3 595.6 62.3-218.7h263.1l64.7 216-97.4 324.6h-196z"
                        />
                        <path
                            key="hover5"
                            d="m2811.9 592.2 64.1-215.3h262l64.7 215.3-97.4 325.3-196 .7z"
                        />
                    */ }
                    </g>
                </svg>

                <StyledButton
                    style={{ marginTop: "10px" }}
                    href={"/competencies/Kompetenzentwicklungsprozess.pdf"}
                    download={"Kompetenzentwicklungsprozess.pdf"}
                >
                    KEP mit zusätzlichen Erläuterungen als PDF herunterladen
                </StyledButton>
            </div>
            <Modal key={"contentModal"}
                open={modalOpen}
                onClose={handleModalClose}
            >
                <div className={classes.modalPaper}>
                    {patternDescriptions[modalContent - 1]}
                    <StyledButton
                        style={{ marginTop: "5px" }}
                        href={"/competencies/" + fileNames[modalContent - 1]}
                        download={fileNames[modalContent - 1]}
                    >
                        Als PDF herunterladen
                    </StyledButton>
                    {getContent(modalContent)}
                    <StyledButton
                        onClick={handleModalClose}>
                        Ansicht schließen
                    </StyledButton>
                </div>
            </Modal>
        </div >
    );
}
