import { Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import PlanningImage from './planningImage';

const useStyles = makeStyles((theme) => ({
    outerDiv: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "15px"
    },
    innerDiv: {
        maxWidth: "800px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    header: {
        //paddingBottom: "15px",
        fontWeight: "bold"
    },
    textHeader: {
        marginTop: "30px",
        fontWeight: "bold",
        width: "100%"
    },
    sourceList: {
        listStyle: "square",
        marginLeft: "35px"
    },
    textJustify: {
        textAlign: "justify"
    },
}))



export default function Startpage() {
    // This component shows the information page about the usage of the application

    const classes = useStyles();

    return (
        <div className={classes.outerDiv}>
            <div className={classes.innerDiv}>

                <Typography gutterBottom className={classes.header} align="center" variant="h3">
                    Willkommen bei der IMPRESS-Webapp
                </Typography>
                <br />
                <Typography variant="subtitle1" className={classes.textJustify}>
                    Die Transformation vom reinen Produkthersteller hin zum Smart Service-Anbieter ist keineswegs
                    trivial. Unternehmen müssen Ihre, teils historisch geprägten, Strukturen an den Anforderungen von
                    Smart Service anpassen. Das im Forschungsprojekt entwickelte Referenzmodell zur Planung eines
                    Smart Service-Geschäfts strukturiert die wesentlichen Bereiche der Transformation anhand drei
                    Ebenen: Wertorientierung, Wertangebot sowie Wertschöpfung. Insgesamt umfasst das Modell neun
                    Teilbereiche, die maßgeblich von der Einführung von Smart Service beeinflusst werden.
                    Im folgendem werden die einzelnen Ebenen sowie die dazugehörigen Teilbereiche näher erläutert.
                    <br />
                    Nähere Informationen zu den einzelnen Teilbereichen erfahren Sie durch einen Klick auf den jeweiligen Bereich der unten dargestellten Abbildung.
                    <br />
                    <br />
                    <br />
                </Typography>



                <PlanningImage />

                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Wertorientierung
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    <p>
                        Im Rahmen der Wertorientierung wird die strategische Ausrichtung des Smart Service-Geschäfts
                        fokussiert. Die Entwicklung einer spezifischen Smart Service-Strategie bildet dabei den Kern der
                        Wertorientierung. Hierbei werden grundlegende Entscheidungen hinsichtlich des Aufbaus eines
                        Smart Service-Geschäfts sowie ein konkretes Leitbild definiert.
                    </p>
                </Typography>
                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Wertangebot
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    <p>
                        Das Wertangebot umfasst die konkret anzubietende Marktleistung. Folglich wird im Rahmen des
                        Wertangebots der Smart Service auf technischer Ebene spezifiziert. Hieraus ergeben sich erste
                        Maßnahmen bezüglich des unternehmenseigenen Engineering-Prozesse. Weiterhin wird das
                        dazugehörige Geschäftsmodell für den Smart Service entwickelt.
                    </p>
                </Typography>
                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Wertschöpfung
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    <p>
                        Die Wertschöpfung umfasst alle Aktivitäten und Ressourcen, um das Smart Service-Geschäft zu
                        operationalisieren. Dies umfasst sowohl die innerbetriebliche Wertschöpfung wie die Aufbau- und
                        Ablauforganisation eines Unternehmens, sowie die zwischenbetriebliche Wertschöpfung
                        (Wertschöpfungsstrukturen). Weiterhin bedarf es der Spezifikation der Arbeitsgestaltung sowie den
                        benötigten Kompetenzen für das Smart Service-Geschäft.
                    </p>
                </Typography>

                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Impress Projekt
                </Typography>

                <Typography className={classes.textJustify} variant="subtitle1">
                    Das IMPRESS-Projekt unterstützt Unternehmen bei der Transformation vom traditionellen Produkthersteller zum Anbieter von Smart Services.
                    Für eine erfolgreiche Transformation muss das jeweilige Unternehmen ihre gegenwärtige Wertschöpfung grundlegend ändern.
                    Es wird eine neue Geschäftsstrategie, ein neues Geschäftsmodell sowie eine neue Organisationsstruktur benötigt.
                    Zu diesem Zweck stellt IMPRESS ein Instrumentarium von Methoden und wiederverwendbaren Lösungen bereit, die bei dem Transformationsprozess unterstützen und schlussendlich zu konkreten Transformationsprojekten führen.
                    Zu dem Instrumentarium gehören erfolgsversprechende Vorgehensweisen und Teillösungen in Form von Normstrategien und Geschäftsmodellmustern.
                    Es entsteht ein Referenzprozess zur Transformation zum Smart Service Anbieter.
                    <br />
                    <br />
                    <img style={{ marginLeft: 175 }} width={450} src={"impress.png"} alt={"Kein Bild gefunden"} />

                    <br />
                    <br />
                    <br />
                    <br />
                    <Link
                        style={{ marginRight: "20px" }}
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.impress-project.com/">
                        Impress-Projekt
                    </Link>
                    <Link
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.impress-project.com/impressum/">
                        Impressum
                    </Link>
                </Typography>
            </div>
        </div >
    );
}
