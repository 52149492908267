import { useEffect } from 'react';
import { Button, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    outerDiv: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "15px"
    },
    innerDiv: {
        maxWidth: "800px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    header: {
        //paddingBottom: "15px",
        fontWeight: "bold"
    },
    textHeader: {
        marginTop: "30px",
        fontWeight: "bold",
        width: "100%"
    },
    sourceList: {
        listStyle: "square",
        marginLeft: "35px"
    },
    textJustify: {
        textAlign: "justify"
    },
}))



export default function TutorialView({ setTabState, contentPaperRef }) {
    // This component shows the information page about the usage of the application

    // Automatic scrolling to top, whenever tab changes
    useEffect(() => {
        window.scrollTo(0, 0);
        contentPaperRef.current.scrollTo(0, 0);
    }, [contentPaperRef]);

    const classes = useStyles();

    return (
        <div className={classes.outerDiv}>
            <div className={classes.innerDiv}>

                <Typography gutterBottom className={classes.header} align="center" variant="h3">
                    Willkommen beim Business Model to Value Creation Implementer für Smart Services
                </Typography>
                <Typography variant="subtitle1" className={classes.textJustify}>
                    BM2VC Implementer (Business Model to Value Creation Implementer) für Smart Services erlaubt Ihnen die Bildung Strategie-konformer Handlungsempfehlungen zur Umsetzung von Geschäftsmodellen für Smart Services.
                    Das Tool stellt hierfür Normstrategien und Geschäftsmodellmuster bereit, aus denen Wertschöpfungsprinzipien abgeleitet werden.
                    Diese helfen bei der Planung der Transformation zum Anbieter von Smart Services.
                    Sie können Ihr erarbeitetes Geschäftsmodell jederzeit speichern und zurücksetzen sowie ein bereits angefertigtes Modell importieren.
                    Nutzen Sie hierfür das Menü am rechten Rand der oberen Navigationsleiste.
                    Dort ist es außerdem möglich ihr fertiges Geschäftsmodell als PDF-Datei zu exportieren, welche die erarbeiteten Lösungen und zusätzliche Hinweise zum weiteren Vorgehen beinhaltet.
                    Mit den Pfeilen der Navigationsleiste können Sie zum nächsten bzw. vorherigen Schritt des Vorgehens springen.
                    Alternativ können Sie direkt einzelne Abschnitte über einen Klick auf das entsprechende Navigationselement erreichen.
                    <br />
                    Die folgende Abbildung stellt das methodische Vorgehen zur Herleitung von Handlungsempfehlungen für die Umsetzung von Smart Service-Geschäftsmodellen vor und zeigt auf, inwiefern die Applikation in der jeweiligen Phase unterstützt.
                    Eine ausführlichere Beschreibung folgt nach der Abbildung.
                </Typography>
                <br />



                <img width={800} src={"approach.png"} alt={"Kein Bild gefunden"} />


                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => { setTabState(1) }}
                >
                    Mit der Gestaltung beginnen
                </Button>


                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Phase 1: Smart Service-Strategie festlegen:
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    <p>
                        Normstrategien bilden die Basis für Ihre zukünftige Smart Service-Strategie.
                        Die Wahl einer Normstrategie beeinflusst die Menge der auswählbaren Geschäftsmodellmuster.
                        Eine Auswahl findet im "Normstrategie"-Reiter der Navigationsleiste statt.
                    </p>
                    <br />
                    Weitere Informationen zum Vorgehen finden Sie in diesen Publikationen:
                    <br />

                    <ul className={classes.sourceList}>
                        <li>
                            <Link
                                href="https://digital.ub.uni-paderborn.de/doi/10.17619/UNIPB/1-1167"
                                target="_blank" rel="noreferrer"
                                variant="inherit">
                                Systematik zur Entwicklung von Smart Service-Strategien im produzierenden Gewerbe
                            </Link>

                        </li>
                        <li>
                            <Link
                                href="https://www.researchgate.net/publication/343835077_Systematische_Entwicklung_von_Normstrategien_fur_Smart_Services"
                                target="_blank" rel="noreferrer"
                                variant="inherit">
                                Systematische Entwicklung von Normstrategien für Smart Services
                            </Link>
                        </li>
                        <li>
                            <Link
                                href="https://www.degruyter.com/document/doi/10.3139/104.100780/html"
                                target="_blank" rel="noreferrer"
                                variant="inherit">
                                VITOSTRA – Ein neues Verfahren auf Basis von Strategieprojekten
                            </Link>
                        </li>
                    </ul>
                </Typography>

                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Phase 2: Smart Service-Geschäftsmodell vorausfüllen:
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    <p>
                        Geschäftsmodellmuster nutzen bewährte Lösungen für Teile des Geschäftsmodells.
                        Sie können sich Geschäftsmodellmuster im Smart Service-Kontext aussuchen, die in Ihr Geschäftsmodell integriert werden.
                        Dies geschieht im "Geschäftsmodellmuster"-Reiter.
                    </p>
                    <br />
                    Weitere Informationen zum Vorgehen finden Sie in diesen Publikationen:
                    <br />

                    <ul className={classes.sourceList} >
                        <li>
                            <Link
                                href="https://www.degruyter.com/document/doi/10.1515/zwf-2021-0069/html"
                                target="_blank" rel="noreferrer"
                                variant="inherit">
                                Smart Service-Transformation mit Geschäftsmodellmustern
                            </Link>
                        </li>
                        <li>
                            <a target="_blank" rel="noreferrer" href="https://www.researchgate.net/publication/317234245_Pattern_based_business_model_development_-_identification_structuring_and_application_of_business_model_patterns">Pattern based business model development – identification, structuring and application of business model patterns</a>
                        </li>
                    </ul>

                </Typography>

                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Phase 3: Smart Service-Geschäftsmodell anreichern:
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    <p>
                        In der Geschäftsmodell-Ansicht können Sie Ihr Geschäftsmodell fertig ausgestalten.
                        Das Anreizmodell und die Risiken, die mit der Umsetzung des Geschäftsmodells einhergehen, werden nicht durch Muster abgedeckt und müssen hier konkretisiert werden, genauso wie andere Elemente, die möglicherweise noch nicht beschrieben sind.
                        Nutzen Sie hierfür die Textboxen der jeweiligen Geschäftsmodellelemente.
                        Informationen zu den einzelnen Elementen des Geschäftsmodellrahmens werden Ihnen überhalb der Textboxen angezeigt, sowie Inhalte von Mustern, die ausgewählt wurden.
                    </p>
                    <br />
                    Weitere Informationen zum Vorgehen finden Sie in diesen Publikationen:
                    <br />

                    <ul className={classes.sourceList}>
                        <li>
                            <Link
                                href="https://www.researchgate.net/publication/317234245_Pattern_based_business_model_development_-_identification_structuring_and_application_of_business_model_patterns"
                                target="_blank" rel="noreferrer"
                                variant="inherit">
                                Pattern based business model development – identification, structuring and application of business model patterns
                            </Link>
                        </li>
                        <li>
                            <Link
                                href="https://www.researchgate.net/publication/236650466_Business_Model_Generation_A_Handbook_for_Visionaries_Game_Changers_and_Challengers"
                                target="_blank" rel="noreferrer"
                                variant="inherit">
                                Business Model Generation: A Handbook for Visionaries, Game Changers, and Challengers
                            </Link>
                        </li>
                    </ul>
                </Typography>

                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Phase 4: Umsetzungsempfehlungen ableiten:
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    <p>
                        In dieser Ansicht sehen Sie die Wertschöpfungsprinzipien der ausgewählten Geschäftsmodellmuster.
                        Sie liefern Informationen über Interaktionen von notwendigen Akteuren im Wertschöpfungsprozess.
                    </p>
                    <br />
                    Weitere Informationen zum Vorgehen finden Sie in diesen Publikationen:
                    <br />

                    <ul className={classes.sourceList}>
                        <li>
                            <Link
                                href="https://www.degruyter.com/document/doi/10.1515/zwf-2021-0069/html"
                                target="_blank" rel="noreferrer"
                                variant="inherit">
                                Smart Service-Transformation mit Geschäftsmodellmustern
                            </Link>
                        </li>
                    </ul>

                </Typography>

                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Impress Projekt
                </Typography>

                <Typography className={classes.textJustify} variant="subtitle1">
                    Das IMPRESS-Projekt unterstützt Unternehmen bei der Transformation vom traditionellen Produkthersteller zum Anbieter von Smart Services.
                    Für eine erfolgreiche Transformation muss das jeweilige Unternehmen ihre gegenwärtige Wertschöpfung grundlegend ändern.
                    Es wird eine neue Geschäftsstrategie, ein neues Geschäftsmodell sowie eine neue Organisationsstruktur benötigt.
                    Zu diesem Zweck stellt IMPRESS ein Instrumentarium von Methoden und wiederverwendbaren Lösungen bereit, die bei dem Transformationsprozess unterstützen und schlussendlich zu konkreten Transformationsprojekten führen.
                    Zu dem Instrumentarium gehören erfolgsversprechende Vorgehensweisen und Teillösungen in Form von Normstrategien und Geschäftsmodellmustern.
                    Es entsteht ein Referenzprozess zur Transformation zum Smart Service Anbieter.
                    <br />
                    <br />
                    <img style={{ marginLeft: 175 }} width={450} src={"impress.png"} alt={"Kein Bild gefunden"} />

                    <br />
                    <br />
                    <br />
                    <br />
                    <Link
                        style={{ marginRight: "20px" }}
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.impress-project.com/">
                        Impress-Projekt
                    </Link>
                    <Link
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.impress-project.com/impressum/">
                        Impressum
                    </Link>
                </Typography>
            </div>
            {/* <PlanningImage /> */}
        </div >
    );
}
