import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { Link } from 'react-router-dom';


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    margin: 15,
  },
  contentPaper: {
    margin: 15,
    paddingBottom: "20px",
    height: `calc(100vh - 63px)`,
    overflow: "auto",
  },
  backButton: {
    position: "fixed",
    bottom: "10px",
    left: "50%",
    transform: "translate(-50%, 0%)",
  },
});




export default function Main({ Component, isStartpage }) {
  // This is the main component, which embeds all content into a scrollable container


  // use defined styling
  const classes = useStyles();

  // Return the whole content, which needs to be rendered
  return (
    <div>

      {/* Main content is integrated in a container paper called contentPaper*/}
      < Paper className={classes.contentPaper} >



        <Component />
      </Paper >
      <Link to="/">
        <Button
          className={classes.backButton}
          variant="contained"
          size="small"
          color="default"
          style={{ ...(isStartpage === true && { display: "none" }) }}
          startIcon={<ArrowBack style={{marginBottom: "4px"}}/>}
        >

          Zurück zum Start
        </Button>
      </Link>
    </div >
  );
}