import React from 'react';
import { useEffect } from 'react';
import { makeStyles, Grid, Link, Typography, Card, CardActions, CardContent, CardMedia, Button, ButtonBase, Modal, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import strategyData from './data/strategy.json'
import strategyCompatibility from './data/strategycompatibility.json';
import InfoIcon from '@material-ui/icons/Info';






const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: "100%",
  },
  headerText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 15,
    fontWeight: "bold",
    fontSize: 26
  },
  descriptionText: {
    textAlign: "justify",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    maxWidth: "800px",
  },
  card: {
    maxWidth: 255,
    height: "100%",
    display: 'flex',
    alignItems: 'center',
    flexDirection: "column",
    boxShadow: theme.shadows[5],
  },
  cardButtonArea: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    height: "100%"
  },
  buttonBase: {
    display: "flex",
    maxHeight: 250,
    flexDirection: "column",
  },
  modalPaper: {
    height: "95vh",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),

    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    //Position the modal in center of the screen
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  nextButton: {
    marginTop: "20px",
  },
}));

export default function StrategyView({ selectedStrategy, setSelectedStrategy, setShownPatterns, setSelectedFilter,
  computeShownCards, selectedPatterns, setSelectedPatterns, setTabState, contentPaperRef }) {
  // This is the strategy component, in which a strategy can be chosen

  const classes = useStyles();

  // Automatic scrolling to top, whenever tab changes
  useEffect(() => {
    window.scrollTo(0, 0);
    contentPaperRef.current.scrollTo(0, 0);
  }, [contentPaperRef])


  // Style for the selected strategy card
  const selectedCardStyle = {
    border: '3px solid',
    borderColor: "limegreen",
  }
  // States for the information modals
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalContent, setModalContent] = React.useState("");


  // Handle modals, which show further information about the strategy
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  // states for the change strategy warning dialog
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogStrategy, setDialogStrategy] = React.useState(-1);

  // Handle dialogs, and reset strategy if user confirms
  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = (confirmed) => {
    setDialogOpen(false);
    if (confirmed)
      resetStrategy(dialogStrategy)
  };

  // handle selection of a new strategy
  const handleStrategySelection = (id) => {
    if (selectedStrategy === id || selectedPatterns.length === 0) {
      resetStrategy(id)
      return
    }
    setDialogStrategy(id)
    handleDialogClickOpen();
  };


  const resetStrategy = (newStrategyID) => {
    // This function resets the strategy and filters out selected patterns, which are not compatible to the new Strategy

    var newPatternSelection = [];
    if (newStrategyID !== selectedStrategy) {
      // filter out selected patterns, which are not suitable to the strategy, if a new strategy is selected
      for (var i = 0; i < selectedPatterns.length; i++) {
        var currPattern = selectedPatterns[i];
        // Check if pattern occurs in the set of compatible patterns of the strategy
        for (var j = 0; j < strategyCompatibility.length; j++) {
          if (strategyCompatibility[j].patternID === currPattern.id
            && strategyCompatibility[j].strategyID === newStrategyID) {
            // Only add compatible patterns to new selection
            newPatternSelection.push(currPattern);
            break;
          }
        }
      }
    }

    // Reset filter
    setSelectedFilter(-1);

    // If strategy is deselected, no patterns need to be filtered
    if (newStrategyID === selectedStrategy) {
      setShownPatterns(computeShownCards(selectedPatterns, -1))
      setSelectedStrategy(-1)
    } else {
      setSelectedPatterns(newPatternSelection);
      setShownPatterns(computeShownCards(newPatternSelection, newStrategyID));
      setSelectedStrategy(newStrategyID);
    }
  }




  return (
    <div id="root" className={classes.root}>

      {/* Description text */}
      <Typography variant={"h2"} className={classes.headerText}>
        Wählen Sie eine Normstrategie aus:
      </Typography>

      <Typography gutterBottom variant={"subtitle1"} className={classes.descriptionText}>
        Normstrategien bilden die Basis für Ihre zukünftige Smart Service-Strategie.
        Sie enthalten konsistente Ausprägungen von strategischen Stellschrauben, auf deren Grundlage das Geschäftsleitbild ausgearbeitet werden kann.
        Wählen Sie eine der sechs Smart Service-Normstrategien durch einen Klick auf die Kachel aus.
        Sie können über den Infobutton weitere Informationen zur jeweiligen Normstrategie erhalten.
        Diese beinhalten eine Kurzbeschreibung sowie die Ausprägung der strategischen Variablen.
        Nach der Auswahl wird die gewählte Normstrategie grün hinterlegt.
        Danach können Sie mit der Auswahl von Geschäftsmodellmustern fortfahren.
      </Typography>

      {/* Main content, consisting of the strategy cards in a grid */}
      <Grid container className={classes.grid} spacing={3}>
        <Grid item xs={6}>
          <Grid container justify="center" spacing={3} >

            {/* Cards are constructed from each strategy in the strategy.json file */}
            {strategyData.map((tile) => (
              <Grid key={tile.id} item>

                {/* Strategy Card*/}
                <Card className={classes.card}

                  // Apply border styling to the selected strategy
                  style={{ ...(selectedStrategy === tile.id && selectedCardStyle) }}>

                  {/* Upper part of the card, which is clickable */}
                  <ButtonBase className={classes.buttonBase}
                    onClick={event => { handleStrategySelection(tile.id) }}
                  >

                    <CardMedia
                      component="img"
                      alt=""
                      height="150"
                      src={"/strategyimages/" + tile.id + ".png"}
                      title="Normstrategie"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {tile.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {tile.description}
                      </Typography>
                    </CardContent>

                  </ButtonBase>

                  {/* Lower part of the strategy card, which contains the buttons */}
                  <div className={classes.cardButtonArea}   >
                    <CardActions>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setModalContent(tile.id);
                          handleModalOpen();
                        }
                        }
                        size="small">
                        <InfoIcon />
                      </Button>

                      <Button
                        variant="contained"
                        color="default"
                        onClick={event => { handleStrategySelection(tile.id) }}
                        size="small">
                        Strategie wählen
                      </Button>
                    </CardActions>
                  </div>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>


      {/* Button for changing to the next tab */}
      {/*
      <Button
        variant="contained"
        size="small"
        color="primary"
        className={classes.nextButton}
        onClick={() => { setTabState(2) }}
      >
        Zu den Geschäftsmodellmustern
      </Button>
      /*}

      {/* Modal for strategy information */}
      <Modal key={"strategyModal"}
        open={modalOpen}
        onClose={handleModalClose}
      >
        <div className={classes.modalPaper}>
          <Typography variant="h5">
            Weitere Informationen zur Normstrategie
          </Typography>

          <img style={{ paddingTop: "40px", height: "80vh", minHeight: "770px" }} src={"/strategyimages/" + modalContent + "info.png"} alt={"Kein Bild gefunden"} />

          <Typography>
            <Link
              href="https://digital.ub.uni-paderborn.de/doi/10.17619/UNIPB/1-1167"
              target="_blank" rel="noreferrer"
              variant="inherit">
              Quelle: Koldewey
            </Link>
          </Typography>

          <Button
            style={{ marginTop: "20px" }}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleModalClose}>
            Ansicht schließen
          </Button>
        </div>
      </Modal>

      {/* Warning dialog, when a strategy is changed */}
      <Dialog
        open={dialogOpen}
        onClose={() => { handleDialogClose(false) }}
      >
        <DialogTitle id="alert-dialog-title">{"Möchten Sie die Normstrategie wirklich ändern?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Wenn Sie die Normstrategie ändern werden gegebenenfalls ausgewählte Muster abgewählt, die nicht mehr
            kompatibel zur neuen Normstrategie sind.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { handleDialogClose(false) }} color="primary">
            Alte Normstrategie behalten
          </Button>
          <Button onClick={() => { handleDialogClose(true) }} color="primary" autoFocus>
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>

    </div >
  );
}