import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Link, Modal, CardMedia, List, ListItem, ListItemIcon, ListItemText, Divider, ListSubheader, Button, Box, GridList, Card, CardActionArea, CardActions, CardContent, Typography
} from '@material-ui/core'

import patternData from './data/pattern.json'

import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import SearchBar from "material-ui-search-bar";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import TextTruncate from 'react-text-truncate';


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    filterList: {
        justifyContent: "flex-start",
        width: "100%",
        maxWidth: 360,
        backgroundColor: "#fcfcfc",
    },
    searchBar: {
        marginBottom: "4px",
        marginTop: "3px",
        boxShadow: "0px 1px 1px lightgray",
        height: "40px"
    },
    patternFinder: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        borderColor: "#f0f0f0",
        border: "1px solid",
        borderTop: "0px",
        //padding: "2px"
    },
    patternFinderInner: {
        display: "flex",
        justifyContent: "space-between"
    },
    patternSelection: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        marginRight: 20,
        maxWidth: 340,
        width: "100%",
        borderColor: "#f0f0f0",
        borderLeft: "1px solid",
    },
    listButtons: {
        maxHeight: "40px",
        marginTop: 20,
        marginLeft: 70
    },
    gridList: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        height: "80vh",
    },
    patternCard: {
        maxWidth: 305,
        minWidth: 250,
        margin: 30,
        display: "flex",
        flexDirection: "column",
    },
    cardAction: {
        display: 'block',
        textAlign: 'initial'
    },
    selectedPatternButtonArea: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        marginBottom: "10px"
    },
    shownCardLowerContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        height: "100%",
        paddingBottom: "5px"
    },
    cardButtonArea: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
    },
    modalPaper: {
        maxHeight: "95vh",
        overflow: "auto",
        width: 650,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),

        // align items in center
        display: "flex",
        alignItems: "center",
        flexDirection: "column",

        //position the modal in center of the screen
        position: "absolute",
        top: "50%",
        left: "50%",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
    patternImage: {
        marginTop: "25px",
        padding: "5px",
        border: "1px solid #000000",
        borderRadius: "1px",
    },
    canvasModalPaper: {
        maxHeight: "95vh",
        maxWidth: "90vw",
        overflow: "auto",
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),

        // align items in center
        display: "flex",
        alignItems: "center",
        flexDirection: "column",

        //position the modal in center of the viewport
        position: "absolute",
        top: "50%",
        left: "50%",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
    modalTextBlock: {
        textAlign: "justify",
        maxWidth: "800px",
    },
    colorCaptions: {
        marginLeft: "20px"
    },
    limitAddButtonHeight: {
        maxHeight: "40px"
    }
}));

export default function PatternView({ selectedFilter, setSelectedFilter, shownPatterns, setShownPatterns, selectedPatterns,
    setSelectedPatterns, selectedStrategy, setSelectedStrategy, computeShownCards, currCoveredElements,
    BUSINESS_MODEL, PARTIAL_MODELS, ELEMENT_ICONS, contentPaperRef }) {
    const classes = useStyles();
    // This component contains the Geschäftsmodellmuster tab, in which the user can look for new patterns and select patterns

    // Automatic scrolling to top, whenever tab changes
    useEffect(() => {
        window.scrollTo(0, 0);
        contentPaperRef.current.scrollTo(0, 0);
    }, [contentPaperRef])

    // States for the information modals, which show further information about the pattern and business model framework
    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalContent, setModalContent] = React.useState("");
    const [canvasModalOpen, setCanvasModalOpen] = React.useState(false);

    // State for the search bar
    const [searched, setSearched] = React.useState("");


    // Hook to force rerender of searchbar, when value changes
    const [randKey, setRandKey] = React.useState("1");

    // Handle opening of information modals
    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleCanvasModalOpen = () => {
        setCanvasModalOpen(true);
    };

    const handleCanvasModalClose = () => {
        setCanvasModalOpen(false);
    };


    // Reset content of search bar when component is drawn
    useEffect(() => {
        //setShownPatterns(computeShownCards(selectedPatterns, selectedStrategy))
        setSearched("");
        // force rerender of searchbar with empty value
        setRandKey(Math.random().toString(36));
    }, [])

    const requestSearch = (searchedVal) => {
        // This function filters the shown patterns, according to the input of the searchbar


        // If no filter is selected, the shownPatterns state is updated with patterns, that contain the search input as a substring in the pattern name
        if (selectedFilter === -1)
            setShownPatterns(computeShownCards(selectedPatterns, selectedStrategy).filter((pattern) => {
                var pName = pattern.name;
                return pName.toUpperCase().includes((searchedVal).toUpperCase());
            }))
        // If a filter is selected, the filter must be applied first before checking for pattern names
        else {
            var shownCards = computeShownCards(selectedPatterns, selectedStrategy);
            var filtered = []
            for (var i = 0; i < shownCards.length; i++) {
                for (var content in shownCards[i].contents) { // eslint-disable-line
                    if (shownCards[i].contents[selectedFilter]) {
                        filtered.push(shownCards[i])
                        break;
                    }
                }
            }
            setShownPatterns(filtered.filter((pattern) => {
                var pName = pattern.name;
                return pName.toUpperCase().includes((searchedVal).toUpperCase());
            }))
        }
    };

    // Cancel search and reset shown patterns
    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };


    const handleCardClick = (index) => {
        // Function to handle the selection of a new pattern
        var clickedPattern = patternData[index];
        var newSelection = [...selectedPatterns, clickedPattern];
        var shownCards = computeShownCards(newSelection, selectedStrategy);
        // apply new state
        if (!selectedPatterns.includes(clickedPattern))
            setSelectedPatterns(selectedPatterns.concat(clickedPattern));
        setShownPatterns(shownCards)
        setSelectedFilter(-1);
        setSearched("");
        // force rerender of searchbar with empty value
        setRandKey(Math.random().toString(36));
    }
    const handleCardDeleteClick = (event, id) => {
        // Function to handle deselection of a selected pattern

        // Update pattern selection and recompute patterns to show 
        var newSelection = selectedPatterns.filter(function (element) {
            return element.id !== id;
        })
        setShownPatterns(computeShownCards(newSelection, selectedStrategy));
        setSelectedPatterns(newSelection);
        setSelectedFilter(-1);

        setSearched("");
        // Force rerender of searchbar with empty value prop
        setRandKey(Math.random().toString(36));
    }


    const handleFilterListItemClick = (event, index) => {
        // Function to handle a click on the FilterList
        // It calculates the patterns, which contain the business model element, that is selected in the list

        // Compute shown cards
        var shownCards = computeShownCards(selectedPatterns, selectedStrategy);

        var filtered = []
        if (selectedFilter === index) {
            // If filter is deselected, shown cards don't need to be filtered
            filtered = shownCards;
        } else {
            // If a new filter is selected, only show cards that contain at least an element of the specified filter
            for (var i = 0; i < shownCards.length; i++) {
                for (var content in shownCards[i].contents) { // eslint-disable-line
                    if (shownCards[i].contents[index]) {
                        filtered.push(shownCards[i])
                        break;
                    }
                }
            }
        }

        // Update state
        setShownPatterns(filtered);
        setSearched("");
        // Force rerender of searchbar with empty value
        setRandKey(Math.random().toString(36));

        if (selectedFilter === index)
            setSelectedFilter(-1)
        else
            setSelectedFilter(index)

    };


    return (<Box className={classes.root}>


        {/* Container for the leftside list of business model elements, that are used to filter shown patterns */}
        <div className={classes.filterList}>
            {/* Business model canvas info button */}
            <Button
                className={classes.listButtons}
                variant="contained"
                color="primary"
                onClick={() => handleCanvasModalOpen()}
                size="medium">
                <>
                    {"Weitere Informationen"}
                    <InfoIcon style={{ marginLeft: "5px" }} />

                </>
            </Button>


            {/* Draw filterList using the business model canvas arrays from maintabs.js*/}
            {PARTIAL_MODELS.map((pModel) => {
                return ((pModel.id !== 4) && (

                    <List component="nav"
                        // First item in the list has a header 
                        subheader={(pModel.id === 0) && (
                            <ListSubheader disableSticky={true} component="div" id="filterlist-subheader">
                                Filter
                            </ListSubheader>)
                        }>
                        {
                            BUSINESS_MODEL[pModel.id].map((element) => {
                                return (
                                    <ListItem
                                        key={element.id}
                                        button
                                        selected={selectedFilter === element.id
                                        }
                                        onClick={(event) => handleFilterListItemClick(event, element.id)}
                                    >
                                        <ListItemIcon>
                                            {ELEMENT_ICONS[element.id]}
                                        </ListItemIcon>
                                        <ListItemText primary={element.name} />
                                        <ListItemIcon>
                                            {(currCoveredElements.includes(element.id + "")) && <CheckCircleIcon />}
                                        </ListItemIcon>
                                    </ListItem>
                                )

                            })
                        }
                        < Divider />
                    </List>
                ))
            })}

            <ListSubheader disableSticky={true} component="div" id="filterlist-subheader">
                Legende
            </ListSubheader>
            {/* Captions of the different border colors */}
            <div className={classes.colorCaptions}>
                <Typography style={{ marginTop: "20px" }} align="left" variant="body1">
                    <FiberManualRecordIcon style={{ color: "green", marginRight: "5px" }} fontSize="inherit" />
                    Verpflichtend für die ausgewählte Normstrategie
                </Typography>
                <Typography style={{ marginTop: "10px" }} align="left" variant="body1">
                    <FiberManualRecordIcon style={{ color: "lightgreen", marginRight: "5px" }} fontSize="inherit" />
                    Komplementär zur ausgewählten Normstrategie
                </Typography>
                <Typography style={{ marginTop: "10px" }} align="left" variant="body1">
                    <FiberManualRecordIcon style={{ color: "darkturquoise", marginRight: "5px" }} fontSize="inherit" />
                    Komplementär zu den ausgewählten Mustern
                </Typography>
                <Typography style={{ marginTop: "10px" }} align="left" variant="body1">
                    <CheckCircleIcon style={{ marginRight: "5px" }} fontSize="inherit" />
                    Element bereits durch ausgew. Muster abgedeckt
                </Typography>
            </div>
        </div >


        {/* Container for the middle part, which shows avaiblable patterns for selection */}
        <div className={classes.patternFinder}>
            <div className={classes.patternFinderInner}>

                <ListSubheader disableSticky={true} component="div" id="finderlist-subheader">
                    Geschäftsmodellmuster
                </ListSubheader>
                {/* Searchbar */}
                <SearchBar
                    key={randKey}
                    className={classes.searchBar}
                    placeholder="Suche"
                    value={searched}
                    onChange={(searchVal) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                />

            </div>
            {/* Function, which generates the pattern finder section */}
            <GetShownCards selectedStrategy={selectedStrategy} tiles={shownPatterns} clickFunc={handleCardClick} handleModalOpen={handleModalOpen} setModalContent={setModalContent} />
        </div>

        {/* Container for the rightside pattern selection */}
        <div className={classes.patternSelection}>
            <ListSubheader disableSticky={true} component="div" id="selectorlist-subheader">
                Auswahl
            </ListSubheader>
            <GridList cols={1} cellHeight={400} className={classes.gridList}>
                {/* Selected pattern cards are rendered in a Gridlist and derived from the state variable of selected patterns */}
                {selectedPatterns[0] && selectedPatterns.map((tile) => (
                    <Card raised className={classes.patternCard} key={tile.id}>

                        <CardActionArea>
                            {/* Card image of the pattern */}
                            <CardMedia
                                component="img"
                                alt=""
                                height="180"
                                src={"/cards/" + tile.id + "icon.png"}
                                title="Muster"
                            />
                            {/* Pattern name and description preview, which is shortened */}
                            <CardContent >
                                <Typography gutterBottom variant="h5" component="h2" align="center">
                                    {tile.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p" align="center">
                                    <TextTruncate
                                        line={2}
                                        element="span"
                                        truncateText="…"
                                        text={tile.description}
                                    />
                                </Typography>
                            </CardContent>
                        </CardActionArea>

                        {/* Buttons on the bottom of each card, which allow selection and access to more informations via modals */}
                        <div className={classes.cardButtonArea}>
                            <CardActions className={classes.selectedPatternButtonArea}>
                                <Button
                                    style={{ width: 222, marginBottom: "8px" }}
                                    onClick={() => {
                                        setModalContent(tile.id);
                                        handleModalOpen();
                                    }}
                                    variant="contained"
                                    size="small"
                                    color="primary">

                                    <>
                                        {"Informationen"}
                                        <InfoIcon style={{ marginLeft: "5px" }} />
                                    </>
                                </Button>
                                <Button
                                    style={{ width: 222, marginRight: "8px" }}
                                    onClick={(event) => handleCardDeleteClick(event, tile.id)}
                                    variant="contained"
                                    size="small"
                                    color="default">
                                    <>
                                        {"Entfernen"}
                                        <DeleteIcon style={{ marginLeft: "5px" }} />
                                    </>
                                </Button>
                            </CardActions>
                        </div>
                    </Card>
                ))}
                <div></div>{/* workaround to avoid empty childrenlist in GridList, which causes a console warning */}
            </GridList>
        </div>

        {/* These are the information modals: one for patterns and one for the canvas model */}

        {/* The content of the modal changes with the state variable modalContent */}
        <Modal key={"patternModal"}
            open={modalOpen}
            onClose={handleModalClose}
        >
            <div className={classes.modalPaper}>
                <Typography variant={"h5"}> Weitere Informationen zum Muster</Typography>
                <img className={classes.patternImage} height={745} src={"/cards/" + modalContent + ".png"} alt={"Kein Bild gefunden"} />
                <Typography>
                    <Link
                        href="https://www.researchgate.net/publication/317234245_Pattern_based_business_model_development_-_identification_structuring_and_application_of_business_model_patterns"
                        target="_blank" rel="noreferrer"
                        variant="inherit">
                        Quelle: Echterhoff et al.
                    </Link>
                </Typography>
                <Button
                    style={{ marginTop: "20px" }}
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={handleModalClose}>
                    Ansicht schließen
                </Button>
            </div>
        </Modal>
        <Modal key={"canvasModal"}
            open={canvasModalOpen}
            onClose={handleCanvasModalClose}
        >
            <div className={classes.canvasModalPaper}>
                <Typography variant={"h5"}> Weitere Informationen zum Geschäftsmodellrahmen </Typography>
                <Typography className={classes.modalTextBlock} variant={"subtitle1"}>
                    <br />
                    In diesem Reiter können Sie Geschäftsmodellmuster in Ihr Geschäftsmodell integrieren.
                    Klicken Sie dazu auf den "Hinzufügen"-Button der jeweiligen Geschäftsmodellmusterkachel.
                    Weitere Informationen sind über Infobuttons an den Kacheln zu erreichen.
                    Diese beinhalten genauere Information über das Muster (Beschreibung, Kernelemente).
                    Sie können bereits ausgewählte Muster im rechten Teil der Geschäftsmodellmuster-Ansicht wieder abwählen, indem Sie auf den roten "Entfernen"-Button drücken.
                    Im linken Bereich der Ansicht finden Sie eine Filterliste.
                    Durch Anklicken von einzelnen Geschäftsmodellelementen können Sie die Auswahl auf Muster beschränken, die das jeweilige Element beinhalten.
                    Dadurch können Sie gezielt nach Mustern suchen, die Bereiche ihres Geschäftsmodells abdecken, die noch nicht ausgefüllt sind.
                    Geschäftsmodellelemente, die bereits durch ausgewählte Geschäftsmodellmuster abgedeckt werden, sind in der Filterliste mit einem Haken gekennzeichnet.
                    Der Rahmen von einzelnen Musterkacheln ist eingefärbt, wenn das jeweilige Muster besonders gut zur aktuellen Konfiguration von Mustern und Normstrategie passt.
                    Die Bedeutung der Farben können Sie der Legende unterhalb der Filterliste entnehmen.
                    Um die strukturierte Beschreibung und Erstellung von Geschäftsmodellen zu ermöglichen, verwendet diese Applikation den unten beschriebenen Modellrahmen.
                </Typography>
                <img style={{ paddingTop: "40px", width: "850px"}} src={"/canvasinfo.png"} alt={"Kein Bild gefunden"} />
                <Typography>
                    <Link
                        href="https://www.researchgate.net/publication/317234245_Pattern_based_business_model_development_-_identification_structuring_and_application_of_business_model_patterns"
                        target="_blank" rel="noreferrer"
                        variant="inherit">
                        Quelle: Echterhoff et al.
                    </Link>
                </Typography>
                <Button
                    style={{ marginTop: "20px" }}
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={handleCanvasModalClose}>
                    Ansicht schließen
                </Button>
            </div>
        </Modal>
    </Box >);
}

function GetShownCards({ tiles, clickFunc, handleModalOpen, setModalContent, selectedStrategy }) {
    // This function returns the middle section: the pattern finder in which the user can select patterns

    const classes = useStyles();

    // Specify border color styles for suitable of compatible cards
    const suitabilityOneCardStyle = {
        border: '3px solid',
        borderColor: "lightgreen",
    };
    const suitabilityTwoCardStyle = {
        border: '4px solid',
        borderColor: "green",
    };
    const compatibleCardStyle = {
        border: '3px solid',
        borderColor: "darkturquoise",
    };


    const handleAddPattern = (id) => {
        // Function to handle selection of new patterns

        // Get index of tile in patternData
        var patternIndex = "";
        for (var i = 0; i < patternData.length; i++) {
            if (patternData[i].id === id) {
                patternIndex = i;
                break;
            }
        }
        // Handle selection of new pattern, which needs to be called with the index of the new pattern
        clickFunc(patternIndex);
    };


    if (tiles.length > 0)
        return (
            // Shown cards are organised in a Gridlist
            <GridList
                cellHeight={380}
                className={classes.gridList} >
                {
                    // Generate cards from given pattern list
                    tiles.map((tile) => (
                        <Card raised className={classes.patternCard} key={tile.id}
                            // Change border color according to suitability or compatible attribute of the pattern
                            style={{
                                ...((tile.suitability === 1 && suitabilityOneCardStyle)
                                    || (tile.suitability === 2 && suitabilityTwoCardStyle)
                                    || (tile.suitability === 0 && tile.isCompatible && compatibleCardStyle)
                                )
                            }}>
                            <CardActionArea
                                onClick={event => { handleAddPattern(tile.id) }}>

                                {/* Pattern image */}
                                <CardMedia
                                    component="img"
                                    alt=""
                                    height="180px"
                                    src={"/cards/" + tile.id + "icon.png"}
                                    title={tile.name}
                                />
                                <CardContent >
                                    {/* Pattern name and shortened description */}
                                    <Typography gutterBottom variant="h5" component="h2" align="center">
                                        {tile.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p" align="center">
                                        <TextTruncate
                                            line={3}
                                            element="span"
                                            truncateText="…"
                                            text={tile.description}
                                        />

                                    </Typography>
                                </CardContent>
                            </CardActionArea>

                            <div className={classes.shownCardLowerContainer}>
                                <CardActions className={classes.cardButtonArea}>
                                    {/* Buttons to add pattern to selection and show further informations */}
                                    <Button
                                        onClick={() => {
                                            setModalContent(tile.id);
                                            handleModalOpen();
                                        }
                                        }
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                    >
                                        <InfoIcon />
                                    </Button>
                                    <Button
                                        className={classes.limitAddButtonHeight}
                                        onClick={() => { handleAddPattern(tile.id) }}
                                        size="small"
                                        color="default"
                                        variant="contained" >
                                        Muster Hinzufügen
                                    </Button>
                                </CardActions>
                            </div>
                        </Card>
                    ))
                }
            </GridList >
        )
    else
        // Show this instead, if list of shown cards is empty
        return (<Typography
            align="center"
            variant="subtitle1">
            Keine Muster gefunden
        </Typography>)
}