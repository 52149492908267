import RootContainer from './components/rootcontainer.js';
import Startpage from './components/startpage.js';

import './main.css';
import { Route, Routes } from 'react-router-dom'
import MarketOffering from './components/marketoffering.js';
import WorkDesign from './components/workdesign.js';

import Organisation from './components/organisation.js';
import Processes from './components/proccesses.js';
import Competencies from './components/competencies.js';
import Interactions from './components/interactions.js';
import PatternFinderImage from './components/patternfinderImage.js'
import PatternApplicationImage from './components/patternapplicationImage.js';
import ProcessPatterns from './components/processpatterns.js';
import ProcessApplication from './components/proccessesapplication.js';
import MainTabs from './components/maintabs.js';

import 'react-app-protect/dist/index.css'
import OrganisationPatterns from './components/organisationpatterns.js';


function App() {

  // App.js only includes the main component


  // < Protect sha512 = '90fd68126a9236fb873d8c4641d17a3c17185ab876d6fd5cf043dcb22ceb8cb45552460f1512475623a3a4fcdfdf322d8436441f3417ba1c0969a0ab24c812c9' >
  // Passwortsicherung für DEMO
  // Passwort: Webtool2021

  
  return (

    <Routes>
      <Route path="/" element={<RootContainer Component={Startpage} isStartpage={true} />} />

      <Route path="/bm2vc" element={<MainTabs/>} />

      <Route path="/marketoffering" element={<RootContainer Component={MarketOffering} />} />
      
      <Route path="/workdesign" element={<RootContainer Component={WorkDesign} />} />
      
      <Route path="/organisation" element={<RootContainer Component={Organisation} />} />
      <Route path="/organisation/patterns" element={<RootContainer Component={OrganisationPatterns} />} />

      <Route path="/processes" element={<RootContainer Component={Processes} />} />
      <Route path="/processes/patterns" element={<RootContainer Component={ProcessPatterns} />} />
      <Route path="/processes/application" element={<RootContainer Component={ProcessApplication} />} />


      <Route path="/interactions" element={<RootContainer Component={Interactions} />} />
      <Route path="/interactions/documentation" element={<RootContainer Component={PatternFinderImage} />} />
      <Route path="/interactions/application" element={<RootContainer Component={PatternApplicationImage} />} />

      <Route path="/competencies" element={<RootContainer Component={Competencies} />} />
    </Routes>

      );

}

export default App;
