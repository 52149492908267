import { Typography, Button } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    outerDiv: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "15px"
    },
    innerDiv: {
        maxWidth: "800px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    header: {
        //paddingBottom: "15px",
        fontWeight: "bold"
    },
    textHeader: {
        marginTop: "30px",
        fontWeight: "bold",
        width: "100%"
    },
    sourceList: {
        listStyle: "square",
        marginLeft: "35px"
    },
    textJustify: {
        textAlign: "justify"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginTop: "50px"
    }
}))



export default function Processes() {
    // This component shows the information page about the usage of the application

    const classes = useStyles();

    const StyledButton = (props) => {
        return (<Button
            size="large"
            variant="contained"
            color="primary">
            {props.children}
        </Button>)
    }


    const StyledTooltip = withStyles((theme) => ({
        tooltip: {
            maxWidth: "200px",
            fontSize: theme.typography.pxToRem(12),
        },
    }))(Tooltip);

    const ElementTooltip = (props) => {
        return (<StyledTooltip
            placement="right"
            enterDelay={100}
            leaveDelay={20}
            title={
                props.title
            } >
            {props.children}
        </StyledTooltip>
        )

    };


    return (
        <div className={classes.outerDiv}>
            <div className={classes.innerDiv}>

                <Typography gutterBottom className={classes.header} align="center" variant="h3">
                    Prozesse
                </Typography>

                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Ablauforganisation
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    <p>
                        Die Ablauforganisation beschreibt die Durchführung von innerbetrieblichen Prozessen zur Umsetzung einer bestimmten Markleistung.
                        Dabei charakterisiert sich ein Prozess aus einem Input, einer oder mehrere aufeinanderfolgenden Aktivitäten sowie einem Output.
                        Bei der Einführung von Smart Services bedarf es oftmals neue oder angepasste Prozesse, die den Anforderungen eines Smart Service-Geschäftes gerecht werden.
                        Die Smart Service-spezifischen Prozessmuster wurden auf Grundlage des Smart Service-Referenprozessmodels nach FRANK ET AL hergeleitet und sollen Unternehmen dazu befähigen, ihre internen Prozesse beschreiben zu können.
                        Die Prozessmuster unterteilen sich in die vier Lebenszyklenphasen eines Smart Services: Planung, Entwicklung, Erbringung und Abrechnung.
                    </p>
                </Typography>
                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Aufbau der Prozessmuster
                </Typography>

                <svg
                    id="main"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x={0}
                    y={0}
                    viewBox="0 0 1278.4 719.1"
                    xmlSpace="preserve"
                    style={{ marginTop: "50px" }}
                >
                    <style>{".st0{fill:none}"}</style>
                    <image
                        width={8000}
                        height={4500}
                        xlinkHref="/processes/demo.png"
                        transform="scale(.1598)"
                        overflow="visible"
                        id="bgImage"
                    />
                    <g id="clickLayer"
                        fillOpacity="0"
                        stroke="#3b91cf"
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeOpacity="1"
                        strokeWidth="0"
                    >
                        {/* Kontext */}
                        <ElementTooltip title="Im Kontext-Feld wird der Prozessschritt und dessen Ziel beschrieben.">
                            <path
                                id="svgHoverElement"
                                d="M12.6 50h622.2v222.7H12.6z" />
                        </ElementTooltip>
                        {/* Vernantwortlichkeiten */}
                        <ElementTooltip title="Bei den Verantwortlichkeiten handelt es sich um mögliche Rollen, die den Prozessschritt durchführen und begleiten. Die hier aufgeführten Rollen stehen mit den Wertschöpfungsrollen in direkter Beziehung. Um mehr Informationen über die einzelnen Wertschöpfungsrollen zu erfahren, klicken Sie bitte auf den Kasten.">
                            <Link to="/interactions">
                            <path
                                id="svgClickElement"
                                d="M12.6 277.4h622.3v157.4H12.6z"
                            /></Link>
                        </ElementTooltip>
                        {/* Zeitlicher Aufwand */}
                        <ElementTooltip title="Der zeitliche Aufwand gibt Auskunft über die Dauer des Prozessschrittes und die Häufigkeit in der der Prozessschritt durchgeführt wird. Die Bearbeitung eines Auftrags wird z.B. regelmäßig beim Auslösen eines neuen Auftrags durchgeführt. Eine Wettbewerbsanalyse hingegen wird in der Regel einmalig bei der Planung eines Smart Services vorgenommen.">
                            <path
                                id="svgHoverElement"
                                d="M12 439h309.5v236H12z" />
                        </ElementTooltip>
                        {/* Iterationen */}
                        <ElementTooltip title="Iterationen geben Hinweise darauf, ob ein Prozessschritt in einer bestimmten Smart Service-Lebenszyklusphase noch einmal durchgeführt werden soll. Z.B. kann es sinnvoll sein bei einer agilen Entwicklung eines Smart Services nach der Ableitung von technischen Anforderungen, das bereits erstellte Smart Service-Geschäftsmodell noch einmal auf Konsistenz zu überprüfen und bei Bedarf anzupassen.">
                            <path
                                id="svgHoverElement"
                                d="M325.2 439h309.7v236.2H325.2z"
                            />
                        </ElementTooltip>
                        {/* Nachgelagerte Prozesse */}
                        <ElementTooltip title="Nachgelagerte Prozessschritte sind die Prozesse, die mit den vorliegenden Outputs als nächstes durchgeführt werden können.">
                            <path
                                id="svgHoverElement"
                                d="m948.1 674.1-309.5.7V480.6l309.5-.7z"
                            />
                        </ElementTooltip>
                        {/* Input */}
                        <ElementTooltip title="Ein Input ist in der Regel ein Artefakt oder Ergebnis, das in den vorherigen Prozessschritten erarbeitet worden ist. Dieses dient als Grundlage und als Startpunkt für die Bearbeitung des vorliegenden Prozessschrittes.">
                            <path
                                id="svgHoverElement"
                                d="M638.6 320.5h309.5v156H638.6z" />
                        </ElementTooltip>
                        {/* Output */}
                        <ElementTooltip title="Ein Output ist sind die Ergebnisse des vorliegenden Prozessschrittes. Diese dienen als Input für ein anderen Prozessschritt oder Aktivität.">
                            <path
                                id="svgHoverElement"
                                d="M951.1 320.5h309.5v156H951.1z" />
                        </ElementTooltip>
                        {/* Kompatible Muster */}
                        <ElementTooltip title="In diesem Feld werden aller Muster, die mit dem Prozessschritt in Beziehung stehen aufgeführt. Diese können dazu genutzt werden, die Marktleistung und Wertschöpfung weiter zu konkretisieren. Weitere Informationen über die Muster können über das Referenzmodell auf der Landingpage aufgerufen werden.">
                            <path
                                id="svgHoverElement"
                                d="M951.1 480.1h309.5v102.5H951.1z"
                            />
                        </ElementTooltip>
                        {/* Weiterführende Literatur*/}
                        <ElementTooltip title="">
                            <path
                                id="svgHoverElement"
                                d="M1261.8 674.6H952.4l-.8-87.5H1261z"
                            />
                        </ElementTooltip>
                        <ElementTooltip title="Aktivitäten beschreiben die einzelnen durchzuführenden Aufgaben eines Prozessschrittes, sowie die dazugehörigen In- und Outputs. Des Weiteren werden Hilfswerkzeuge und Methoden aufgeführt, die bei der Bearbeitung des Prozesses und der dazugehörigen Aktivitäten eingesetzt werden können. Auch werden Entscheidungspunkte bzw. Abbruchkriterien dargestellt.">
                            {/* Aktivitäten */}
                            <path
                                id="svgHoverElement"
                                d="M639.1 50.1h622.1v266.5H639.1z"
                            />
                        </ElementTooltip>
                    </g>
                </svg>








                <div className={classes.buttonContainer} >

                    <Link to="/processes/application">
                        <StyledButton>Anwendungsbeispiel</StyledButton>
                    </Link>
                    <Link to="/processes/patterns">
                        <StyledButton>Weitere Prozessmuster</StyledButton>
                    </Link>
                </div>

            </div>
        </div >
    );
}
