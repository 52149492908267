import React, { useRef } from 'react';
import { useEffect } from 'react';
import { Button, Link, makeStyles, Paper, Typography, } from "@material-ui/core";




const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "15px"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    headerText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 15,
        fontWeight: "bold",
        fontSize: 26
    },
    textJustify: {
        textAlign: "justify",
    },
    textJustifyBold: {
        textAlign: "justify",
        fontWeight: "bold"
    },
    textWidth: {
        width: "800px",
    },
    valueCreationImages: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    cardPaper: {
        flex: 1,
        margin: "20px",
    },
    image: {
        padding: "5px",
    },
}));




export default function ValueView({ selectedPatterns, contentPaperRef }) {
    // This component shows the value creation systems from selected patterns


    const classes = useStyles();

    // Automatic scrolling to top, whenever tab changes
    useEffect(() => {
        window.scrollTo(0, 0);
        contentPaperRef.current.scrollTo(0, 0);
    }, [contentPaperRef])

    // Ref for scrolling to the bottom information text
    const moreInfoRef = useRef();

    //Show this instead, if there is no pattern selected
    if (selectedPatterns.length === 0)
        return (
            <Typography style={{ paddingTop: 15 }} align="center" variant="h5">
                Es müssen Geschäftsmodellmuster ausgewählt werden, damit erste Wertschöpfungsprinzipien hergeleitet werden können.
            </Typography>
        )

    return (
        <div className={classes.root}>

            {/* Description text */}
            <Typography className={classes.headerText} align="center" variant="h2">
                Wertschöpfungsprinzipien
            </Typography>

            <div className={classes.textWidth}>
                <Typography variant="subtitle1" className={classes.textJustify} >
                    Im dieser Ansicht sehen Sie die Menge an Wertschöpfungsprinzipien, die aus Ihrer Wahl von Geschäftsmodellmustern hergeleitet wurde.
                    Diese beinhalten Aussagen über weitere Akteure, die in Ihrem Wertschöpfungsprozess involviert sind.
                    Zudem wird die Rolle Ihres Unternehmens, sowie Wechselwirkungen mit anderen Akteuren im Wertschöpfungsprozess beleuchtet.
                    Diese Wertschöpfungsprinzipien bieten eine Basis für die Operationalisierung Ihres Smart Service-Geschäfts.
                    Die Wertschöpfungsprinzipien werden in Kartenform dargestellt.
                    Das Kartenset verwendet hierbei eine Modellierungssprache, die alle relevanten Informationen des Wertschöpfungssystems beschreibt.
                    Diese beinhalten die Beschreibung von Geschäftsprozessen, Geschäftsmodellen, notwendigen Kompetenzen, sowie Interaktionen zwischen den am Wertschöpfungsprozess beteiligten Akteuren.
                    Um diese Informationen darzustellen, werden Grundkonstrukte verwendet, die Wertschöpfungseinheiten, -prozesse und –ressourcen beschreiben.
                    Diese Grundkonstrukte werden über Beziehungen miteinander in Verbindung gebracht und können so Leistungs-, Informations- und Geldflüsse spezifizieren.
                    Unter den Wertschöpfungsprinzipien wird eine allgemeine Handlungsempfehlung für das weitere Vorgehen mit diesen Prinzipien beschrieben.
                    Diese besteht aus drei Phasen und resultiert in einem Transformationssetup zur Operationalisierung des Smart Service-Geschäfts.
                    Genauere Informationen können Sie den verlinkten Quellen entnehmen:
                    <br />
                    <br />
                    <Link
                        href="https://www.degruyter.com/document/doi/10.1515/zwf-2021-0069/html"
                        target="_blank" rel="noreferrer"
                        variant="inherit">
                        Quelle: Reinhold et al.
                    </Link>
                    <br />
                    <Link
                        href="https://digital.ub.uni-paderborn.de/ubpb/id/2964133"
                        target="_blank" rel="noreferrer"
                        variant="inherit">
                        Quelle: Schneider (Modellierungssprache)
                    </Link>
                    <br />
                    <br />
                </Typography>
                    <Typography className={classes.textJustifyBold}>Hinweis:</Typography>Eine Zusammenfassung ihrer Ergebnisse erhalten Sie  mit einem Klick auf den Button "Als PDF exportieren" im Menü am rechten Rand der Navigationsleiste.
            </div>

            {/* Show legend image*/}
            <div className={classes.valueCreationImages}>
                <img
                    style={{ padding: "20px" }}
                    className={classes.image}
                    alt={"Kein Bild zu Muster Gefunden"}
                    src={"/valuecreationimages/legend.png"}
                    width="450">
                </img>
            </div>

            <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                    moreInfoRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }}>
                Weitere Informationen
            </Button>



            <div className={classes.valueCreationImages}>
                {/* Show value creation image for every selected pattern */}
                {selectedPatterns.map((pattern) => {
                    return (
                        <Paper className={classes.cardPaper}>
                            <img
                                className={classes.image}
                                alt={"Kein Bild zu Muster " + pattern.id + " Gefunden"}
                                src={"/cards/" + pattern.id + ".png"}
                                width="400">
                            </img>
                            <img
                                className={classes.image}
                                alt={"Kein Bild zu Muster " + pattern.id + " Gefunden"}
                                src={"/valuecreationimages/" + pattern.id + ".png"}
                                width="400">
                            </img>
                        </Paper>
                    )
                })}
            </div>



            <Typography ref={moreInfoRef} className={classes.headerText} align="center" variant="h2">
                Weitere Informationen
            </Typography>

            <div className={classes.textWidth}>

                <Typography variant="h5" style={{ marginTop: "10px", fontWeight: "bold" }}>
                    Phase 1: Zielbild ausgestalten
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    In der ersten Phase wird das Zielbild für die zukünftige Wertschöpfung definiert.
                    Aus jedem Geschäftsmodellmuster geht ein Wertschöpfungsprinzip hervor, welches charakteristische Interaktionen zwischen den wesentlichen Wertschöpfungselementen beschreibt.
                    Um ein erstes Modell des zukünftigen Wertschöpfungssystems zu erhalten, müssen die Wertschöpfungsprinzipien aller Muster kombiniert werden.
                    Es entsteht ein initiales Wertschöpfungssystem, das als Grundlage des zukünftigen Wertschöpfungssystems dient.
                    Um dieses zu erhalten, muss das initiale Wertschöpfungssystem iterativ um Smart Service-spezifische Wertschöpfungsrollen angereichert werden.
                </Typography>


                <Typography variant="h5" style={{ marginTop: "40px", fontWeight: "bold" }}>
                    Phase 2: Bedarfsanalyse durchführen
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    Ziel der zweiten Phase ist die Definition der zur Transformation notwendigen Anpassungsbedarfe.
                    Die Ermittlung der einzelnen Bedarfe erfolgt in zwei Schritten.
                    Zunächst findet ein Vergleich des zukünftigen Wertschöpfungssystems (Soll-Zustand) mit der aktuellen Situation statt (Ist-Zustand).
                    Identifizierte Lücken werden in einer Liste gesammelt und beschreiben nicht existente Wertschöpfungsrollen der aktuellen Wertschöpfungssituation.
                    Dabei werden sämtliche fehlende Aktivitäten, Ressourcen, Wertbeiträge und Interaktionen erfasst.
                    Anschließend folgt eine Bewertung der Wertschöpfungslücken.
                    Hier wird geprüft, ob Wertschöpfungslücken vom Unternehmen selbst oder durch externe Akteure geschlossen werden sollen.
                    Dazu wird die strategische Relevanz (Differenzierungspotential zum Wettbewerb) und die Erreichbarkeit (notwendiger Aufwand, mögliche Partner, etc.) zur Schließung der Wertschöpfungslücke untersucht.
                    Es entsteht eine Menge von Handlungsempfehlungen für die Besetzung der einzelnen Wertschöpfungsrollen.
                </Typography>


                <Typography variant="h5" style={{ marginTop: "40px", fontWeight: "bold" }}>
                    Phase 3: Transformation planen
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    In der letzten Phase findet die Umsetzungsplanung statt.
                    Hierzu werden die zuvor ermittelten Transformationsbedarfe in operative Projekte überführt.
                    Es müssen Projekte aufgestellt werden, die zur Erreichung des zukünftigen Wertschöpfungssystems durchgeführt werden müssen.
                    Hierzu werden unter anderem Ziele definiert, eine Ressourcenplanung durchgeführt und eine Projektorganisation aufgesetzt.
                    Nach der Durchführung dieser Projekte kann das Smart Service-Geschäft in Betrieb genommen werden.
                </Typography>


            </div>



        </div >
    );
}
