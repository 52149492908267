import { Typography, Button, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    outerDiv: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "15px"
    },
    innerDiv: {
        maxWidth: "1000px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    header: {
        //paddingBottom: "15px",
        fontWeight: "bold"
    },
    textHeader: {
        marginTop: "30px",
        fontWeight: "bold",
        width: "100%"
    },
    sourceList: {
        listStyle: "square",
        marginLeft: "35px"
    },
    textJustify: {
        textAlign: "justify"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginTop: "50px"
    },
    paperContainer: {
        marginTop: "50px",
    },

    patternContainer: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "1000px",
        height: "800px",
        overflowY: "scroll"
    }
}))



export default function OrganisationPatterns() {
    // This component shows the information page about the usage of the application

    const patterns = [
        [
            "1.png",
            "2.png",
            "3.png",
            "4.png",
            "5.png",
            "6.png",
            "7.png",
            "8.png",
            "9.png",
            "10.png",
        ],
    ];

    const classes = useStyles();

    const StyledButton = (props) => {
        return (<Button
            size="large"
            variant="contained"
            color="primary"
            {...props}>
            {props.children}
        </Button>)
    }



    return (
        <div className={classes.outerDiv}>
            <div className={classes.innerDiv}>

                <Typography gutterBottom className={classes.header} align="center" variant="h3">
                    Muster der Aufbauorganisation
                </Typography>

                <div className={classes.buttonContainer} >

                    <Link to="/organisation">
                        <StyledButton>Zurück zur Übersicht</StyledButton>
                    </Link>
                    <StyledButton
                        href="/organisation/Muster_Aufbauorganisation.pdf"
                        download="Aufbauorganisation_Muster.pdf"
                    >Download</StyledButton>
                </div>





                <Paper className={classes.paperContainer}>
                    <div className={classes.patternContainer}>

                        {patterns[0].map((pattern) => {
                            return (
                                <img alt="Kein Bild gefunden" style={{ margin: "10px" }} width="980px" src={"/organisation/patterns/" + pattern}></img>
                            )
                        }
                        )}
                    </div>
                </Paper>



            </div>
        </div >
    );
}
