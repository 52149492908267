import { Typography, Button, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    outerDiv: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "15px"
    },
    innerDiv: {
        maxWidth: "1000px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    header: {
        //paddingBottom: "15px",
        fontWeight: "bold"
    },
    textHeader: {
        marginTop: "30px",
        fontWeight: "bold",
        width: "100%"
    },
    sourceList: {
        listStyle: "square",
        marginLeft: "35px"
    },
    textJustify: {
        textAlign: "justify"
    },
    buttonContainerLeft: {
        float: "left",
        width: "100%",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginTop: "50px"
    },
    splitContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "50px",
        width: "100%",
    },
    vertContainer: {
        display: "flex",
        flexDirection: "column",
        marginRight: "50px",
        height: "500px"
    },
}))



export default function ProcessApplication() {
    // This component shows the information page about the usage of the application

    const classes = useStyles();

    const [stepState, setStepState] = useState(0);

    const StyledButton = (props) => {
        return (<Button
            size="large"
            variant="contained"
            color="primary"
            onClick={props.onClick}
            style={props.style}
        >
            {props.children}
        </Button>)
    }


    const stepImages = [
        (<img width="800px" src="/processes/step1.png" alt="Kein Bild gefunden."></img>),
        (<img width="800px" src="/processes/step2.png" alt="Kein Bild gefunden."></img>),
        (<img width="800px" src="/processes/step3.png" alt="Kein Bild gefunden."></img>)]
    const stepTexts = [
        (
            <Typography className={classes.textJustify} variant="subtitle1">
                <p>
                    Eine Prozesslandschaft stellt alle erforderlichen Prozessschritte und Aktivitäten der innerbetrieblichen Wertschöpfung einer Organisation dar.
                    Für den Aufbau einer Prozesslandschaft müssen im ersten Schritt alle relevanten Wertschöpfungsrollen identifiziert und die ausführenden Stakeholder bestimmt werden.
                    Das Vorgehen zur Auswahl der relevanten Wertschöpfungsrollen ist unter der Seite &#160;
                    <Link to="/interactions">
                        Wertschöpfungsstrukturen
                    </Link>
                    &#160; beschrieben.
                    Sobald die Wertschöpfungsrollen und die dazugehörigen Stakeholder identifiziert worden sind, werden sie untereinander, in sogenannte "Swimlanes", aufgeführt.
                </p>
            </Typography>
        ),
        (
            <Typography className={classes.textJustify} variant="subtitle1">
                <p>
                    Im zweiten Schritt werden die relevanten Prozessmuster identifiziert.
                    Dafür vergleichen Sie zunächst die relevanten Wertschöpfungsrollen mit den in den Prozessmustern aufgeführten Verantwortlichkeiten für die einzelnen Prozessschritte.
                    Als nächstes überprüfen Sie, ob diese Prozessmuster für Ihre Planung, Entwicklung, Erbringung und Abrechnung Ihres Smart Services relevant sind und ob ggf. Prozessschritte noch fehlen.
                    Anschließend bestimmen Sie die Reihenfolge, in denen die Prozessschritte durchgeführt werden sollen.
                </p>
            </Typography>
        ),
        (
            <Typography className={classes.textJustify} variant="subtitle1">
                <p>
                    Im dritten und letzten Schritt werden die Prozessschritte individuell ausgestaltet.
                    Dabei beschreiben Sie zunächst auf Titelebene den durchzuführenden Prozess.
                    Dabei kann Ihnen sowohl die generische Prozessmusterbeschreibung als auch die aufgeführten Aktivitäten helfen.
                    Als nächstes bestimmen Sie den In- und Output des Prozesses.
                    Auch hier enthalten die Prozessmuster generische Vorschläge, die individuell angepasst werden können z.B. durch die Ergänzung, in welchem Format der In bzw. Output vorliegen soll.
                    Anschließend bestimmen Sie die für den Prozessschritt verantwortliche Wertschöpfungsrolle bzw. Stakeholder und tragen den Prozess mit dem dazugehörigen In- und Output in die entsprechene Swimlane ein.
                    Wenn Sie dies für jeden Prozessschritt wiederholt haben, ergibt sich daraus die initiale innerbetriebliche Prozesslandschaft.
                    Anschließend können Sie die Prozesse auf Abbruchkritierien oder Iterationsschleifen überprüfen und diese in Ihrer Prozesslandschaft vorsehen.
                </p>
            </Typography>
        )]
    return (
        <div className={classes.outerDiv}>
            <div className={classes.innerDiv}>

                <Typography gutterBottom className={classes.header} align="center" variant="h3">
                    Prozesslandschaft
                </Typography>

                <div className={classes.buttonContainerLeft} >
                    <Link to="/processes">
                        <StyledButton>Zurück zur Übersicht</StyledButton>
                    </Link>
                </div>


                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Anwendung der Prozessmuster
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    <p>
                        {stepTexts[stepState]}
                    </p>
                    <br />
                    <br />
                </Typography>


                <Paper style={{ padding: "20px" }}>
                    {stepImages[stepState]}
                </Paper>


                <div className={classes.buttonContainer} >
                    <StyledButton
                        style={{ ...(stepState === 0 && { visibility: "hidden" }) }}
                        onClick={() => { setStepState(stepState - 1) }}
                    >Vorheriger Schritt
                    </StyledButton>
                    <StyledButton
                        style={{ ...(stepState === 2 && { visibility: "hidden" }) }}
                        onClick={() => { setStepState(stepState + 1) }}
                    >Nächster Schritt
                    </StyledButton>
                </div>

            </div>
        </div >
    );
}
