import * as React from "react";
import { Typography, makeStyles, Modal, Button } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({

  modalPaper: {
    height: "95vh",
    overflow: "auto",
    overflowY: "auto",
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),

    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    //Position the modal in center of the screen
    position: "fixed",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",

  },
  outerDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "15px"
  },
  innerDiv: {
    maxWidth: "800px",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  header: {
    //paddingBottom: "15px",
    fontWeight: "bold",
  },
  headerarbeitsgestaltung: {
    color: "black",
    backgroundColor: "#e3e3e3",
    borderRadius: "10px",
    paddingTop: "25px",
    paddingBottom: "25px"
  },
  headeraspekte: {
    color: "black",
    backgroundColor: "#d4e2f0",
    borderRadius: "10px",
    paddingTop: "25px",
    paddingBottom: "25px"
  },
  headergestaltungsbereich: {
    color: "white",
    backgroundColor: "#303366",
    borderRadius: "10px",
    paddingTop: "25px",
    paddingBottom: "25px"
  },
  headergestaltungsmethoden: {
    color: "white",
    backgroundColor: "#3d5d26",
    borderRadius: "10px",
    paddingTop: "25px",
    paddingBottom: "25px"
  },

  textHeader: {
    marginTop: "30px",
    fontWeight: "bold",
    width: "100%",
  },
  textJustify: {
    textAlign: "justify",
    fontSize: [14, "!important"]
  },
  sourceList: {
    listStyle: "square",
    marginLeft: "35px",
    fontSize: [14, "!important"]
  },
  textLeft: {
    textAlign: "left"
  },
  imgStyle: {
    /*height: "400px"*/
  },
  imageSourceText: {
    color: "grey",
    fontSize: [10, "!important"],
    textAlign: "center"
  },
  sourceLitList: {
    listStyle: "square",
    marginLeft: "15px",
    marginBottom: "10px",
    fontSize: [14, "!important"]
  }

}));

export default function WorkDesignImage() {

  // States for the information modals
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalContent, setModalContent] = React.useState("");

  const classes = useStyles();

  const getContent = (cardNumber) => {
    switch (cardNumber) {
      case 0:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>

              <Typography variant="subtitle1" className={classes.textJustify}>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                <br />
                <br />
                <br />
                <br />
              </Typography>

              <img className={classes.imgStyle}
                src={"/interactions/plans.jpg"} alt={"Kein Bild gefunden"} />

              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beispielparagraph 1: Wertorientierung
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">

                Hier könnten zusätzliche beschreibende Texte stehen.
                Normstrategien bilden die Basis für Ihre zukünftige Smart Service-Strategie.
                Die Wahl einer Normstrategie beeinflusst die Menge der auswählbaren Geschäftsmodellmuster.
                Eine Auswahl findet im "Normstrategie"-Reiter der Navigationsleiste statt.

                <br />
                Weitere Informationen zum Vorgehen finden Sie in diesen Publikationen:
                <br />

                <ul className={classes.sourceList}>
                  <li>
                    <a
                      href="https://digital.ub.uni-paderborn.de/doi/10.17619/UNIPB/1-1167"
                      target="_blank" rel="noreferrer"
                      variant="inherit">
                      Systematik zur Entwicklung von Smart Service-Strategien im produzierenden Gewerbe
                    </a>
                  </li>
                </ul>
              </Typography>
            </div>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={() => { setModalOpen(false) }}
              style={{ marginTop: "20px" }}
            >
              Ansicht schließen
            </Button>
          </div >)

      case 1:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headerarbeitsgestaltung} align="center" variant="h4">
                Arbeitsgestaltung
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">

                Das Konzept der Arbeitsgestaltung kann zum einen auf einer arbeitswissenschaftlichen und zum anderen auf einer arbeitspsychologischen Betrachtungsebene konkretisiert werden. Während die arbeitswissenschaftliche Deutung eher auf die Ziele von arbeitsgestalterischen Maßnahmen eingeht, beschäftigt sich die Arbeitspsychologie eher mit der Unterscheidung grundlegender Formen, beziehungsweise Arten von Arbeitsgestaltung.

              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Arbeitswissenschaftliche Perspektive<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">

                "Durch Maßnahmen der Arbeitsgestaltung soll eine Anpassung der technischen, organisatorischen und sozialen Bedingungen an den Menschen erreicht werden, sodass [...]


                <ol className={classes.sourceList}>
                  <i>1. schädigungslose, ausführbare, erträgliche und beeinträchtigungsfreie Arbeits-bedingungen sichergestellt werden, […]</i>
                </ol>
                <ol className={classes.sourceList}>
                  <i>2. Standards sozialer Angemessenheit nach Arbeitsinhalt, Arbeitsaufgabe, Arbeits-umgebung sowie Entlohnung und Kooperation erfüllt werden, […]</i>
                </ol>
                <ol className={classes.sourceList}>
                  <i>3. die Arbeitspersonen Handlungsspielräume entfalten, Fähigkeiten erwerben und […]</i>
                </ol>
                <ol className={classes.sourceList}>
                  <i>4. in Kooperation mit anderen ihre Persönlichkeit erhalten und entwickeln können." (Schlick et al. 2010, S. 49)</i>
                </ol>


                Aus dieser Zielstellung ergeben sich die im Mosaik dargestellten Gestaltungsbereiche sowie Aspekte und Beeinflussungsfaktoren.

              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Arbeitspsychologische Perspektive<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">

                "Durch Maßnahmen der Arbeitsgestaltung soll eine Anpassung der technischen, organisatorischen und sozialen Bedingungen an den Menschen erreicht werden, sodass [...]


                <ol className={classes.sourceList}>
                  <i>1. „Von korrektiver Arbeitsgestaltung spricht man, wenn Arbeitssysteme und betriebliche Abläufe nach ihrer Einführung und der Identifizierung von Mängeln verändert werden.“</i>
                </ol>
                <ol className={classes.sourceList}>
                  <i>2. „Bei der präventiven Arbeitsgestaltung werden mögliche gesundheitliche Schädigungen und psychosoziale Beeinträchtigungen der Beschäftigten, die durch die Auseinandersetzung mit der Arbeitstätigkeit auftreten können, gedanklich vorweggenommen.“</i>
                </ol>
                <ol className={classes.sourceList}>
                  <i>3. „Bei der prospektiven Arbeitsgestaltung gestaltet man bereits bei der Planung bzw. Neugestaltung von Arbeitsstrukturen Aufgaben, Anforderungen und Arbeitsplätze so, dass sie sich positiv auf die Gesundheit und Persönlich-keitsförderlichkeit des Arbeitnehmers auswirken.“ </i>
                </ol>
                <ol className={classes.sourceList}>
                  <i>4. „Bei der flexiblen Arbeitsgestaltung werden interindividuelle Differenzen bei der Bewältigung von Arbeit einbezogen und eine persönlichkeitsförderliche Aufgabengestaltung unterstützt.“</i>
                </ol>
                <ol className={classes.sourceList}>
                  <i>5. „Mithilfe der differenziellen Arbeitsgestaltung werden Arbeitsformen realisiert, bei denen die Beschäftigten zwischen verschiedenen Arbeitsstrukturen wählen können. Es wird also nicht eine für alle Mitarbeiter einheitliche Arbeitsstruktur entwickelt und vorgegeben.“</i>
                </ol>
                <ol className={classes.sourceList}>
                  <i>6. „Mithilfe der dynamischen Arbeitsgestaltung werden parallel zum Lernfortschritt des Beschäftigten solche Arbeitsbedingungen geschaffen und weiterentwickelt, die den Mitarbeiterkompetenzen entsprechen.“ (Nerdinger et al. 2019, S. 421 f.)</i>
                </ol>


                Aus dieser Zielstellung ergeben sich die im Mosaik dargestellten Gestaltungsbereiche sowie Aspekte und Beeinflussungsfaktoren.

              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Mosaik der Arbeitsgestaltung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">

                Unter Berücksichtigung von Anforderungen, die mit dem Wandel vom Produkt- zum Smart Service-Anbieter einhergehen, ergibt sich folgender Systematisierungsansatz der Arbeitsgestaltung:
              </Typography>
              <img src="/workdesigns/Bild1.png" alt="Kein Bild gefunden"></img>
              <Typography className={classes.textJustify} variant="subtitle1">
                <p align="middle">Abb.: 1 &ndash; Mosaik der Arbeitsgestaltung (in Anlehnung an Jäger 2015, in Petry 2016)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Mosaik der Arbeitsgestaltung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">

                Das von Jäger (2015, in Petry 2016) entwickelte und im Rahmen des Projekts IMPRESS konkretisierte „Mosaik der Arbeitsgestaltung“ zeigt die wesentlichen Gestaltungsbereiche auf und veranschaulicht die dazugehörigen Aspekte und Beeinflussungsfaktoren.


                Allgemein ist festzuhalten, dass es im Rahmen der Entwicklung, hin zu einem Smart Service-Anbieter, besonders darauf ankommt, der betrieblichen Mitbestimmung und Partizipation der Mitarbeitenden bei der Arbeitsgestaltung eine zentrale Rolle zukommen zu lassen. Wie der Arbeitskreis Smart Service Welt/acatech in diesem Zusammenhang berichtet, muss es das Leitbild sein,
                <i>
                  &nbsp; „[ ] dass ein hohes Maß an Selbstbestimmung bei der Arbeitsgestaltung gewährleistet und gefördert wird und virtuelle, betriebliche wie überbetriebliche Arbeitsorganisations-formen dies in innovativer Weise unterstützen.“
                </i>
                &nbsp; (Arbeitskreis Smart Service Welt / acatech 2015, S. 115).
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Nerdinger, F. W., Blickle, G., Schaper, N. & Solga, M. (2019).</b> Arbeits- und Organisationspsychologie (4. Aufl.). Springer-Lehrbuch. Springer.</p>
                  <p>&#10146; <b>Schlick, C., Bruder, R. & Luczak, H. (2010).</b> Arbeitswissenschaft (3. Aufl.). Springer.</p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )

      /* 2.1 Arbeitsorganisation */
      case 21:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headergestaltungsbereich} align="center" variant="h4">
                Arbeitsorganisation <p>(Gestaltungsbereiche)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Aspekte & Beeinflussungsfaktoren<br />
              </Typography>
              <Typography className={classes.textJustify} >

                Selbstorganisation, Virtuelle Teamarbeit, Kollaboratives Arbeiten, Demokratisierung, Multispace Office

              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Im Rahmen des Gestaltungsbereiches der Arbeitsorganisation ergeben sich mit Blick auf den Wandlungsprozess zum Smart Service-Anbieter, drei grundlegende Ansätze der Weiterentwicklung.
                <br />
                <ol className={classes.sourceList}>
                  1. <u>Integration smarter Dienstleistungsaufgaben in bereits vorhandenen Abteilungen:</u>
                </ol>
                <ol className={classes.sourceList}>
                  <i> „Aus dieser Perspektive der Organisationsgestaltung können Smart Services eine gute Grundlage dafür bieten, breitere und zugleich interessantere tätigkeitszuschnitte für die Industriebeschäftigten zu generieren.“ </i>
                </ol>
                <p align="right">(Arbeitskreis Smart Service Welt / acatech 2015, S. 112)</p>
                <ol className={classes.sourceList}>
                  2. <u>Aufbau eigener Abteilungen zur Entwicklung und Verwaltung von Smart-Services:</u>
                </ol>
                <ol className={classes.sourceList}>
                  Innerhalb des Unternehmens wird eine neue Spezialabteilung für Smart-Services etabliert, welche im Vergleich zu Variante 1 weniger 	Berührungspunkte mit den traditionellen industriellen Arbeitsinhalten aufweist.
                </ol>
                <br />
                <ol className={classes.sourceList}>
                  3. <u>Outsourcing des Smart Service-Bereiches:</u>
                </ol>
                <ol className={classes.sourceList}>
                  Die Entwicklung und Verwaltung von Smart Services wird aus dem produzierenden Bereich ausgelagert.
                </ol>
                <br />
                Wichtig anzumerken bleibt, dass die Ausgestaltung des gewählten Grundansatzes zum einen von den bereits bestehenden Strukturen der Arbeitsorganisation und zum anderen auch von der Form der smarten Produkttypen abhängig ist. So ist zum Beispiel durchaus das Szenario vorstellbar, in dem die Entwicklung digitaler Dienstleistungen in einer eigenständigen Abteilung erfolgt, während der Vertrieb dieser Produkte in bereits vorhandene Organisationsstrukturen integriert wird.
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Hinweise<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Bei der Weiterentwicklung der Arbeitsorganisation rückt folgender Grundsatz immer stärker in den Mittelpunkt:
                <i>"Die Herausforderungen schnell wechselnder Umfeldbedingungen in globalisierten Käufermärkten erfordern mehr denn je flexible, wandlungsfähige Arbeits-organisationen, die schnell auf Störungen und Schwankungen reagieren können.„</i>
                <p align="right">(Schlick et al. 2010, S. 501)</p><br />
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Prinzipien der innov. Arbeitsorganisation<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Im Rahmen des Gestaltungsbereiches der Arbeitsorganisation ergeben sich mit Blick auf den Wandlungsprozess zum Smart Service-Anbieter, drei grundlegende Ansätze der Weiterentwicklung.
                <ol className={classes.sourceList}>
                  &#10003;  <i>„Erweiterte Handlungs- und Entscheidungsspielräume (sowie) breite und funktionsintegrierte Aufgabenzuschnitte“ für die Beschäftigten schaffen.</i>
                </ol>
                <ol className={classes.sourceList}>
                  &#10003;  <i>„Bereichs- und hierarchieübergreifenden Austausch (sowie) kollegiale Austausch- und Unterstützungsprozesse“ fördern.</i>
                </ol>
                <ol className={classes.sourceList}>
                  &#10003;  <i>„Beschäftigtengetragene Optimierungsprozesse sowie ein indirektes Führen per Absprachen“ ermöglichen.</i>
                </ol>
                <p align="right">(Lager 2020, 280 ff.)</p><br />
                <ol className={classes.sourceList}>
                  &#10003;  <i>„Beschäftigte und ihre betrieblichen Interessensvertretungen müssen die Veränderungen von Anfang an begleiten und mitbestimmen.“</i>
                </ol>
                <p align="right">(Arbeitskreis Smart Service Welt / acatech 2015, S. 115)</p><br />
                <ol className={classes.sourceList}>
                  &#10003;  Strukturierte und bedarfsgerechte betriebliche Qualifizierungs- und Weiterbildungsaktivitäten sind für alle Beschäftigten im Rahmen einer unter-nehmensweiten Lernkultur sicherzustellen.
                </ol>
              </Typography>

              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Schlick, C., Bruder, R. & Luczak, H. (2010).</b> Arbeitswissenschaft (3. Aufl.). Springer. (S. 681 ff.).</p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )

      /* 2.2 Arbeitsinhalt */
      case 22:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headergestaltungsbereich} align="center" variant="h4">
                Arbeitsinhalt <p>(Gestaltungsbereiche)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Aspekte & Beeinflussungsfaktoren<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Demokratisierung, Selbstorganisation, Eigenverantwortung, Flexible Arbeitszeiten, Virtuelle Teamarbeit
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Im Bereich der Arbeitsgestaltung wird dem Arbeitsinhalt und der Gestaltung von Arbeitsaufgaben eine entscheidende Bedeutung zugeschrieben. Besonders mittelständige Unternehmen die über die Entwicklung von Smart-Services ihr Wertschöpfungsangebot erweitern wollen, stehen vor der Herausforderung, aufgrund von fortschreitenden technologischen Entwicklungen daran ausgerichtete Arbeitsaufgaben für neue Stellen zu definieren sowie die Arbeitsinhalte für bereits etablierte Bereiche angemessen zu erweitern. Da die Veränderung von Arbeitsinhalten sowie die damit verbundene Aus- und Weiterbildung der Mitarbeitenden eng mit der Arbeitsorganisation verbunden sind, ist es zudem notwendig die Einflüsse auf die Unternehmenskultur in diesem Zusammenhang mitzudenken.
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Merkmale innovativer Arbeitsaufgaben<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Die Digitalisierung im Allgemeinen und auch der Wandel vom Produkt- zum Smart-Service Anbieter im Speziellen beinhalten im Rahmen der Gestaltung von Arbeitsaufgaben folgende Merkmale:
                <br />
                <ol className={classes.sourceList}>
                  1. Entwicklungsorientierte Aufgaben nehmen gegenüber den Routineaufgaben eine größere und wichtigere Rolle ein, was sich wiederum auf die geforderten Mitarbeiterkompetenzen auswirkt.
                </ol>
                <ol className={classes.sourceList}>
                  2. Die damit potenziell gesteigerte Aufgabenkomplexität erfordert ein hohes Maß an Kommunikation, Kooperation sowie die Sicherstellung regelmäßiger Weiterbildungsaktivitäten. Das kontinuierliche lebenslange Lernen sollte somit im Unternehmen als einer der Kernarbeitsinhalte anerkannt werden.
                </ol>
                <ol className={classes.sourceList}>
                  3. Die eigenverantwortliche Auswahl und Durchführung von Arbeitsinhalten rückt stärker in den Mittelpunkt, wodurch die dauernde Zugänglichkeit zu den Arbeitsaufgaben sichergestellt werden muss.
                </ol>
                <ol className={classes.sourceList}>
                  4. Der konkrete Inhalt der Arbeitsaufgaben variiert jedoch fortwährend von Branche zu Branche und ist eng an die Form des zu entwickelnden Smart-Services und somit in erster Linie an den Kundenbedarf gekoppelt.
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beispiel Arbeitsaufgaben vom Smarten Vertriebler<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <b>Kernaufgaben:</b>
                <br />
                <ol className={classes.sourceList}>
                  <li>Vertrieb der Produkte des Unternehmens</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Kundenbetreuung (Kundenakquise, Kundenbindung)</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Entwicklung von Verkaufsstrategien</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Marktanalyse</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li><p style={{ color: "blue" }}>Anforderungsmanagement (Erhebung der Anforderungen des Kunden an die Software)</p></li>
                </ol>
                <ol className={classes.sourceList}>
                  <li><p style={{ color: "blue" }}>Enge Abstimmung mit dem Smart Service-Owner hinsichtlich Anforderungserhebung und Produktentwicklung</p></li>
                </ol>
                <p>Blau gekennzeichnete Kernaufgaben sind Smart Service spezifische Aufgaben, die neu beim Vertrieb hinzugekommen sind. </p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Thiemann, D., Müller, M. & Kozica, A. (2020).</b> Selbstorganisation in komplexen digitalen Arbeitswelten. In O. Geramanis & S. Hutmacher (Hrsg.), uniscope. Publikationen der SGO Stiftung. Der Mensch in der Selbstorganisation (S. 337–350). Springer Fachmedien.</p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )

      /* 2.3 Arbeitszeit */
      case 23:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headergestaltungsbereich} align="center" variant="h4">
                Arbeitszeit <p>(Gestaltungsbereiche)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Aspekte & Beeinflussungsfaktoren<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Selbstorganisation, Eigenverantwortung, Flexible Arbeitszeiten, Work-Life-Balance
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Im Rahmen der Gestaltung der Arbeitszeit können neben den vorhandenen Arbeitszeitstrukturen ebenso flexible Modelle der Arbeitszeitgestaltung in Betracht gezogen werden.<br /><br />
                Durch komplexere Arbeitsinhalte und digital geprägte Arbeitsformen steigen die Interessen und Ansprüche der Mitarbeitenden mit Blick auf die sogenannte Work-Life Balance. So ist durchaus davon auszugehen, dass sich die Strukturierungsform der Arbeitszeit unter den Aspekten der gesteigerten Selbstorganisation und Eigenverantwortung zu einem der entscheidenden Faktoren in der Gewinnung qualifizierter Fachkräfte entwickeln wird.<br /><br />
                Die Nachfrage nach flexiblen Arbeitszeitmodellen entsteht darüber hinaus bereits aufgrund der notwendigen Gewährleistung einer häufig zeitunabhängigen Bereitstellung von digitalen Dienstleitungen und der damit verbundenen hohen Verfügbarkeit eines Kundensupports.<br /><br />
                Wichtig zu bedenken bleibt in diesem Zusammenhang, dass bei der Arbeitszeitgestaltung weiterhin gesetzliche sowie tarifliche Bedingungen einzuhalten sind und die Form der Arbeitszeitdokumentation bereits vor der Einführung alternativer Modelle unter Partizipation der Mitarbeitenden geregelt werden muss.<br /><br />
                Somit soll sichergestellt werden, dass neue Regelungen zur Flexibilisierung der Arbeitszeit nicht zu einer Entgrenzung der Arbeit und schließlich zu erhöhten physischen und psychischen Belastungen der Mitarbeitenden führen.<br /><br />
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Altun, U. & Koczy, A. (2018).</b> Checkliste zur Gestaltung orts- und zeitflexibler Arbeit. In ARBEIT(S).WISSEN.SCHAF(F)T Grundlage für Management & Kompetenzentwicklung: 64. Kongress der Gesellschaft für Arbeitswissenschaft: FOM Hochschule für Oekonomie & Management, 21.-23. Februar 2018. GfA-Press. Beitrag C.4.1.</p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )

      /* 2.4 Ergonomie */
      case 24:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headergestaltungsbereich} align="center" variant="h4">
                Ergonomie <p>(Gestaltungsbereiche)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Aspekte & Beeinflussungsfaktoren<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Flexible Arbeitszeiten, Work-Life-Balance, Flexibler Arbeitsort / Mobiles Arbeiten, Arbeitsraumgestaltung, Arbeitsmittel, Multispace Office, Mobile Devices
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Begriff<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li><i>„Unter Ergonomie versteht man in erster Linie das Anpassen der Arbeitsbedingungen an die Fähigkeiten und Eigenschaften des arbeitenden Menschen.</i></li>
                </ol>
                <ol className={classes.sourceList}>
                  <li><i>Man versteht jedoch auch darunter die Anpassung des Menschen an die Arbeit.</i></li>
                </ol><br />
                &#10146; Aufgabe der Ergonomie ist es [somit] die Wechselwirkung Mensch, Arbeit und Technik zu harmonisieren. Dadurch soll eine größtmögliche Arbeitszufriedenheit, ein möglichst kleines Unfall- und Gesundheitsrisiko für die Mitarbeit(enden) und größtmöglicher wirtschaftlicher Nutzen für das Unternehmen erreicht werden“
                <p align="right">(Rothe 2009, S. 198)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Als zentrale Ansatzpunkte der Ergonomie gelten allgemein die hier vorgestellten <b>Gestaltungsbereiche</b> sowie im Speziellen die erwähnten <b>Aspekte und Beeinflussungsfaktoren</b> des Mosaiks der Arbeitsgestaltung. (Abb.1)
                Darüber hinaus beschäftigt sich die Ergonomie jedoch auch mit dem Gestaltungsbereich Mensch, in dem besonders die veränderlichen Eigenschaften eines Mitarbeitenden durch Instrumente wie zum Beispiel Einarbeitung, Weiterbildung und Motivation beeinflusst werden können.
                Die Gesamtheit der bestehenden und neuen Fragen, die sich in den Gestaltungs-bereichen und Beeinflussungsfaktoren im Zusammenhang mit dem Wandel zum Smart-Service Anbieter stellen, werden also im Rahmen der Ergonomie unter einer inklusiven Perspektive betrachtet.
                Zur groben Orientierung der aktuellen und zukünftigen Herausforderungen an die ergonomische Arbeitsgestaltung sollen die nachfolgenden Aspekte dienen:
                <ol className={classes.sourceList}>
                  <li>Bedeutung psychischer Anforderungen im Rahmen der Flexibilisierung von Arbeitsbedingungen und -aufgaben im Zuge der Digitalisierung</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Umgang mit einseitig <i>„körperliche(r) Belastung, monotone(n) und dequali-fizierende(n) Aufgaben sowie zunehmende(r) körperliche Inaktivität“</i> durch neue Arbeitsinhalte, in Folge von Automatisierung und Digitalisierung (Adolph et al. 2016, S. 79 f.)</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Bedingungen zur Lösung des Spannungsfeldes zwischen Entgrenzung und Verdichtung von Arbeit, in Folge wachsender Informationskomplexität sowie Aufgabenspezifität auf der einen Seite und Erholungsfähigkeit und Arbeitszu-friedenheit der Mitarbeitenden auf der anderen Seite</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Gestaltung der Anwendungsbereiche digitaler Automatisierung, unter der Voraussetzung der Beachtung von adäquaten und ausreichenden Möglichkeiten zur sozialen Kommunikation und Kooperation unter den Mitarbeitenden und zu den Kunden</li>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Rothe, C. (Hrsg.). (2009).</b> Haufe-Praxisratgeber. Arbeitsschutz von A - Z: Aktuelle Informationen zum Nachschlagen (5. Aufl.). Haufe.</p>
                  <p>&#10146; <b>Rüffert, D., Heß, J. & Bullinger-Hoffmann, A. C. (2017).</b> Ergonomie am wissensintensiven Arbeitsplatz - Problemstellung und Lösungsansätze. In H. Breit, A. C. Bullinger-Hoffmann & U. Cantner (Hrsg.), Produktivität von industriellen Dienstleistungen in der betrieblichen Praxis (S. 163–179). Springer Fachmedien.</p>
                  <p>&#10146; <b>Best Practice Beispiel „Der ergonomische Bildschirm- und Büroarbeitsplatz“: Mohokum, M. & Dördelmann, J. (2018).</b> Betriebliche Gesundheitsförderung: Ein Leitfaden für Physiotherapeuten. Springer.</p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )

      /* 2.5 Arbeitsplatz */
      case 25:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headergestaltungsbereich} align="center" variant="h4">
                Arbeitsplatz <p>(Gestaltungsbereiche)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Aspekte & Beeinflussungsfaktoren<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Selbstorganisation, Work-Life-Balance, Flexibler Arbeitsort / Mobiles Arbeiten, Arbeits-raumgestaltung, Arbeitsmittel, Multispace Office, Mobile Devices
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Zielgruppe<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Smart-Service Owner, Smarte Vertriebler, Programmierer, weitere Office-Mitarbeiter
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Mit dem Wandel der Arbeitsorganisation und des Arbeitsinhalts ergeben sich im Zuge alternativer Arbeitszeitmodelle ebenfalls Potentiale für Veränderungen im Gestaltungsbereich des Arbeitsplatzes.
                Im Mittelpunkt steht dabei die Integration von Unternehmens- und Mitarbeiterinteressen durch gesteigerte Wertschöpfungseffizienz auf der einen und besserer Vereinbarkeit von Beruf und Privatleben auf der anderen Seite. Gelingt diese Zusammenführung, besteht die Chance einer erhöhten Arbeitgeberattraktivität bei der Suche geeigneter Fachkräfte, wie z.B. Smart-Service Owner und Programmierer für den anstehenden Wandel zum Smart-Service Anbieter.
                Die Entwicklung und der Vertrieb von Smart-Services bedeuten in diesem Zusammenhang gerade für kleine und mittelständige Unternehmen einen zunehmenden Anteil von <b>Wissensarbeit</b> wonach insbesondere <b>„geistig objektivierende Tätigkeiten</b>, die <b>neuartige</b> und <b>komplexe Arbeitsprozesse und -ergebnisse</b> betreffen“, in den Vordergrund der Arbeitsgestaltung rücken. (Hube 2005, S. 61)
                Durch die damit verbundene zunehmende Relevanz des Wissensarbeitsplatzes und die erhöhte Digitalisierung der Arbeitsinhalte sind über die Flexibilisierung des Arbeitsortes hinaus, zusätzlich konkrete Maßnahmen der Arbeitsraumgestaltung und eine anforderungsgerechte Arbeitsmittelausstattung von großer Bedeutung.
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beispiel Wissensarbeitsplatz<br /><br />
              </Typography>
              <img src="/workdesigns/Bild2.png" alt="Kein Bild gefunden"></img><br />
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li><i>„Unter Ergonomie versteht man in erster Linie das Anpassen der Arbeitsbedingungen an die Fähigkeiten und Eigenschaften des arbeitenden Menschen.</i></li>
                </ol>
                <ol className={classes.sourceList}>
                  <li><i>Man versteht jedoch auch darunter die Anpassung des Menschen an die Arbeit.</i></li>
                </ol>
                <ol className={classes.sourceList}>
                  &#10146; Aufgabe der Ergonomie ist es [somit] die Wechselwirkung Mensch, Arbeit und Technik zu harmonisieren. Dadurch soll eine größtmögliche Arbeitszufriedenheit, ein möglichst kleines Unfall- und Gesundheitsrisiko für die Mitarbeit(enden) und größtmöglicher wirtschaftlicher Nutzen für das Unternehmen erreicht werden“
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Arbeitskreis Smart Service Welt / acatech. (2015).</b> Smart Service Welt – Umsetzungsempfehlungen für das Zukunftsprojekt Internet basierte Dienste für die Wirtschaft. Abschlussbericht. Berlin. (S. 115). </p>
                  <p>&#10146; <b>Mohokum, M. & Dördelmann, J. (2018).</b> Betriebliche Gesundheitsförderung: Ein Leitfaden für Physiotherapeuten. Springer. (S. 179 ff.)</p>
                  <p>&#10146; <b>Sandrock, S. & Niehues, S. (2020).</b> CHECKLISTE zur ergonomischen Bewertung von Tätigkeiten, Arbeitsplätzen, Arbeitsmitteln & Arbeitsumgebung – Teil B: Büro- und Bildschirmarbeit – Teil C: Mobile Arbeitsmittel – Teil C: Software-ergonomie. In ifaa - Institut für angewandte Arbeitswissenschaft e.V. (Hrsg.), ifaa-Checklisten (3. Aufl.).</p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )

      /* 2.6 Arbeitsumgebung */
      case 26:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headergestaltungsbereich} align="center" variant="h4">
                Arbeitsumgebung <p>(Gestaltungsbereiche)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Aspekte & Beeinflussungsfaktoren<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Selbstorganisation, Flexibler Arbeitsort / Mobiles Arbeiten, Arbeitsraumgestaltung, Arbeitsmittel, Multispace Office, Mobile Devices, Virtuelle Teamarbeit
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Die Arbeitsumgebung umfasst alle Einflussbereiche von denen konkrete Auswirkungen auf die Tätigkeit am eigentlichen Arbeitsplatz abgeleitet werden können, wie z.B. Raumklima, Beleuchtung, Schalleinflüsse oder auch mechanische Schwingungen.<br />
                Unter dieser Annahme spielt vor allem der Arbeits- und Gesundheitsschutz eine zentrale Rolle, welcher im Rahmen des Wandels zum Smart-Service Anbieter an neue technologische Entwicklungen sowie unterschiedlichen Formen der orts- und zeitflexiblen Arbeit angepasst werden muss.<br />
                In diesem Zusammenhang sind zunächst die bereits heute geltenden Arbeitsschutzrechte, wie zum Beispiel bestehende gesetzliche Regelungen zur Arbeitszeitgestaltung unbedingt einzuhalten. Darüber hinaus sollten jedoch auch neue, bisher unentdeckte Herausforderungen und Schutzbedürfnisse der Mitarbeitenden, durch deren Partizipation bei der Gestaltung der Arbeitsumgebung berücksichtigt werden. <br />
                Dies erscheint besonders vor dem Hintergrund relevant, indem die digitale menschliche Arbeit zum einen ein hohes Maß an Werteindividualisierung für die Mitarbeitenden ermöglicht und zum anderen deren Fähigkeit zum individuell, kritischen Denken voraussetzt und fördert.<br />
                Ein weiterer wichtiger Aspekt der Arbeitsumgebung bezieht sich auf die Arbeitsatmosphäre, die wiederum im hohen Maße von der <b>Unternehmenskultur</b>, jedoch auch von der Gestaltung der digitalen und sozialen Vernetzung der Mitarbeitenden abhängt. <br />
                Während auf der einen Seite die Gefahr der sozialen Isolation durch eine steigende räumliche Trennung besteht, ergeben sich auf der anderen Seite ebenfalls vielfältige Formen des virtuellen Austausches, die zur Erleichterung des Arbeitsalltages beitragen können.<br />
                Besonders die Erfahrungen des Arbeitsalltages zur Zeit der Corona-Pandemie bieten an dieser Stelle eine gute Gelegenheit, die Vor- und Nachteile des Internets als globale Arbeitsumgebung für das eigene Unternehmen zu erörtern. Daraus können wiederum wertvolle Schlüsse für die zukünftigen Aufgaben im Zusammenhang mit der Entwicklung von Smart-Services gewonnen werden.<br />
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beispielfaktoren der Arbeitsumgebung<br />
              </Typography>
              <br /> <img style={{ marginLeft: "25%" }} src="/workdesigns/Bild3.png" alt="Kein Bild gefunden" width="50%" alignItems="center" />
              <Typography className={classes.textJustify} variant="subtitle1">
                <p align="middle">Abb.: 2 – Faktoren der Arbeitsumgebung</p><br />
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Wörwag, S. & Cloots, A. (Hrsg.). (2020).</b> Human Digital Work - eine Utopie? Erkenntnisse aus Forschung und Praxis zur digitalen Transformation der Arbeit. Springer Gabler. (S. 193 f).</p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )

      /* 3.1 Kollaboratives Arbeiten */
      case 31:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headeraspekte} align="center" variant="h4">
                Kollaboratives Arbeiten <p>(Aspekte & Beeinflussungsfaktoren)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Gestaltungsbereich<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Arbeitsorganisation
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Kompatible Aspekte & Beeinflussungsfaktoren<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Demokratisierung, Eigenverantwortung, Flexible Arbeitszeiten, Flexibler Arbeitsort /  Mobiles Arbeiten, Multispace Office, Virtuelle Teamarbeit
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Zielgruppe<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Kollaboration umfasst angefangen mit der Unternehmensleitung, über die Mitarbeitenden aus Entwicklung und Vertrieb bis hin zu Kunden, Lieferanten sowie Wissenschaft und Forschung alle an der Wertschöpfung von Smart-Services beteiligten Akteure.
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Mit Blick auf die bereits unter dem Punkt 2.4 aufgeführten Prinzipien der innovativen Arbeitsorganisation, ergibt sich für diesen Gestaltungsbereich der Beeinflussungsfaktor des kollaborativen Arbeitens. Grundlegend basiert diese Form der Arbeitsorganisation auf der kollektiven Nutzung von Intelligenz und Fähigkeiten sowie dem Wissens- und Erfahrungsaustausch, unter Voraussetzung der gemeinsamen Zielerreichung.
              </Typography>
              <br /> <img style={{ marginLeft: "30%" }} src="/workdesigns/Bild4.png" alt="Kein Bild gefunden" width="40%" alignItems="center" />
              <Typography className={classes.textJustify} variant="subtitle1">
                <p align="middle">Abb.: 3 - Kollaborative Arbeit</p>
              </Typography>
              <Typography className={classes.imageSourceText}>
                (Quelle: Freepik.com)
              </Typography>
              <br />
              <Typography className={classes.textJustify} variant="subtitle1">
                Die Zusammenarbeit der Mitarbeitenden soll dementsprechend mit Hilfe einer gemeinsamen und stärker vernetzten Ressourcennutzung verbessert werden. Wichtig zu beachten bleibt in diesem Zusammenhang, dass besonders unter den Aspekten der Flexibilisierung der Arbeitszeit und des Arbeitsortes, das kollaborative Arbeiten sowohl im Rahmen physischer Präsenz als auch über internetbasierte Anwendungen zu fördern ist.
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Chancen & Vorteile<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li>Verbesserung des Informationsaustausches unter den Mitarbeitenden sowie mit Kunden und Lieferanten</li>
                  <li>Erleichterung der Koordination von Aufgaben und Tätigkeiten</li>
                  <li>Steigerung und Verbesserung interdisziplinärer Zusammenarbeit und Problem-lösungsfähigkeit</li>
                  <li>Förderung von kollektiven Lerneffekten und Innovationen</li>
                  <li>Steigerung der Arbeitgeberattraktivität</li>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Herausforderungen<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li>Regelmäßige und zielorientierte Kommunikation aller Beteiligten</li>
                  <li>Transparente und offenere Räume für konstruktiven Ideenaustausch schaffen (z. B. Diskussionsforen, Multi-Space Office, Kreativ-Meetings in Präsenz oder Digital)</li>
                  <li>Ausweitung von Tätigkeitsbereichen und Etablieren von flachen Hierarchien zur Förderung bereichsübergreifender Kollaboration</li>
                  <li>Überwindung technologischer Vorurteile zur Etablierung internetbasierter kollaborativer Anwendungen, wie zum Beispiel Cloud-Anwendungen, Tools zur Online-Kommunikation (z. B. MS-Teams), Projektmanagement-Tools (z. B. Adobe workfront) sowie Anwendungen zum Vertragsabschluss (z. B. yousign)</li>
                  <li>Partizipation der beteiligten Mitarbeitenden, Kunden & Lieferanten zur Gestaltung und Organisation kollaborativer Arbeit</li>
                  <li>Entwicklung eines systematischen und unternehmensspezifischen Ansatzes zur Vermeidung ineffizienter Ad-hoc-Lösungen </li>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Klötzer, S., Hardwig, T. & Boos, M. (2017).</b> Gestaltung internetbasierter kollaborativer Team- und Projektarbeit. Gruppe. Interaktion. Organisation. Zeitschrift für Angewandte Organisationspsychologie (GIO), 48(4), S. 293–303.</p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )

      /* 3.2 Demokratisierung */
      case 32:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headeraspekte} align="center" variant="h4">
                Demokratisierung <br /> <p>(Aspekte & Beeinflussungsfaktoren)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Gestaltungsbereich<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Arbeitsorganisation, Arbeitsinhalt/-aufgabe, Arbeitszeit, Arbeitsort
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Kompatible Aspekte & Beeinflussungsfaktoren<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Kollaboratives Arbeiten, Eigenverantwortung, Flexible Arbeitszeiten, Flexibler Arbeitsort / Mobiles Arbeiten, Multispace Office, Virtuelle Teamarbeit
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Zielgruppe<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Smart-Service Owner, Smarte Vertriebler, Programmierer, weitere Office-Mitarbeiter
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Wie bereits erwähnt, spielt die Partizipation von Mitarbeitenden, aber auch von Kunden und Lieferanten eine zentrale Rolle im Rahmen des Wandels vom Produkt- zum Smart-Service Anbieter. Dieser Aspekt soll durch die Betrachtung der sogenannten „Demokratisierung“ als Beeinflussungsfaktor vertiefter beleuchtet werden. Zunächst ist es dafür notwendig, die Treiber dieses Prozesses aufzuzeigen und zu verstehen:
                <ol className={classes.sourceList}>
                  <li>Steigender Anteil an Wissensarbeit durch die Weiterentwicklung von Industrie 4.0</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Zunehmende Relevanz dezentralisierter Prozesse der Entscheidungsfindung</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Erleichterter Informationszugang für Mitarbeitende, Kunden & Lieferanten infolge der Digitalisierung</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Wunsch nach mehr Partizipation von Mitarbeitenden durch veränderte Werteorientierungen jüngerer Generationen</li>
                </ol>
                <br />
                Im Zusammenhang mit den aufgezeigten Treibern von „Demokratisierung“ in Unternehmen steht vor allem der Wandel der Arbeitseinstellung von Mitarbeitenden hin zu einer zunehmenden Forderung von Chancen, eigene Ideen und Talente zu verwirklichen. Es kommt daher stärker denn je darauf an, die Potentiale von flacheren Hierarchien und digitaler transformationaler Führung (Eigenverantwortung, Abb. 6) für das eigene Unternehmen systematisch zu ergründen und das Mitwirken von Mitarbeitenden bei Entscheidungen zu fördern. Für diesen Veränderungsprozess ergeben sich zum Beispiel folgende Ansatzpunkte:
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li>Übertragung von Eigenverantwortung für die Wahl von Arbeitszeiten und Arbeitsorte</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Partizipation von Mitarbeitenden im Rahmen von Personalentscheidungen fördern</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Konsensorientierte Entscheidungssituationen für die Verteilung von Arbeitsaufgaben in den verschiedenen Abteilungen schaffen (z. B. Projektgruppen & teilautonome Arbeitsteams)</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Steigerung der Arbeitgeberattraktivität</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Gruppenorientierte Beurteilung und Einschätzung von Arbeitsergebnissen ermöglichen (z. B. Qualitätszirkel)</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Teilnahme von Kunden & Lieferanten bei Fragen der Produktentwicklung</li>
                </ol>
              </Typography>
              <br /> <img style={{ marginLeft: "20%" }} src="/workdesigns/Bild5.png" alt="Kein Bild gefunden" width="60%" alignItems="center" />
              <Typography className={classes.textJustify} variant="subtitle1">
                <p align="middle">Abb.: 4 - Demokratisierung als Führungsaufgabe der zukünftigen Arbeitswelt (Quelle: Franken 2016, S. 48)</p><br />
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Franken, S. (2016).</b> Demokratisierung der Führung. In S. Franken (Hrsg.), Führen in der Arbeitswelt der Zukunft. Springer Fachmedien. (S. 119–143).</p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )

      /* 3.3 Selbstorganisation */
      case 33:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headeraspekte} align="center" variant="h4">
                Selbstorganisation <br /> <p>(Aspekte & Beeinflussungsfaktoren)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Gestaltungsbereich<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Arbeitsinhalt/-aufgabe, Arbeitszeit, Arbeitsplatz, Arbeitsumgebung
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Kompatible Aspekte & Beeinflussungsfaktoren<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Demokratisierung, Eigenverantwortung, Flexible Arbeitszeiten, Flexibler Arbeitsort
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Zielgruppe<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Smart-Service Owner, Smarte Vertriebler, Programmierer, weitere Office-Mitarbeiter
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Die Fähigkeit zur Selbstorganisation stellt eine zentrale Anforderung für die Mitarbeitenden eines Smart-Service Anbieters dar und gilt als entscheidende Voraussetzung für eine demokratischere Arbeitsorganisation. Neben der anforderungsgerechten Weiterbildung von Mitarbeitenden kommt es in diesem Zusammenhang besonders darauf an, dass die Unternehmenskultur die Entfaltung von Kompetenzen zur Selbstorganisation zunächst einmal zulässt. Der Wandel weg von hierarchischen Organisationsstrukturen hin zu einer agilen Organisationsform bietet dafür einen möglichen ersten Zugang. Als zentraler Verbindungspunkt zwischen den Angestellten und dem Unternehmen als Organisation, kommt es dabei vor allem auf Führungskräfte wie den Smart-Service Owner an, die dazu in der Lage sind, konkrete Lösungen für das autonome Arbeiten zu entwickeln.
              </Typography>
              <br /> <img style={{ marginLeft: "15%" }} src="/workdesigns/Bild6.png" alt="Kein Bild gefunden" width="75%" alignItems="center" />
              <Typography className={classes.textJustify} variant="subtitle1">
                <p align="middle">Abb.: 5 &ndash; Stärkung von Selbstorganisation über die Organisationsstruktur (eigene Darstellung)</p><br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Damit die Förderung von Selbstorganisation der Mitarbeitenden gelingen kann, ist es notwendig, den organisationalen Wandel sowie das nachfolgende Spannungsfeld (Abb. 5) auf die konkrete unternehmensspezifische Situation zu projizieren. Unter Partizipation von Mitarbeitenden und Führungskräften können somit die Potentiale einer gestärkten Selbstorganisation systematisch betrachtet und diskutiert werden.
              </Typography>
              <br />
              <Typography className={classes.textJustify} variant="subtitle1">
                &#10146; Selbstorganisation von Arbeitszeiten nach Thiemann et al. 2020, S. 346 f.:
                <ol className={classes.sourceList}>
                  <li>Festlegung organisationaler Regeln z.B. zur digitalen Erreichbarkeit außerhalb der Kernzeiten</li>
                  <li>Bestimmung des Verantwortungs-rahmens von Führungskräften für den Schutz der Mitarbeitenden vor Entgrenzung der Arbeitszeit</li>
                  <li>Entscheidungen über Freiräume zum selbstbestimmten, verantwortungsvollen Umgang mit Arbeitszeit </li>
                  <li>Diskussion einer Kombination dieser Faktoren</li>
                </ol>
              </Typography>


              <br /> <img style={{ marginLeft: "20%" }} src="/workdesigns/Bild7.png" alt="Kein Bild gefunden" width="60%" alignItems="center" />
              <Typography className={classes.textJustify} variant="subtitle1">
                <p align="middle">Abb.: 6 &ndash; Spannungsfeld zur Förderung von Selbstorganisation (in Anl. an Thiemann et al. 2020, S. 346 f.)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Herausforderungen<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li>Hohe Anforderung an das Verhalten und die Kompetenzen der Mitarbeitenden (z.B. Selbstmanagement & Eigenverantwortung)</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Risiken durch zu schnelle, unsystematische Ad-Hoc-Lösungen</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Mentaler Stress infolge von ergebnisorientierter Arbeitsorganisation</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Überwindung bürokratischer & zentralisierter Organisationsstrukturen</li>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Thiemann, D., Müller, M. & Kozica, A. (2020).</b> Selbstorganisation in komplexen digitalen Arbeitswelten. In O. Geramanis & S. Hutmacher (Hrsg.), uniscope. Publikationen der SGO Stiftung. Der Mensch in der Selbstorganisation Springer Fachmedien, S. 337–350.</p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )

      /* 3.4 Eigenverantwortung */
      case 34:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headeraspekte} align="center" variant="h4">
                Eigenverantwortung <br /> <p>(Aspekte & Beeinflussungsfaktoren)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Gestaltungsbereich<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Arbeitsinhalt/-aufgabe, Arbeitszeit, Arbeitsplatz, Arbeitsorganisation
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Kompatible Aspekte & Beeinflussungsfaktoren<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Demokratisierung, Kollaboratives Arbeiten, Selbstorganisation, Flexible Arbeitszeiten, Flexibler Arbeitsort / Mobiles Arbeiten
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Zielgruppe<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Smart-Service Owner, Smarte Vertriebler, Programmierer, weitere Office-Mitarbeiter
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Im Rahmen der kollaborativen Arbeitsorganisation und einer stärker geforderten Fähigkeit zur Selbstorganisation der Mitarbeitenden spielt der Aspekt höherer Eigenverantwortung eine wichtige Rolle. Besonders die mit dem Wandel vom Produkt- zum Smart-Service Anbieter verbundene Zunahme der Komplexität von Arbeitsaufgaben und des Wissens, hat für Führungskräfte eine Reihe von Herausforderungen zur Folge.
                So stellt sich in diesem Zusammenhang vorwiegend die Anleitung und Kontrolle von Mitarbeitenden schwieriger dar als in den traditionell etablierten Geschäftsbereichen. Mit Blick auf den Aspekt der Demokratisierung, geht die Forderung nach gesteigerter Partizipation zugleich mit der Anforderung von mehr Eigenverantwortung und somit auch mit der Eigeninitiative der Mitarbeitenden eines Smart-Service Anbieters einher.
                <br /><br />
                <b>Faktoren zur Förderung von Eigenverantwortung & Eigeninitiative:</b>
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li>Stärkung des Selbstvertrauens von Mitarbeitenden durch Unterstützung bei der Steigerung von Qualifikationen</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Regelmäßige Angebote zur Nutzung erweiterter Handlungsspielräume etablieren</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Einführung und Förderung von Konsensentscheidungen über flachere Hierarchien</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Durchführung von gemeinsamen Retrospektiven über abgeschlossene Projektetappen </li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Stärkung einer transparenten und konstruktiven Fehlerkultur</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Etablieren von neuen Führungsrollen (z. B. Product Owner, Projektteams)</li>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Hinweis<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Eine Ausweitung der Mitarbeiterautonomie kann ohne die vorhandene Eigeninitiative der Mitarbeitenden zu keiner Verbesserung der Innovationsfähigkeit führen. In diesem Zusammenhang stellt ein transformationaler Führungsansatz die Grundlage dafür dar, dass die Mitarbeitenden das Selbstvertrauen aufbringen, mehr Eigenverantwortung zu übernehmen.
                <br />
                &#10146; Winkler und Kollegen empfehlen dazu die in der folgenden Abbildung dargestellten Ansätze einer digitalen transformationalen Führung:
              </Typography>
              <br /> <img style={{ marginLeft: "25%" }} src="/workdesigns/Bild8.png" alt="Kein Bild gefunden" width="50%" alignItems="center" />
              <Typography className={classes.textJustify} variant="subtitle1">
                <p align="middle">Abb.: 7 &ndash; Digitale transformationalen Führung (Quelle: Winkler et al. 2020, S. 200)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Kaudela-Baum, S. & Altherr, M. (2020).</b> Freiheiten bewusst organisieren – oder: Wie führe ich eine Organisation in die Selbstorganisation? Ansatzpunkte autonomiefördernder Führung. In O. Geramanis & S. Hutmacher (Hrsg.), uniscope. Publikationen der SGO Stiftung. Der Mensch in der Selbstorganisation Springer Fachmedien, S. 125–141.</p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )

      /* 3.5 	Flexible Arbeitszeiten */
      case 35:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headeraspekte} align="center" variant="h4">
                Flexible Arbeitszeiten <br /> <p>(Aspekte & Beeinflussungsfaktoren)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Gestaltungsbereich<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Arbeitszeit, Arbeitsinhalt/Aufgabe, Ergonomie
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Kompatible Aspekte & Beeinflussungsfaktoren<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Selbstorganisation, Eigenverantwortung, Flexibler Arbeitsort / Mobiles Arbeiten, Work-Life-Balance, Virtuelle Teamarbeit
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Zielgruppe<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Smart-Service Owner, Smarte Vertriebler, Programmierer, weitere Office-Mitarbeiter
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Ein kompatibles Mittel zur Unterstützung von Selbstorganisation, Eigenverantwortung und einer besseren Work-Life-Balance ist die Einführung von neuen Konzepten der flexiblen Arbeitszeit. In diesem Zusammenhang werden nachfolgend neben unterschiedlichen Ansätzen ebenso die damit verbundenen Anforderungen sowie Chancen und Herausforderungen beleuchtet. Der Schwerpunkt liegt hierbei vor allem auf kompatiblen Konzepten mit Blick auf die Digitalisierung und den damit verbundenen Wandel vom Produkt- zum Smart-Service Anbieter im Mittelstand.
                <br /><br /><b>Anforderungen an neue flexible Arbeitszeitkonzepte:</b>
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li>Berücksichtigung des tariflichen & rechtlichen Rahmens:</li>
                  <ol className={classes.sourceList}>
                    <li>Arbeitszeitgesetz (ArbZG) – Arbeitsschutzgesetz (ArbSchG) – Teilzeit- und Befristungsgesetz (TzBfG)</li>
                    <li>Sozialgesetzbuch III (SGB III) – Mutterschutzgesetz (MuSchG) </li>
                    <li>Betriebsverfassungsgesetz (BetrVG) – Arbeitsstättenverordnung (ArbStättV)</li>
                  </ol>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Phasenweise Einführung neuer Arbeitszeitmodelle (z.B. 9-Phasen Modell der Bundesanstalt für Arbeitsschutz und Arbeitsmedizin)</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Partizipation von Beschäftigen, Betriebs- & Personalrat (§ 87 Abs. 1 Nr. 2 BetrVG) sowie Kunden, Zulieferern & Projektpartnern (z.B. Regelungen zur Erreichbarkeit)</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Beachtung von Arbeitszeitkonten als Bedingung flexibler Konzepte</li>
                </ol>
                <br /><b>Jobsharing</b>
                <ol className={classes.sourceList}>
                  <li>Mehrere Beschäftigte teilen sich in einer Form von Teilzeit eine Funktion und die damit verbundene Arbeitszeit. Mit diesem Arbeitszeitmodell ermöglicht der Arbeitgeber den Mitarbeitenden trotz möglicher Teilzeitphasen (z.B. Einarbeitung nach Elternzeit) dennoch die Partizipation an anspruchsvollen Projekten und Arbeitsaufgaben.</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Jobsharing kann außerdem zur Gewährleistung einer hohen Verfügbarkeit von Smart-Services dienen.</li>
                </ol>
                <br /><b>Gleitzeit</b>
                <ol className={classes.sourceList}>
                  <li>Das Arbeitszeitmodell beinhaltet eine sogenannte Kernarbeitszeit, in der eine Pflicht zur Anwesenheit besteht, sowie eine dem vor- und nachgelagerte Gleitzeit, in der die Mitarbeitenden den Arbeitsbeginn und das Ende eigenständig bestimmen können.</li>
                </ol>
                <img style={{ marginLeft: "25%" }} src="/workdesigns/Bild9.png" alt="Kein Bild gefunden" width="50%" alignItems="center" />
                <ol className={classes.sourceList}>
                  <li>Gleitzeit lässt sich unabhängig von der Unternehmensgröße leicht implementieren und ermöglicht somit eine unkomplizierte Flexibilisierung der Arbeitszeit unter Beachtung der Anwesenheit der Mitarbeitenden zu wichtigen Kerngeschäftszeiten.</li>
                </ol>
                <br /><b>Vertrauensarbeitszeit</b>
                <ol className={classes.sourceList}>
                  <li>Im Zusammenhang mit diesem Arbeitszeitmodell vertraut der Arbeitgeber den Mitarbeitern die eigenverantwortliche Bearbeitung der Arbeitsaufgaben in einem vorgegebenen Zeitrahmen an. Dabei besteht weder die Pflicht noch eine Kontrolle der Anwesenheit am Arbeitsplatz. Die fristgerechte Vorlage des Arbeitsergebnisses steht im Mittelpunk des Ansatzes und eignet sich besonders gut für die Arbeitsform der Projektarbeit.</li>
                  <li>Neben einem hoch ausgeprägten Pflichtbewusstsein der Mitarbeitenden, gilt ebenso ein starkes Vertrauen in die Fähigkeiten der Führungskräfte als Grundvoraussetzung für eine konstruktive Vertrauensarbeitszeit. Dies sollte besonders mit Blick auf eine drohende Entgrenzung der Arbeitszeit durch unrealistische Zielanforderungen beachtet werden, um mögliche Gesundheitsrisiken für die Mitarbeitenden zu vermeiden. Dazu gilt folgender rechtlicher Rahmen: <b>§ 16 Abs. 2 (ArbZG)</b></li>
                </ol>
                &#10146; Zusätzliche Informationen zu den hier vorgestellten und weiteren Modellen der Arbeitszeitgestaltung bietet der Praxisbericht der Bundesanstalt für Arbeitsschutz und Arbeitsmedizin: Flexible Arbeitszeitmodelle – Überblick und Umsetzung (2019).
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Chancen & Vorteile<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li>Steigerung der Arbeitsplatzsicherheit bei veränderlichen Auftragslagen und verschiedenen Lebensphasen der Beschäftigten (z. B. Eltern- & Pflegezeit)</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Optimierung der Wettbewerbsfähigkeit durch Anpassung der Arbeitszeitgestaltung an Kerngeschäftszeiten (z.B. Verbesserung des Kundenservices) und neue Formen der Arbeitsorganisation</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Steigerung der Arbeitgeberattraktivität im Wettbewerb um hoch qualifizierte Fachkräfte</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Verbesserung der zeitlichen und organisatorischen Voraussetzungen zur Durchführung von Weiterbildungsmaßnahmen</li>
                </ol>
                <ol className={classes.sourceList}>
                  <li>Förderung eines positiven Betriebsklimas durch höhere Motivation und Arbeitszufriedenheit</li>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Bundesanstalt für Arbeitsschutz und Arbeitsmedizin. (2019).</b> Flexible Arbeitszeitmodelle - Überblick und Umsetzung (2. Aufl.).</p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )

      /* 3.6 	Work-Life-Balance */
      case 36:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headeraspekte} align="center" variant="h4">
                Work-Life-Balance <br /> <p>(Aspekte & Beeinflussungsfaktoren)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Gestaltungsbereich<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Arbeitszeit, Ergonomie, Arbeitsplatz
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Kompatible Aspekte & Beeinflussungsfaktoren<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Arbeitsorganisation, Selbstorganisation, Flexible Arbeitszeiten, Flexibler Arbeitsort / Mobiles Arbeiten
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Zielgruppe<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Smart-Service Owner, Smarte Vertriebler, Programmierer, weitere Office-Mitarbeiter
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Im Klassischen Sinne wird unter dem Konstrukt der Work-Life-Balance <i>„ein Spannungsverhältnis verstanden, das die Vereinbarung von privaten und beruflichen Interessen ausdrückt.“</i> (Weber et al. 2018, S. 2)
                Durch den zunehmenden Einfluss der Entwicklungen im Bereich der Industrie 4.0, wie etwa dem Wandel vom Produkt- zum Smart-Service Anbieter, ist es jedoch notwendig den Blick auf den Begriff der Work-Life-Balance zu weiten und anzupassen.
                Besonders jüngere Generationen betrachten den Aspekt nicht mehr als bloßen <i>„Gleichgewichtszustand von Arbeit und Leben (…), sondern als das Ausbleiben von ein- oder gegenseitigen negativen Beeinflussungen zwischen den Lebensbereichen“. </i> (Paulus 2020, S. 281).
                Das bedeutet, dass zunehmend der Wunsch danach besteht, den Beruf als fördernden Faktor für die Freizeitgestaltung, das Familienleben sowie weitere private Ziele und Werte ausüben zu können.
              </Typography>
              <br /> <img style={{ marginLeft: "25%" }} src="/workdesigns/Bild10.png" alt="Kein Bild gefunden" width="50%" alignItems="center" />
              <Typography className={classes.textJustify} variant="subtitle1">
                <p align="middle">Abb.: 8 – Integrativer Ansatz der Work-Life-Balance</p>
              </Typography>
              <Typography className={classes.imageSourceText}>
                (eigene Darstellung unter Verwendung von pngegg.com)<br /><br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Durch folgende und weitere kreative Maßnahmen zur Förderung der Work-Life-Balance besteht neben der Verbesserung der Arbeitszufriedenheit und -motivation die Chance zur Steigerung der Arbeitgeberattraktivität im Wettbewerb um qualifizierte Fachkräfte.
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li>Einführung von neuen Konzepten flexibler Arbeitszeit und der mobilen Arbeit unter Berücksichtigung individueller Mitarbeitervoraussetzungen (z. B. Telearbeit bei Kinderbetreuung, Gleitzeit für flexiblere Freizeitgestaltung)</li>
                  <li>Unterstützungsangebote für Beschäftigte mit Kindern (z. B. Übernahme von Kinderbetreuungskosten, betriebliche Familienberatung, Vermittlung von Betreuungsangeboten)</li>
                  <li>Förderung einer Mobilitätsunterstützung (z. B. Wohnraumvermittlung, Umzugs-unterstützung, Arbeitsvermittlung für Lebenspartner)</li>
                  <li>Unterstützung von Weiterbildungs- und Qualifizierungsvorhaben der Mitarbeitenden</li>
                  <li>Einführung gesundheitsförderlicher Maßnahmen (z. B. Gesundheitsschutz, Sport-angebote, Informationsbereitstellung)</li>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Herausforderungen<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li>Der Arbeitgeber muss den Grad der Flexibilität von Arbeitszeit und Arbeitsort unter der Sicherstellung der Verfügbarkeit der Mitarbeitenden abwiegen. (z.B. durch Kernarbeitszeit)</li>
                  <li>Flexible Lösungen für Arbeitszeiten und mobiles Arbeiten beinhalten aufgrund der häufig 24/7 gewährleisteten Verfügbarkeit von Smart-Services ein hohes Risiko mit Blick auf die Entgrenzung von Arbeitszeiten.</li>
                  <li>Die digitale Erreichbarkeit der Mitarbeitenden verstärkt dieses Risiko, welches wiederum zu gesundheitlichen Beeinträchtigungen und Fehlzeiten beitragen kann.</li>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Paulus, S. (2020).</b> Work-Life-Balance 4.0 – Zentrale Herausforderungen. In S. Wörwag & A. Cloots (Hrsg.), Human Digital Work – Eine Utopie? Springer Fachmedien, S. 269–283. </p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )

      /* 3.7 Flexibler Arbeitsort / Mobiles Arbeiten */
      case 37:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headeraspekte} align="center" variant="h4">
                Flexibler Arbeitsort / Mobiles Arbeiten <p>(Aspekte & Beeinflussungsfaktoren)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Gestaltungsbereich<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Ergonomie, Arbeitsplatz, Arbeitsumgebung
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Kompatible Aspekte & Beeinflussungsfaktoren<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Selbstorganisation, Eigenverantwortung, Flexible Arbeitszeiten, Work-Life-Balance, Arbeitsplatz, Arbeitsumgebung, Arbeitsorganisation, Virtuelle Teamarbeit
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Zielgruppe<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Smart-Service Owner, Smarte Vertriebler, Programmierer, weitere Office-Mitarbeiter
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Aufgrund von veränderten Arbeits-inhalten sowie neuen Formen der Arbeitsorganisation ergibt sich im Rahmen des Wandels vom Produkt- zum Smart-Service Anbieter der Bedarf, das ortsflexible und vor allem mobile Arbeiten zu ermöglichen. Eng verbunden mit der Flexibilisierung der Arbeitszeit und den technischen Möglichkeiten der Digitalisierung eröffnen sich dabei vor allem für Smart-Service Mitarbeiter vielfältige Alternativen zum klassischen Büroarbeitsplatz.
              </Typography>
              <br /> <img style={{ marginLeft: "25%" }} src="/workdesigns/Bild11.png" alt="Kein Bild gefunden" width="50%" alignItems="center" />
              <Typography className={classes.textJustify} variant="subtitle1">
                <p align="middle">Abb.: 9 – Flexible Arbeitsorte</p>
              </Typography>
              <Typography className={classes.imageSourceText}>
                (eigene Darstellung unter Verwendung von pngegg.com)<br /><br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Im Folgenden sollen einige praxisrelevante Chancen sowie grundlegende Anforderungen an die Gestaltung von Konzepten für flexible Arbeitsorte betrachtet werden. Wie bereits bei vorangegangenen Beeinflussungsfaktoren erwähnt, lohnt es sich in diesem Zusammenhang jedoch darüber hinaus, die Mitarbeitenden partizipativ einzubinden und beispielsweise die positiven und negativen Erfahrungen aus corona- bedingten Home-Office Situationen für die Planung flexibler Arbeitsortkonzepte zu nutzen.
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Chancen & Vorteile<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li><b>Steigerung der Arbeitgeberattraktivität</b> und <b>Senkung von Fehlzeiten</b> mit Blick auf die Vereinbarkeit von Beruf und Familienleben sowie weitere Aspekte der Work-Life-Balance</li>
                  <li><b>Ermöglichung von Einsparungspotentialen</b> bei Zeit und Kosten für vermeidbare Arbeitswege der Beschäftigten</li>
                  <li>Chance zur <b>Steigerung der Leistungsbereitschaft</b> und Konzentrationsfähigkeit der Mitarbeitenden durch die Förderung von Eigenverantwortung und Selbstorganisation im Rahmen eines potenziellen Vertrauenszuwachses</li>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Herausforderungen<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li>Berücksichtigung des tariflichen & rechtlichen Rahmens:</li>
                  <ol className={classes.sourceList}>
                    <li>
                      <b>Digitale Selbstkompetenz:</b>
                      Ortsflexibles und mobiles Arbeiten stellt „hohe Anforderungen an die Belastbarkeit, Anpassungsfähigkeit und zeitliche Selbstorganisation der Beschäftigten und verlangt die Fähigkeit und Bereitschaft, sich und die eigene Arbeit eigenständig und eigenverantwortlich zu organisieren“. (Prümper 2017, S. 14)
                    </li>
                    <li>
                      <b>Digitale Sozialkompetenz:</b>
                      Das ortsflexible und mobile Arbeiten legt eine hohe Bereitschaft und Kenntnis im Umgang mit digitalen Formen der Kommunikation und des Datenaustausches zu Grunde. Im Besonderen erfordert dies, dass die Führungskräfte in Fragen der Gestaltung des Informationsaustausches und der Sicherstellung sozialer Beziehungen klare und kreative Grundsätze in Partizipation mit den Mitarbeitenden aufstellt.
                    </li>
                    <li>
                      <b>Digitale Sachkompetenz:</b>
                      Diese Kompetenz bezieht sich auf die Fähigkeit zur sicheren Arbeit im Internet als berufliche Arbeitsumgebung. In diesem Zusammenhang ist es zum einen wichtig, über grundlegende Kenntnisse zur aufgabenspezifischen Anwendung von digitalen Werkzeugen und Softwarelösungen zu verfügen. Zum anderen sollten die Mitarbeitenden jedoch auch die betrieblichen Richtlinien und Pflichten in Bezug auf Datenschutz und IT-Sicherheit beachten.
                    </li>
                  </ol>
                  <br />
                  <li>Das regelmäßige Monitoring von neuen Anforderungen an die digitale Sachkompetenz und die Ableitung des entsprechenden Weiterbildungsbedarfes stellt eine grundlegende betriebliche Aufgabe für die konstruktive Gestaltung ortsflexibler und mobiler Arbeitsformen dar.</li>
                  <li>Bereitstellung von digitalen Kommunikations- & Projektmanagementtools zur Unterstützung der kollaborativen Arbeit von ortsflexibel eingesetzten Mitarbeitenden (z. B. MS Teams, G Suite, Slack, awork, Zoom …)</li>
                  <li>Partizipation der Mitarbeitenden bei der Gestaltung von Konzepten für flexible Arbeitsorte sowie Gewährleistung von innerbetrieblichen Arbeitsplätzen für Mitarbeitende ohne Interesse an mobiler Arbeit</li>
                  <li>Berücksichtigung von verpflichtenden, allgemeinen Präsenzzeiten in regelmäßigen Abständen (z. B. Teammeetings bei Ergebnisberichten oder Kick-Offs)</li>
                  <li>Gesetzliche Pflicht zur Dokumentation der Arbeitszeit in mobiler Arbeit über digitale Arbeitszeitkonten sicherstellen. (z. B. Excel-Arbeitszeiterfassung, Windows WorkingHours, ManicTime, Clockodo)</li>
                  <li>Festlegung klarer Regelungen zur Erreichbarkeit der mobilen Beschäftigten und Vorgesetzen sowie Vereinbarung sicherer Mittel und Wege der Kommunikation und des Netzzuganges (z. B. VPN-Verbindung)</li>
                  <li>Aufklärung und Schulung der Beschäftigten zu präventiven physischen und psychischen Gesundheitsschutzmaßnahmen zur Vorbeugung einer Entgrenzung der mobilen Arbeit (z. B. Weiterbildung zur Zeitorganisation im Homeoffice) </li>
                  <li>Einhaltung gesundheitsförderlicher Standards zur Gestaltung mobiler Arbeitsplätze im Rahmen der Arbeitsraumgestaltung (z.B. ergonomische Büroeinrichtung, Faktor Licht)</li>
                  <li>Sicherstellung und Schulung von Datenschutzrichtlinien auf Seiten des Arbeitnehmers (z. B. Passwortsicherung bei geschäftlich genutzten mobilen Endgeräten) sowie des Arbeitgebers (v.a. Gewährleistung der Persönlichkeitsrechte von Angestellten), (z. B. Keyed.de, bfdi.bund.de)</li>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Prümper, J. (2017).</b> Mobiles Arbeiten 4.0 Anforderungen und Kompetenzen. ATZextra, 22(4), S. 14–15.</p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )

      /* 3.8 Arbeitsmittel */
      case 38:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headeraspekte} align="center" variant="h4">
                Arbeitsmittel <p>(Aspekte & Beeinflussungsfaktoren)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Gestaltungsbereich<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Ergonomie, Arbeitsplatz, Arbeitsumgebung
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Kompatible Aspekte & Beeinflussungsfaktoren<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Selbstorganisation, Flexible Arbeitszeit, Flexibler Arbeitsort / Mobiles Arbeiten, Virtuelle Teamarbeit
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Zielgruppe<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Smart-Service Owner, Smarte Vertriebler, Programmierer, weitere Office-Mitarbeiter
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Der Beeinflussungsfaktor des Arbeitsmittels ist grundlegend determiniert durch den Arbeitsinhalt sowie die Art des Arbeitsplatzes und der Arbeitsumgebung.
                Allgemein kann jedoch im Rahmen des Wandels vom Produkt- zum Smart-Service Anbieter erwartet werden, dass sowohl Wissensarbeiter als auch Blue-Collar-Worker, wie etwa am Maschinenarbeitsplatz, zunehmend mit Computern sowie mobilen Endgeräten wie Tablets und Smartphones arbeiten.
                <br />
                Darüber hinaus bieten ebenso neue Technologien wie etwa Datenbrillen im Bereich der Virtual & Augmented Reality interessante Einsatzmöglichkeiten. All diese durch die Digitalisierung vorangetriebenen Arbeitsmittel fördern und ermöglichen neue Formen der Arbeitsorganisation sowie die Flexibilisierung von Arbeitszeit und Arbeitsort.
                In diesem Zusammenhang ist es jedoch unbedingt notwendig die individuellen Ansprüche der Mitarbeitenden sowie die konkreten Anforderungen der Arbeitsinhalte bei der Planung neuer Arbeitsmittel zu berücksichtigen.
                Im Mittelpunkt steht dabei, dass die Auswahl und Planung neuer Arbeitsmittel unter dem Aspekt der ergonomischen Arbeitsgestaltung sowie in Partizipation der betroffenen Mitarbeitenden durchgeführt wird.
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beispielmuster<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li>Digitale Kommunikations- & Projektmanagementtools zur Unterstützung der kollaborativen Arbeit von ortsflexibel eingesetzten Mitarbeitenden (z.B. MS Teams, G Suite, Slack, awork, Zoom …)</li>
                  <li>Verteiltes Arbeiten im Bereich Programmierung durch Versionierungs-Softwares wie z. B. GIT oder SVN ermöglichen</li>
                </ol>
                Arbeitsmittel für den Smarten Vertriebler (u. a.):
                <ol className={classes.sourceList}>
                  <li>Laptop</li>
                  <li>Smart Service Demonstrator Smart Service</li>
                  <li>Platform as a Service (PaaS) </li>
                  <li>Applikationssoftware sowie deren Anbindung an ERP-Systeme</li>
                  <li>Zugang zum Smart Service (im Unternehmen)</li>
                  <li>Vertriebssysteme</li>
                  <li>Dienstwagen</li>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Fortmann, H. R. & Kolocek, B. (Hrsg.) (2018).</b> Arbeitswelt der Zukunft: Trends – Arbeitsraum – Menschen – Kompetenzen. Springer Gabler.</p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )

      /* 3.9 Arbeitsmittel */
      case 39:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headeraspekte} align="center" variant="h4">
                Mobile Devices <p>(Aspekte & Beeinflussungsfaktoren)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Gestaltungsbereich<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Ergonomie, Arbeitsmittel, Arbeitsplatz, Arbeitsumgebung
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Kompatible Aspekte & Beeinflussungsfaktoren<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Demokratisierung, Selbstorganisation, Multispace Office, Arbeitsraumgestaltung
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Zielgruppe<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Smart-Service Owner, Ingenieure, Produktionsmitarbeitende, Smarte Vertriebler, Programmierer, weitere Office-Mitarbeiter
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                In Verbindung mit der Entwicklung und der Anwendung von Smart-Services in einem Unternehmen steht häufig der Einsatz von Mobile Devices in Form von Tabletts oder auch Smartphones.
                Diese bieten zum einen die Möglichkeit als Arbeitsmittel neue Gestaltungsformen der Mensch-Maschine Interaktion zu nutzen und zum anderen als integrierter Teil der Arbeitsumgebung die unternehmensweite Kommunikation zu verbessern.<br /> Über individuell zugeschnittene Softwarelösungen können somit Informationen schneller und zielgerichteter innerhalb des Unternehmens ausgetauscht werden.
                In diesem Zusammenhang ergeben sich sehr viele verschiedene Ansätze der Integration von Mobile Devices in den Arbeitsalltag, wie zum Beispiel die mobile Organisation und Erfassung von Arbeitszeiten oder auch die Abfrage von Informationen über Arbeitsprozesse und Maschinen.
                Daher ist es notwendig den Einsatz von Mobile Devices mit Blick auf die individuellen Voraussetzungen des Unternehmens und den betroffenen Mitarbeitenden zu planen und abzustimmen.
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beispielmuster<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li>Vermeidung von Blendung sowie Spiegelung und somit auch ungünstiger Körperhaltungen durch reflexionsarme Bildschirme mit hoher Kontrastschärfe</li>
                  <li>Für Mobile Devices optimierte Zeichen und Darstellungen in ausreichender Größe sicherstellen</li>
                  <li>Abstimmung der erforderlichen Bildschirmgröße, des Gewichtes und dessen Positionierung mit den Bedingungen des Arbeitskontextes (z. B. höhenverstellbare Halterungen mit Option zur Entnahme leichter Geräte)</li>
                  <li>Vermeidung des Einsatzes von Mobile Devices (Tabletts & Smartphones) für langandauernde Arbeitstätigkeiten aufgrund der fehlenden Trennung von Anzeige und Eingabefläche</li>
                  <ol className={classes.sourceList}>
                    <li>Ergänzung manueller Eingabegeräte abwägen (z. B. Verfassung von Kurzberichten am Maschinenarbeitsplatz)</li>
                  </ol>
                  <li>Empfehlung der beidhändigen Nutzung Mobiler Devices bei fehlenden Aufstellungsvorrichtungen zur Vermeidung ungünstiger Körperhaltungen</li>
                  <li>Gestaltung von Sitzmöglichkeiten und Stehhilfen zur Vermeidung ungünstiger Körperhaltungen bei der Verwendung von Mobile Devices</li>
                  <li>Information der Mitarbeitenden über kritische Haltungsrisiken bei der Anwendung von Mobile Devices</li>
                </ol>
                <br />
                Weitere detailliertere Gestaltungshinweise gibt die DIN EN ISO 9241 (Stand 2012), im Besonderen jedoch der Teil - 303.
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Tegtmeier, P. (2016).</b> Review zu physischer Beanspruchung bei der Nutzung von Smart Mobile Devices. Bundesanstalt für Arbeitsschutz und Arbeitsmedizin (BAuA).</p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )

      /* 3.10 	Arbeitsraumgestaltung */
      case 310:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headeraspekte} align="center" variant="h4">
                Arbeitsraumgestaltung <p>(Aspekte & Beeinflussungsfaktoren)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Gestaltungsbereich<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Ergonomie, Arbeitsplatz, Arbeitsumgebung
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Kompatible Aspekte & Beeinflussungsfaktoren<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Selbstorganisation, Work-Life-Balance, Flexibler Arbeitsort / Mobiles Arbeiten, Arbeitsmittel, Multispace Office, Mobile Devices
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Zielgruppe<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Smart-Service Owner, Smarte Vertriebler, Programmierer, weitere Office-Mitarbeiter
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Besonders die Veränderungen in den Bereichen des Arbeitsinhaltes sowie der Flexibilisierung von Arbeitsplätzen, welche mit dem Wandel vom Produkt- zum Smart-Service Anbieter und der Digitalisierung der Arbeitswelt allgemein einhergehen, stellen neue Herausforderungen an die Gestaltung von Arbeitsräumen.
                Da die Nutzung digitaler Arbeitsmittel sowohl bei der Wissensarbeit in Büroräumen als auch bei flexiblen Arbeitsorten wie etwa Home-Office Lösungen zunehmend dem Standard entspricht, ist es wichtig die damit verbundenen ergonomischen Anforderungen sowohl an Arbeitsplätzen <b>innerhalb als auch außerhalb</b> des Unternehmens zu berücksichtigen.
                Das Ziel der Arbeitsplatzgestaltung besteht darin, physischen und psychischen Gesundheitsrisiken und somit auch Fehlzeiten bewusst vorzubeugen und eine innovative Produktivität im Arbeitsalltag sicherzustellen.
              </Typography>
              <br /> <img style={{ marginLeft: "25%" }} src="/workdesigns/Bild12.png" alt="Kein Bild gefunden" width="50%" alignItems="center" />
              <br />
              <Typography className={classes.textJustify} variant="subtitle1">
                <p align="middle">Abb.: 10 – Faktoren der Arbeitsraumgestaltung</p>
              </Typography>
              <Typography className={classes.imageSourceText}>
                (eigene Darstellung unter Verwendung von maul.de & pngegg.com)<br />
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beispielmuster<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li>Sicherstellung einer individuell flexiblen Anpassungsfähigkeit sowie einer dem Arbeitsinhalt angemessenen Größe und Auflösung von Bildschirmen (siehe auch: ArbStättV: Anhang 6)</li>
                  <ol className={classes.sourceList}>
                    <ol className={classes.sourceList}>
                      <li>Einstellung des oberen Bildschirmrandes auf Augenhöhe</li>
                      <li>Parallele Anordnung zu natürlichen Lichtquellen zur Vermeidung von beeinträchtigenden Reflexionen</li>
                      <li>Vermeidung der Überschreitung einer notwendigen Kopfrotation von mehr als 20° bei der Nutzung mehrerer Bildschirme</li>
                      <li>Ausreichenden Abstand zu Bildschirmen sicherstellen (mind. 500 mm)</li>
                      <li>Beachtung der Gestaltungshinweise und -anforderungen für Mobile Devices</li>
                    </ol>
                  </ol>
                  <br />
                  <li>Bereitstellung von geeigneten Eingabegeräten unter Berücksichtigung der <b>Bedingungen von Arbeitsaufgaben</b> sowie der ergonomischen Anforderungen mit Blick auf die <b>individuellen Voraussetzungen</b> der Anwendenden </li>
                  <ol className={classes.sourceList}>
                    <li>
                      z. B. 3D-Mäuse, Track-Ball-Mäuse, ergonomische Tastaturen, Eingabestifte, Leap-Motion-Controller, Geräte zur Audioeingabe)
                    </li>
                    <li>
                      Vermeidung von statisch-muskulärem Kraftaufwand über die Gewährleistung neutraler Körperhaltungen durch flexibel anpassungsfähige Eingabegeräte und Möglichkeiten der Armablage
                    </li>
                  </ol>
                  <br />
                  <li>Einsatz von höhenverstellbaren Bürotischen zur gesundheitsförderlichen Abwechslung zwischen sitzender und stehender Arbeitshaltung sowie zur Anpassung an unterschiedliche anthropometrische Bedingungen der Mitarbeitenden</li>
                  <br />
                  <li>Bereitstellung ergonomischer Stühle und Fußstützen für die Gewährleistung einer flexiblen Einstellbarkeit</li>
                  <ol className={classes.sourceList}>
                    <li>
                      Anpassungsfähigkeit an die Wirbelsäule und Stützfunktion im Lendenwirbelbereich der Rückenlehne sicherstellen
                    </li>
                    <li>
                      90° Winkel zwischen Ober- & Unterschenkel gewährleisten
                    </li>
                    <li>
                      Sensibilisierung der Mitarbeitenden mit Blick auf eine gesundheitsförderliche Sitzhaltung
                    </li>
                  </ol>
                  <br />
                  <li>Explizite Berücksichtigung von Arbeitsumweltfaktoren:</li>
                  <ol className={classes.sourceList}>
                    <li>
                      Frischluftversorgung unter Zugluftfreiheit sicherstellen
                    </li>
                    <li>
                      Vermeidung der Temperaturüberschreitung von 26°C, Berücksichtigung der Optimaltemperatur von 20-22°C
                    </li>
                    <li>
                      Bereitstellung einer Beleuchtungsstärke von mind. 500 Lux im direkten Arbeitsbereich und mind. 300 Lux in anliegenden Bereiche
                    </li>
                    <li>
                      Unter der Bedingung einer Blendungsvermeidung neben künstlicher Beleuchtung ebenso natürliche Beleuchtungsquellen nutzen
                    </li>
                    <li>
                      Störende Geräusche und Lärm vermeiden und Ruhezonen einrichten (z.B. Ruheräume im Rahmen eines Multispace Office)
                    </li>
                  </ol>
                  <br />
                  <li>Berücksichtigung der Pflicht zur Gefährdungsbeurteilung (§5 ArbSchG; §3 ArbStättV)  und Unterweisung der Beschäftigten (§6 ArbStättV) von Seiten des Arbeitgebers sowie zur Gewährung des dafür Notwendigen Zugangsrechts von Seiten des Arbeitnehmers in <b>Home-Office Situationen</b>.</li>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Rüffert, D., Heß, J. & Bullinger-Hoffmann, A. C. (2017).</b> Ergonomie am wissensintensiven Arbeitsplatz - Problemstellung und Lösungsansätze. In H. Breit, A. C. Bullinger-Hoffmann & U. Cantner (Hrsg.), Produktivität von industriellen Dienstleistungen in der betrieblichen Praxis (S. 163–179). Springer Fachmedien.</p>
                  <p>&#10146; <b>Deutsche Gesetzliche Unfallversicherung e.V. (2016).</b> Büroraumplanung - Hilfen für das systematische Planen und Gestalten von Büros. DGUV Information, S. 215–441.</p>
                  <p>&#10146; <b>Sandrock, S. & Niehues, S. (2020).</b> CHECKLISTE zur ergonomischen Bewertung von Tätigkeiten, Arbeitsplätzen, Arbeitsmitteln & Arbeitsumgebung – Teil B: Büro- und Bildschirmarbeit – Teil C: Mobile Arbeitsmittel – Teil C: Software-ergonomie. In ifaa - Institut für angewandte Arbeitswissenschaft e.V. (Hrsg.), ifaa-Checklisten (3. Aufl.).</p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )

      /* 3.11 Multispace-Office */
      case 311:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headeraspekte} align="center" variant="h4">
                Multispace-Office <p>(Aspekte & Beeinflussungsfaktoren)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Gestaltungsbereich<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Ergonomie, Arbeitsplatz, Arbeitsumgebung, Arbeitsorganisation
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Kompatible Aspekte & Beeinflussungsfaktoren<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Raumgestaltung, Mobile Devices, Virtuelle Teamarbeit, Kollaboratives Arbeiten, Selbstorganisation, Flexibler Arbeitsort, Arbeitsmittel
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Zielgruppe<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Smart-Service Owner, Ingenieure, Smarte Vertriebler, Programmierer, weitere Office-Mitarbeiter
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Ein Multispace Office ist ein alternatives Bürokonzept, das einen effektiveren Informationstransfer durch verbesserte Interaktions- und Kommunikationsbedingungen ermöglicht und zugleich die notwendige Privatsphäre für Einzelarbeiten sicherstellt.
                Der Kernaspekt des Konzepts liegt dabei in der grundlegenden Vielfalt der Arbeitsplätze, die von den Mitarbeitern flexibel, entsprechend bestimmter Tätigkeitsanforderungen genutzt werden können.
                Dieses sogenannte non-territoriale Bürokonzept beinhaltet zum Beispiel Ruhebereiche mit Einzelarbeitsplätzen, offen gestaltete Kreativräume für Gruppenarbeiten sowie Pausenbereiche und Meeting-Räume und eröffnet somit folgende
                <b> Chancen und Vorteile:</b><br /><br />
                <ol className={classes.sourceList}>
                  <li>Hohe Unterstützungsfunktion der Arbeitsumgebung zur Umsetzung von Unternehmenszielen und effektivere Anpassung an Projektstrukturen durch flexible, tätigkeitspezifische Raumalternativen</li>
                  <li>Stärker gelebte Zusammenarbeit & höheres Ausmaß an Selbstbestimmung</li>
                  <li>Verbesserte Rückzugsmöglichkeiten zur besseren Konzentration oder auch Erholung</li>
                  <li>Signifikantes Steigerungspotential der Arbeitgeberattraktivität</li>
                  <li>Höhere gestalterische Qualität & somit Steigerung des Unternehmensimages</li>
                </ol>
                <br />
                Neben den beschriebenen Chancen bringt das non-territoriale Bürokonzept jedoch auch einige Herausforderungen mit sich, die bei der Planung nicht vernachlässigt werden dürfen:

                <ol className={classes.sourceList}>
                  <br />
                  <li>Aktive und bewusste psychosoziale Mitarbeiterführung zur Vermeidung von höheren Konfliktpotentialen</li>
                  <li>Vermeidung von Konzentrationsschwierigkeiten & Arbeitsunterbrechungen durch irrelevante beiläufige Gespräche/Räume für Privatsphäre schaffen</li>
                  <li>Beachtung ausreichender Anpassungsfähigkeit der Arbeitsplätze aufgrund des non-territorialen Gestaltungsansatzes</li>
                  <li>Partizipation der Mitarbeitenden bei der Gestaltung sicherstellen</li>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beispielmuster<br />
              </Typography>
              <br /> <img style={{ marginLeft: "15%" }} src="/workdesigns/Bild13.png" alt="Kein Bild gefunden" width="70%" alignItems="center" />
              <br />
              <br /> <img style={{ marginLeft: "30%" }} src="/workdesigns/Bild14.png" alt="Kein Bild gefunden" width="40%" alignItems="center" />
              <Typography className={classes.textJustify} variant="subtitle1">
                <p align="middle">Bildquelle: Professur Arbeitswissenschaft & Innovationsmanagement</p><br />
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Haner, U. E. & Wackernagel, S. (2019).</b> ORGATEC Sonderbericht zur Studie "Wirksame Büro- und Arbeitswelten": Erfolgsfaktoren für eine wirksame Gestaltung von Büro- und Arbeitswelten. Fraunhofer-Institut für Arbeitswirtschaft und Organisation IAO.</p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )

      /* 3.12 	Virtuelle Teamarbeit */
      case 312:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headeraspekte} align="center" variant="h4">
                Virtuelle Teamarbeit <p>(Aspekte & Beeinflussungsfaktoren)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Gestaltungsbereich<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Ergonomie, Arbeitsplatz, Arbeitsumgebung, Arbeitsorganisation
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Kompatible Aspekte & Beeinflussungsfaktoren<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Kollaboratives Arbeiten, Demokratisierung, Arbeitsmittel, Flexibler Arbeitsort / Mobiles Arbeiten, Flexible Arbeitszeiten, Work-Life-Balance
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Zielgruppe<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Smart-Service Owner, Ingenieure, Smarte Vertriebler, Programmierer, weitere Office-Mitarbeiter
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                Besonders im Zusammenhang mit dem Beeinflussungsfaktor des kollaborativen Arbeitens spielt die virtuelle Teamarbeit, neben Arbeitsformen in Präsenz eine zentrale Rolle. Dem damit verbundenen Bedarf der stärker vernetzten Nutzung und Bereitstellung von Ressourcen, wie Erfahrungen und Wissen aber auch von Arbeitsergebnissen sowie organisatorischen Arbeitsinhalten, kann mit Hilfe digitaler Lösungen direkt begegnet werden.
                Weiterhin ermöglicht die digitale Teamarbeit einen höheren Grad an Flexibilität mit Blick auf den Arbeitsort sowie die Arbeitszeit. Bei der systematischen Planung dieser Arbeitsform ist es jedoch entscheidend die unterschiedlichen Arbeitsbedingungen und individuellen Voraussetzungen der digital vernetzten Teammitglieder genau zu berücksichtigen. Die partizipative Teilnahme der beteiligten Mitarbeitenden gilt daher als Grund-voraussetzung für die erfolgreiche Organisation von klaren unternehmens-spezifischen Leitlinien für die virtuelle Teamarbeit.
              </Typography>
              <br /> <img style={{ marginLeft: "20%" }} src="/workdesigns/Bild15.png" alt="Kein Bild gefunden" width="60%" alignItems="center" />
              <Typography className={classes.textJustify} variant="subtitle1">
                <p align="middle">Abb.: 11 – Virtuelle Teamarbeit</p>
              </Typography>
              <Typography className={classes.imageSourceText}>
                (eigene Darstellung in Anlehnung an https://www.pngegg.com/en/png-tlqqi)<br /><br />
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beispielmuster<br /><br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <b>Mitarbeiterebene:</b>
                  <ol className={classes.sourceList}>
                    <li>Einrichtung von E-Mail-Filtern und konkreten virtuellen Kommunikationsräumen zur Förderung eines klaren, strukturierten und zielorientierten Austausches ohne irrelevante Benachrichtigungen</li>
                    <li>Telekooperationskompetenzen durch Einweisung sicherstellen und durch Weiterbildung fördern (z. B. Selbstmanagement, Eigenverantwortung, interpersonelles Vertrauen, Fähigkeit zur Nutzung virtueller Tools)</li>
                  </ol>
                  <br />
                  <b>Teamebene:</b>
                  <ol className={classes.sourceList}>
                    <li>Festlegung einer klaren Rollenverteilung (z. B. Moderatorenrolle)</li>
                    <li>Sicherstellung von Kommunikationsleitlinien und regelmäßigen Stimmungsabfragen mit Möglichkeiten zur Intervention zur Vorbeugung von Konfliktpotentialen virtueller Teamarbeit</li>
                    <li>Gewährleistung der Dokumentation von Interaktionen sowie deren klare Zugänglichkeit für alle Teammitglieder (z. B. Gesprächsprotokolle, Ergebnisse, Inhalte der Zielbestimmung)</li>
                    <li>Förderung des Erfahrungsaustauschen durch Präsenztreffen, besonders zu Projektbeginn & Projektabschluss</li>
                    <li>Einheitlich abgestimmte Nutzung von digitalen Kommunikationstools unter Berücksichtigung individueller und projektbezogener Anforderungen (z. B. Cloudverfügbarkeit, Qualitätsanforderung an Video- & Tonübertragung, max. Teilnehmerzahlen, Whiteboard-Funktionen, Umfrageoptionen)</li>
                  </ol>
                  <br />
                  <b>Führungskräfteebene:</b>
                  <ol className={classes.sourceList}>
                    <li>Sicherstellung klarer und regelmäßiger Feedbackstrukturen (z. B. Anwendung von Online-Feedback-Tools, Sicherstellung persönlicher Rückmeldung zu bestimmten Zeitpunkten)</li>
                    <li>Förderung von geteilter Führung und struktureller Unterstützung durch Belohnungs-systeme sowie eine transparente Informations- und Kommunikationskultur</li>
                    <li>Wissensorientierung mit Blick auf zukünftige Erkenntnisse gesundheits- und leistungsfördernder Faktoren im Rahmen der virtuellen Teamarbeit (z. B. Studien zur Aufarbeitung der Home-Office-Erfahrungen aus der Corona-Pandemie)</li>
                  </ol>
                  <br />
                  <b>Organisationsebene:</b>
                  <ol className={classes.sourceList}>
                    <li>Gewährleistung der erforderlichen technischen Ausstattung durch anforderungsgerechte Hardware & Software, gesicherte Netzwerke und schnelle Internetverbindung</li>
                    <li>Gesundheitsschutzmaßnamen durch ergonomische Arbeitsplatzgestaltung auch bei flexiblen und mobilen Arbeitsformen wie z. B. im Homeoffice sicherstellen</li>
                  </ol>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Herausforderungen<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li>Mangelndes Vertrauen von Führungskräften kann zu unverhältnismäßiger Kontrolle der Mitarbeitenden führen.</li>
                  <li>Einer mangelnden spontanen Kommunikation z.B. über den Fluhrfunk oder in Pausen ist mit Teambuildingmaßnahmen entgegenzuwirken. Andernfalls können negative Effekte auf die Organisationskultur und den Zusammenhalt im Unternehmen entstehen.</li>
                  <li>Die Zusammenarbeit ohne persönlichen Kontakt kann zu Einschränkungen der Arbeitsmotivation führen.</li>
                  <li>Eine unterschiedliche technische Ausstattung von Mitarbeitenden in virtuellen Teams kann zu Terminverzug und Arbeitsunzufriedenheit führen.</li>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Kordsmeyer, A.‑C., Mette, J., Harth, V. & Mache, S. (2019).</b> Wie können virtuelle Teams leistungsfördernd geführt werden? Zentralblatt für Arbeitsmedizin, Arbeitsschutz und Ergonomie, 69(5), S. 313–318.</p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )


      /* 4.1 		Virtuelles Job-Shadowing */
      case 41:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headergestaltungsmethoden} align="center" variant="h4">
                Virtuelles Job-Shadowing <p> (Methoden der Arbeitsgestaltung) </p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li>Problem: Remote Onboarding und Einzelbüros bedingen eine fehlende Validierung des Arbeitsalltags durch Erfahrungsaustausch mit Kollegen</li>
                  <li>Umsetzungswege eröffnen sich über den bewussten Einsatz von Konzepten zum Agilen Lernen sowie von innerbetrieblichen sozialen Netzwerken</li>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Umsetzung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <b>&rarr;	Allgemein: Arbeit mit virtueller Anwesenheit über Video + Ton</b>
              </Typography>
              <br />
              <img style={{ marginLeft: "3%" }} src="/workdesigns/Bild16.png" alt="Kein Bild gefunden" width="94%" alignItems="center" />
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beispiele:<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <table width="100%" cellspacing="15px" border="0px">
                  <tr valign="top">
                    <td>
                      <b><center>Kollegiale Fallberatung</center></b>
                      <ol className={classes.sourceList}>
                        <li>Digitales Treffen von max. 10 Kollegen, 2-4 Std., digital oder analog</li>
                        <li>Rollenzuweisung in Fallgeber, Moderator und Berater</li>
                        <li>Austausch über Problemfälle aus dem individuellen Arbeitsalltag</li>
                        <li>Ziele:</li>
                        <ol className={classes.sourceList}>
                          <li>Perspektivwechsel ermöglichen,</li>
                          <li>Stärkung von Selbstorganisation,</li>
                          <li>praktischer Erfahrungsaustausch,</li>
                          <li>Stärkung der Teamorientierung</li>
                        </ol>
                      </ol>
                    </td>
                    <td>
                      <b><center>virtuelle Hackathons</center></b>
                      <ol className={classes.sourceList}>
                        <li>Ursprung: Prototypentwicklung in der Programmierszene</li>
                        <li>Formulierung von Arbeitsziel & Zeitrahmen der Bearbeitung</li>
                        <li>Lernen durch Arbeiten im Mittelpunkt</li>
                        <li>Geeignet für 1-2 Tagesprojekte</li>
                        <li>Option eines Wettbewerbsformats:</li>
                        <ol className={classes.sourceList}>
                          <li>Zusammenstellung von interdisziplinären Teams</li>
                          <li>Vorgegebene Challenge</li>
                          <li>Ergebnisbewertung durch eine „Jury“ (z. B. Geschäftsleitung)</li>
                        </ol>
                      </ol>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td>
                      <b><center>Open Space</center></b>
                      <ol className={classes.sourceList}>
                        <li>Fokus auf Kooperation in selbstorganisierten Arbeitsteams</li>
                        <li>Bearbeitung dringlicher Themen mit hohem Interessenpotential der Teilnehmenden</li>
                        <li>Selbstbestimmte Festlegung von Teilthemen & Teilnahme an Themensessions</li>
                        <li>Open Space Rollen:</li>
                        <ol className={classes.sourceList}>
                          <li>Sessiongeber initiieren und dokumentieren die Themen</li>
                          <li>Moderator zur Leitung der Veranstaltung</li>
                          <li>Teilnehmer bearbeiten das Thema & kommunizieren / diskutieren die Ergebnisse</li>
                        </ol>
                      </ol>
                    </td>
                    <td>
                      <b><center>Working out loud – Learning out loud</center></b>
                      <ol className={classes.sourceList}>
                        <li>Ziel ist Transparenz und gegenseitige Unterstützung in der Zusammenarbeit </li>
                        <li>Etablierung eines möglichst breiten Lernfeldes durch Teilnahme diverser Arbeitsrollen</li>
                        <li>Abwechselnde Rollenbesetzung des Moderators / Themeninitiators</li>
                        <li>Kernfrage des Meetings:</li>
                        <ol className={classes.sourceList}>
                          <li>„Was möchte ich erreichen [und] wer könnte mich dabei unterstützen?“ (Hasenbein, 2020)</li>
                        </ol>
                      </ol>
                    </td>
                  </tr>
                </table>
              </Typography>

              <Typography className={classes.textJustify} variant="subtitle1">
                <b>&rarr; Innerbetriebliche soziale Netzwerke als Medium:</b>
                <ol className={classes.sourceList}>
                  <li>Anforderungen:</li>
                  <ol className={classes.sourceList}>
                    <li>Intuitives Nutzerinterface, abgestimmt auf individuelle Ansprüche des Unternehmens</li>
                    <li>Optionen zur offenen Kollaboration über Hierarchieebenen hinweg</li>
                    <li>Keine Funktion zur Mitarbeiterüberwachung</li>
                    <li>Anpassung an betriebsinterne Kommunikationsstandards und Mitarbeiterrollen (z.B. Administratoren, Contentcreator …)</li>
                  </ol>
                  <li>Technische Voraussetzungen:</li>
                  <ol className={classes.sourceList}>
                    <li>Mobile Kommunikationstechnik</li>
                    <li>Betriebsinterne Serverinfrastruktur</li>
                  </ol>
                  <li>Beispiele: Microsoft Teams, Staffbase, LOLYO Mitarbeiterapp</li>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <b>Herausforderungen:</b>
                <ol className={classes.sourceList}>
                  <li>Klare & regelmäßige Kommunikation über digitale Plattformen</li>
                  <li>Abnehmende Aufmerksamkeit bei längeren Online-Meetings</li>
                  <li>Missverstandene Informationen & eingeschränkte Detailwahrnehmung über digitale Kommunikation</li>
                </ol>
              </Typography>
              <br /> <img style={{ marginLeft: "30%" }} src="/workdesigns/Bild17.png" alt="Kein Bild gefunden" width="40%" alignItems="center" />
              <Typography className={classes.imageSourceText}>
                Quelle: istockphoto.com
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Vorteile<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li>Förderung fachlicher, methodischer und sozialer Kompetenzen</li>
                  <li>Ermutigung zur Selbstreflektion bei beiden Beteiligten</li>
                  <li>Aufdecken von Optimierungspotenzialen</li>
                  <li>Motivation, die eigene Arbeitsweise effizienter zu gestalten</li>
                  <li>Wissens- und Erfahrungsaustausch</li>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Griffith University (Hrsg.). (2022).</b> JOB SHADOWING - Specialist Programs. https://intranet.secure.griffith.edu.au/employment/learning-and-development/specialist-programs/job-shadowing .</p>
                  <p>&#10146; <b>Randel, S. & Schlabbers, M. (2019).</b> Remote Job Shadowing als Scrum Master: voneinander lernen. https://www.inovex.de/de/blog/remote-job-shadowing-scrum-master/ .</p>
                  <p>&#10146; <b>Dupley, S. (2021).</b> Job shadowing during a pandemic. https://www.universityaffairs.ca/career-advice/responsibilities-may-include/job-shadowing-during-a-pandemic/ .</p>
                  <p>&#10146; <b>Hasenbein, M. (2020).</b> Der Mensch im Fokus der digitalen Arbeitswelt: Wirtschaftspsychologische Perspektiven und Anwendungsfelder. Springer.</p>
                  <p>&#10146; <b>Daum, M., Wedel, M., Zinke-Wehlmann, C. & Ulbrich, H. (Hrsg.). (2020).</b> Gestaltung vernetzt-flexibler Arbeit: Beiträge aus Theorie und Praxis für die digitale Arbeitswelt. Springer Vieweg. </p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )

      /* 4.2 	AR / VR – Technologien */
      case 42:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headergestaltungsmethoden} align="center" variant="h4">
                AR / VR – Technologien <p> (Methoden der Arbeitsgestaltung) </p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li>Technologien im Bereich der Augmented-Reality (AR) und Virtual-Reality (VR) verfügen über das Potential die Art und Weise wie wir zusammen arbeiten drastisch zu verändern.</li>
                  <li>Damit ergeben sich für den Wissensarbeitsplatz immer neue Methoden mit Blick auf die Gestaltung der ortsflexiblen und mobilen Arbeit.</li>
                  <li>Aber auch für den festen Büroarbeitsplatz werden aktuell innovative Methoden zur Gestaltung virtueller Arbeitsumgebungen entwickelt, die eines Tages zur Reduktion der Arbeitsbelastung beitragen könnten.</li>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Ziele und Herausforderungen<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li><b>Visionen der virtuellen Büroarbeit:</b></li>
                  <ol className={classes.sourceList}>
                    <li>Kontrolle und individuelle Gestaltung der virtuellen Arbeitsumgebung mittels VR-Head-Mounted-Displays</li>
                    <li>Ortsunabhängige Wiederholung von Arbeitssituationen mit Erfahrungs- und Lernpotential</li>
                    <li>Virtuelle Anwesenheit in vertrauten Arbeitsumgebungen und alltägliche Mitarbeiterinteraktionen bei mobilen Arbeitseinsätzen</li>
                    <li>Sicherstellung der Privatsphäre über virtuelle Arbeitsplätze in Situationen der mobilen Arbeit</li>
                    <li>Durchbrechen von Limitationen der physischen Umwelt</li>
                  </ol>
                  <br />
                  <li><b>Herausforderungen virtueller Wissensarbeit</b></li>
                  <ol className={classes.sourceList}>
                    <li>Eingeschränkte Bildqualität von VR- & AR Brillen</li>
                    <li>Fehlende Interaktion mit dem realen Umfeld</li>
                    <li>Risiko von Gleichgewichtsstörungen, Kopfschmerzen, Schwindelgefühl bei virtuellen Bewegtbildern</li>
                    <li>Fehlendes Situationsbewusstsein und physische Isolation</li>
                    <li>Eingeschränkte Eingabeeffizienz bei der Steuerung von VR- & AR-Anwendungen</li>
                  </ol>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Lang, B. (2021).</b> 27 VR Apps for Remote Work, Education, Training, Design Review, and More. https://www.roadtovr.com/vr-apps-work-from-home-remote-office-design-review-training-education-cad-telepresence-wfh/ .</p>
                  <p>&#10146; <b>Grubert, J., Ofek, E., Pahud, M. & Kristensson, P. O. (2018).</b> The Office of the Future: Virtual, Portable, and Global. IEEE computer graphics and applications, 38(6), S. 125–133. </p>
                  <p>&#10146; <b>Reipschlager, P., Flemisch, T. & Dachselt, R. (2021).</b> Personal Augmented Reality for Information Visualization on Large Interactive Displays. IEEE transactions on visualization and computer graphics, 27(2), S. 1182–1192.</p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )

      /* 4.3 	Work-Life-Integration */
      case 43:
        return (
          <div className={classes.modalPaper}>
            <div className={classes.innerDiv}>
              <Typography gutterBottom className={classes.headergestaltungsmethoden} align="center" variant="h4">
                Work-Life-Integration <p>(Methoden der Arbeitsgestaltung)</p>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Beschreibung<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li>Aus veränderten Arbeitsinhalten und daraus resultierenden Konzepten der mobilen, orts- und zeitflexiblen Arbeitsgestaltung ergeben sich vielfältige Potentiale, den Arbeitsplatz und das Privatleben integrativ zu betrachten.</li>
                  <li>In diesem Zusammenhang rücken unter anderem Alternativen zum klassischen Home-Office sowie zur digitalen Teamarbeit in den Fokus der Gestaltung und Organisation von Wissensarbeit.</li>
                  <li>Eingehende Beispiele sind etwa Communities im Bereich des <b>Coworking</b> oder freizeitverbindende Konzepte wie z.B. <b>Workation</b>.</li>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Coworking<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li><b>Grundverständnis:</b></li>
                  „Coworking ist ein flexibilisiertes, temporäres, progressives Arbeitsmodell mit geteilten Ressourcen an einem gemeinsamen institutionalisierten Ort, durch den ein soziales Netzwerk mit anderen Personen entsteht und durch den Potenziale für Kooperationen über die eigene Arbeitstätigkeit hinaus entstehen.“ (Werther, 2021, S. 4)
                  <li>Neben dem Home-Office entstehen sog. <b>Coworking-Spaces</b> als alternative außerbetriebliche Arbeitsräume:</li>
                  <ol className={classes.sourceList}>
                    <li>Externe Anbieter übernehmen die Gestaltung vielfältiger Bürokonzepte und etablieren ein Netz aus <b>flexibel nutzbaren Arbeitsorten.</b></li>
                    <li>Unternehmen oder auch Freelancer buchen einen Arbeitsplatzzugang und haben darüber hinaus häufig die Möglichkeit, weitere professionelle Services wie Weiterbildungen oder auch <b>soziale Services</b> wie Kinderbetreuung und Freizeitangebote arbeitsplatznah wahrzunehmen.</li>
                    <li>Der Kernaspekt beinhaltet dabei eine <b>systematische Raumkonzeption</b> durch die Verbindung von <b>kollaborativen</b> und <b>nicht-kollaborativen</b> Arbeitsbereichen.</li>
                    <li>Coworking Spaces verfolgen häufig den Ansatz des <b>Multispace-Office</b>, nur dass es sich eben um einen außerbetrieblichen Arbeitsraum handelt.</li>
                  </ol>
                  <li><b>Vision: In Innenstädten etablieren sich zukünftig mehrere extern angebotene Arbeits-räume die Cafés, Ruheräume & Großraumbüros unter einem Dach in Coworking-Spaces bündeln.</b></li>
                </ol>
              </Typography>
              <br /> <img style={{ marginLeft: "30%" }} src="/workdesigns/Bild18.png" alt="Kein Bild gefunden" width="40%" alignItems="center" />
              <Typography className={classes.textJustify} variant="subtitle1">
                <p align="middle">Bildquelle: Professur Arbeitswissenschaft & Innovationsmanagement</p>
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <b>Chancen</b>
                <ol className={classes.sourceList}>
                  <li>Einsparungspotentiale im Bereich von Büroräumen und Verwaltung</li>
                  <li>Bereitstellung der benötigten digitalen Infrastruktur durch den Coworking-Space-Anbieter</li>
                  <li>Netzwerkpotentiale zwischen Mitarbeitenden unterschiedlicher Unternehmen</li>
                  <li>Strukturierter Arbeitsalltag trotz mobiler Arbeit, mit Möglichkeiten zum sozialen Austausch</li>
                </ol>
                <br />
                <b>Herausforderungen</b>
                <ol className={classes.sourceList}>
                  <li>Wenig Einfluss auf tagesaktuelle Zusammensetzung der „Coworkenden</li>
                  <li>Soziale Konfliktpotentiale durch heterogenes Nutzerprofil</li>
                  <li>Störfaktoren durch physische Einflüsse wie etwa Lärm sowie durch psychische Beanspruchung bei fehlenden Rückzugsräumen</li>
                </ol>
              </Typography>

              <Typography variant="h5" align="left" className={classes.textHeader}>
                Workation<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li><b>Grundverständnis:</b></li>
                  „Workation (ist) als Kombination der englischen Begriffe für Arbeit („Work“) und Urlaub („Vacation“) erst einmal die Hybridversion eines Urlaubsaufenthalts oder einer kürzeren oder längeren Reise, die zusätzlich Arbeitsphasen beinhaltet. (S. Werther, 2021)
                  <li>Individuelle Workations sind zeitlich und örtlich flexible Arbeitsmodelle und erfordern daher folgende Voraussetzungen:</li>
                  <ol className={classes.sourceList}>
                    <li>Sicherstellung der lokalen technischen Infrastruktur in den Räumlichkeiten des Workation-Ziels</li>
                    <li>Erfüllung relevanter Arbeitsschutzkriterien (siehe z.B. Erfahrungen mit Home-Office)</li>
                    <li>Klares Konzept zur Arbeitszeiterfassung</li>
                  </ol>
                  <li>Workation- und Coworking-Konzepte lassen sich zu <b>Coworkation</b> verbinden:</li>
                  <ol className={classes.sourceList}>
                    <li>Dies beinhaltet das Arbeiten an abwechslungsreichen, mitunter ländlich geprägten Orten , die wiederum über Coworking-Spaces verfügen</li>
                    <li>Es eröffnen sich entsprechend Gestaltungspotenziale im Bereich der agilen Projektarbeit sowie neue Alternativen zum klassischen Home-Office</li>
                    <li>Coworkation-Spaces mit Unterkunft ermöglichen darüber hinaus sogar ein temporäres Coliving womit z.B. sich vielfältige Potenziale im Bereich des Teambuildings ergeben</li>
                  </ol>
                </ol>
                <ol className={classes.sourceList}>
                  <b>Chancen</b>
                  <ol className={classes.sourceList}>
                    <li>Inspiration und Erholung in direkter Arbeitsplatzumgebung</li>
                    <li>Stärkung des Gemeinschaftssinns in Arbeitsteams & Netzwerkaufbau</li>
                    <li>Steigerung der Arbeitszufriedenheit und der intrinsischen Arbeitsmotivation</li>
                  </ol>
                  <br />
                  <b>Herausforderungen</b>
                  <ol className={classes.sourceList}>
                    <li>Entgrenzung der Arbeitszeit</li>
                    <li>Unklare Trennung von Arbeit und Freizeit</li>
                    <li>Fehlende Geschäftsmodelle und wissenschaftliche Evaluation</li>
                    <li>Parallele Herausforderungen zu Coworking bei Coworkation</li>
                  </ol>
                </ol>
              </Typography>
              <br />
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceList}>
                  <li><b>Vision:</b></li>
                  <b>Neben klassischen Urlaubszeiten werden von Unternehmen zusätzlich „Workationtage“ angeboten. Coworkation-Spaces stellen dann eine weitere Alternative zum Home-Office dar und eröffnen sowohl in städtischen als auch ländlichen Gegenden neue Potenziale zu gewerblichen Raumnutzung.</b>
                </ol>
              </Typography>
              <Typography variant="h5" align="left" className={classes.textHeader}>
                Weiterführende Literatur<br />
              </Typography>
              <Typography className={classes.textJustify} variant="subtitle1">
                <ol className={classes.sourceLitList}>
                  <p>&#10146; <b>Werther, S. (Hrsg.). (2021).</b> Coworking als Revolution der Arbeitswelt: Von Corporate Coworking bis zu Workation. Springer. </p>
                  <p>&#10146; <b>Reuschl, A. J. & Bouncken, R. B. (2017).</b> Coworking-Spaces als neue Organisationsform in der Sharing Economy. In M. Bruhn & K. Hadwich (Hrsg.), Dienstleistungen 4.0 (S. 185–208). Springer Fachmedien.</p>
                </ol>
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => { setModalOpen(false) }}
                style={{ marginTop: "20px", marginLeft: "35%", marginRight: "35%" }}
              >
                zurück
              </Button>
            </div>
          </div>
        )
      default: return (<></>)
    }
  }


  // Handle modals, which show further information about the strategy
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };


  return (
    <svg
      id="main"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 777.10011 548.8104"
    >
      <defs>
        <style>{".cls-1{fill:none;}"}</style>
      </defs>
      <g id="clickLayer">
        <image
          width={3227}
          height={2279}
          transform="scale(.24081)"
          xlinkHref="/workdesigns/2022-08-04_Mosaik_1_jg_small_new.png"
          Opacity="1"
        />
      </g>
      <g id="clickLayer">
        <g id="VirtuellesJobShadowing">
          <path id="svgClickElementworkdesigns" className="VirtuellesJobShadowing"
            onClick={() => {
              setModalContent(41);
              handleModalOpen();
            }}
            d="M417.3217,159.57365c-2.34558,2.75498-11.72253,8.41022-13.98339,8.49469-.32903-2.2113,2.86989-12.84329,4.83138-16.05788,.15158,.00757,.37179-.04729,.44473,.03245,1.78594,1.95222,1.89627,2.04524,3.57104-.04744,4.90538-6.12955,9.71999-12.33188,14.54402-18.52598,.42112-.54072,.65052-1.23065,1.15742-2.21856-1.21644-.20174-2.10693-.46766-3.00024-.47753-4.32792-.0479-8.66746-.21807-12.98175,.02695-3.77774,.21456-6.42307-1.30753-8.64887-4.15268-3.11033-3.97575-6.32311-7.87167-9.5095-11.78755-4.95638-6.09117-9.92754-12.17034-14.89212-18.25489-.25333-.31046-.51204-.61704-.75362-.93648-1.71573-2.26876-1.76777-2.84315,.0086-5.04183,4.58251-5.67191,9.22052-11.29898,13.82893-16.95002,3.89948-4.78174,7.86918-9.50955,11.6482-14.38487,1.87956-2.42481,4.0481-3.5385,7.16322-3.52293,17.63389,.08815,35.26863,.07632,52.90263-.0066,3.05962-.01439,5.28122,.94322,7.2272,3.38227,8.19571,10.27229,16.53958,20.42646,24.82935,30.62378,.25277,.31093,.51547,.61423,.75814,.93282,1.59399,2.09272,1.62453,2.81107-.02666,4.86086-3.31759,4.11862-6.67761,8.20307-10.01925,12.30231-5.31622,6.52145-10.68786,12.99895-15.9168,19.58963-1.80265,2.27211-3.9122,3.3409-6.79941,3.31281-7.69428-.07489-15.39232,.0887-23.08357-.08202-3.55142-.07884-6.09762,1.15106-8.20887,3.97089-5.13874,6.86325-10.4253,13.61583-15.65214,20.41316-.09773,.12706-.1941,.25517-.2888,.38449-1.69506,2.31475-1.68179,2.39095,.85014,4.12013Z"
          />
        </g>
        <g id="ARVRTechnolgie">
          <path id="svgClickElementworkdesigns" className="ARVRTechnolgie"
            onClick={() => {
              setModalContent(42);
              handleModalOpen();
            }}
            d="M88.98176,154.23557l1.81534-2.54157c.40562,.52762,.85426,.88236,.99857,1.33422,1.51118,4.73193,2.9831,9.47652,4.43286,14.22768,.12587,.4125,.01668,.89671,.01668,1.3651-2.16024-.25034-13.08586-7.12968-15.02649-9.44338,.43257-.22213,.82613-.44084,1.2333-.63033,1.50443-.7001,1.68464-1.11658,.75692-2.53762-.82995-1.27129-1.81689-2.44042-2.73881-3.65139-4.75709-6.2487-9.5625-12.46149-14.24687-18.76419-1.54302-2.07609-3.41758-2.89933-5.95676-2.87513-7.93467,.07565-15.87212-.06627-23.80516,.06983-3.25337,.05579-5.5664-1.02531-7.61802-3.60587-7.97841-10.03531-16.12746-19.93509-24.22207-29.87794-.80903-.99375-1.66469-1.95093-2.52586-2.90048-.79737-.87919-.67839-1.60737,.06899-2.5173,9.00089-10.9585,18.014-21.90779,26.90159-32.95781,1.77512-2.20703,3.70016-3.17228,6.54056-3.15846,17.71395,.08617,35.42877,.07805,53.14275-.00825,2.99319-.01458,5.18994,.9862,7.05573,3.29466,8.1594,10.09525,16.40006,20.1248,24.59849,30.18858,3.15424,3.8719,3.11549,3.86545,.02061,7.6487-8.21943,10.04759-16.41684,20.11321-24.62298,30.17168-.20266,.24838-.43472,.47847-.60301,.7483-1.43013,2.29296-3.55337,3.00014-6.1593,2.95091-4.48706-.0848-8.97698-.0231-13.46566-.0116-.72,.00185-1.4533-.0132-2.15682,.1121-1.27695,.22745-1.63308,.91997-1.08988,2.06028,.23851,.50069,.58695,.95585,.92593,1.40087,4.51582,5.92869,9.02833,11.86,13.57366,17.76605,.55275,.71823,1.2917,1.29325,2.15571,2.14232Z"
          />
        </g>
        <g id="WorkLifeIntegration">
          <path id="svgClickElementworkdesigns" className="WorkLifeIntegration"
            onClick={() => {
              setModalContent(43);
              handleModalOpen();
            }}
            d="M249.75377,102.75969c-1.58212-1.76695-5.747-12.89664-6.0213-15.89261,.13713-.06548,.29475-.21231,.40866-.18392,2.40303,.59911,2.7762,.42552,3.00763-2.06845,.17678-1.90517,.19877-3.84773,.04848-5.75466-.21275-2.69964-.96205-3.32759-3.63775-3.40356-1.84265-.05232-3.68778-.02131-5.53179-.02225-4.89074-.00248-9.7851-.11595-14.67084,.0399-2.93198,.09352-5.04303-.98669-6.82687-3.22948-4.1908-5.26906-8.47207-10.46629-12.72527-15.68565-4.10115-5.03281-8.21314-10.05679-12.31988-15.08506-.25348-.31037-.51372-.61606-.75189-.93792-1.68679-2.27944-1.71793-3.11617,.06804-5.31294,8.89884-10.94576,17.8332-21.86268,26.72086-32.81748,1.05586-1.30142,2.30936-1.88442,3.94569-1.87458,3.3672,.02024,6.73463-.00813,10.10198-.00854,14.75235-.00179,29.50472-.00103,44.25709,.00003,.88189,.00006,1.76454,.03656,2.64548,.01093,1.56538-.04554,2.71267,.58751,3.70227,1.82415,6.96057,8.69846,13.96063,17.36543,20.96366,26.02986,1.91436,2.36854,3.9003,4.67917,5.81977,7.04366,1.77358,2.18475,1.78938,2.79593-.01178,5.0052-5.97626,7.33037-11.98851,14.6314-17.97223,21.95568-2.53557,3.10364-5.12338,6.17072-7.52276,9.37756-1.99696,2.66903-4.41592,3.84331-7.8091,3.72304-5.84549-.20717-11.70368-.05945-17.55651-.05705-.80157,.00033-1.60579-.01608-2.40425,.03989-2.31011,.16194-3.01507,.8265-3.09104,3.09616-.06698,2.00123-.04966,4.00859,.01923,6.01019,.08131,2.36315,.31905,2.52772,2.70421,2.2969,.35261,2.31805-3.4147,13.20501-5.5598,15.88101Z"
          />
        </g>
        <g id="Arbeitsinhalt">
          <path id="svgClickElementworkdesigns" className="Arbeitsinhalt"
            onClick={() => {
              setModalContent(22);
              handleModalOpen();
            }}
            d="M343.84878,236.16997c8.73932,.00001,17.4796,.07333,26.21742-.04043,2.98985-.0389,5.07834,.96951,6.9714,3.34182,8.39871,10.52468,16.96032,20.91953,25.47079,31.35495,1.89383,2.32217,1.99963,3.31301,.07852,5.71024-4.6613,5.81651-9.39935,11.57152-14.10708,17.35087-3.84721,4.72301-7.78029,9.37964-11.50813,14.19504-1.95917,2.53069-4.35432,3.53744-7.46852,3.52062-10.98394-.05927-21.96844-.02578-32.95271-.02583-6.25381-.00003-12.50895-.07187-18.76091,.03632-3.15077,.05449-5.40901-1.07219-7.40595-3.57349-8.30102-10.39752-16.7656-20.66449-25.16983-30.97966-.20247-.24854-.40891-.49444-.60098-.7509-1.65319-2.20788-1.71934-2.95461-.00255-5.0883,3.9692-4.93306,8.00892-9.80937,12.018-14.71041,4.61819-5.64559,9.33513-11.21475,13.80385-16.97629,1.92211-2.47816,4.12832-3.44799,7.19928-3.40623,8.7377,.11882,17.47808,.0417,26.2174,.0417Z"
          />
        </g>
        <g id="Arbeitsumgebung">
          <path id="svgClickElementworkdesigns" className="Arbeitsumgebung"
            onClick={() => {
              setModalContent(26);
              handleModalOpen();
            }}
            d="M156.08352,235.68795c8.89968,0,17.8003,.07262,26.6985-.04229,2.82954-.03654,4.77776,.93876,6.55834,3.15249,8.18799,10.17989,16.52646,20.23872,24.79812,30.35147,3.14153,3.84073,3.1421,3.8668,.07936,7.62854-8.50203,10.44229-17.0501,20.84732-25.50003,31.33148-1.45415,1.80418-3.0997,2.53275-5.36742,2.52734-17.79918-.04248-35.59859-.04233-53.39775,.00446-3.12081,.00819-5.45776-1.07283-7.45202-3.55873-8.125-10.12802-16.37949-20.15235-24.59789-30.20536-2.94981-3.60831-2.97827-3.81874,.03036-7.51284,8.40232-10.31664,16.85266-20.59447,25.19622-30.9583,1.56027-1.9381,3.32697-2.75962,5.77433-2.7408,9.0595,.06965,18.11985,.02773,27.17989,.02773v-.0052Z"
          />
        </g>
        <g id="Ergonomie">
          <path id="svgClickElementworkdesigns" className="Ergonomie"
            onClick={() => {
              setModalContent(24);
              handleModalOpen();
            }}
            d="M250.06533,352.08591c8.659,0,17.3193,.08681,25.97628-.04627,3.10293-.04768,5.25447,1.05352,7.18004,3.46534,8.30119,10.39749,16.73908,20.68586,25.12719,31.01398,.10107,.12445,.20241,.24872,.30149,.37472,1.90531,2.42292,1.93336,3.08569,.05767,5.46017-2.48398,3.14455-4.9824,6.27827-7.51345,9.38501-6.12564,7.51889-12.3317,14.9733-18.37591,22.5568-1.71867,2.15641-3.7817,3.17265-6.4034,3.14775-2.72148-.02589-5.44114-.30984-8.16209-.31794-12.34697-.03684-24.69416-.0352-37.04115,.00082-2.32016,.00678-4.63927,.24249-6.95964,.35634-3.34949,.16428-5.8831-1.0111-8.06548-3.7681-8.15773-10.30595-16.52826-20.4436-24.81895-30.64437-.05058-.06221-.10172-.12395-.15168-.18662-2.41095-3.02475-2.39519-3.54954,.12123-6.58931,2.9643-3.58083,5.93093-7.1599,8.87202-10.75976,5.57826-6.8277,11.16847-13.646,16.68901-20.52017,1.63965-2.04166,3.60394-2.96982,6.22793-2.95012,8.9792,.06749,17.95919,.02589,26.93889,.02589v-.00417Z"
          />
        </g>
        <g id="Arbeitsplatz">
          <path id="svgClickElementworkdesigns" className="Arbeitsplatz"
            onClick={() => {
              setModalContent(25);
              handleModalOpen();
            }}
            d="M155.84071,313.60994c9.06097-.00053,18.12262,.06106,27.18249-.03884,2.64706-.02915,4.56087,.87053,6.21225,2.91348,8.31458,10.28617,16.70657,20.50977,25.05908,30.76538,2.94711,3.61862,2.89588,3.71344-.02267,7.2773-8.17695,9.98502-16.38139,19.94876-24.44158,30.02748-2.19566,2.74553-4.71211,4.20562-8.23075,3.99172-2.7178-.16519-5.44217-.22999-8.16446-.31116-1.60203-.04776-3.20593-.06688-4.80861-.05352-12.90474,.10757-25.80949,.21721-38.71403,.3475-3.00429,.03033-5.26985-1.04195-7.22276-3.48806-8.30184-10.39846-16.77055-20.66378-25.17691-30.97887-.3544-.4349-.72031-.86372-1.03692-1.32548-1.10271-1.60843-1.12352-2.35284,.04968-3.90864,1.20621-1.59959,2.47871-3.15013,3.74681-4.70205,7.55742-9.24891,15.16573-18.45687,22.64855-27.76565,1.55699-1.93691,3.27087-2.80357,5.73718-2.78181,9.06026,.07988,18.12167,.03171,27.18263,.03121Z"
          />
        </g>
        <g id="Arbeitsorganisation">
          <path id="svgClickElementworkdesigns" className="Arbeitsorganisation"
            onClick={() => {
              setModalContent(21);
              handleModalOpen();
            }}
            d="M249.57208,196.72902c8.65663-.00141,17.31457,.08495,25.96926-.04436,3.41294-.05099,5.87286,1.06508,8.04128,3.78632,8.13895,10.21383,16.46734,20.27684,24.72398,30.39693,.30396,.37253,.61857,.73821,.8973,1.12913,1.21183,1.69964,1.26269,2.67619-.02088,4.3524-1.55816,2.0348-3.22192,3.98896-4.84518,5.97378-7.05116,8.62168-14.16237,17.19532-21.11888,25.8926-1.92005,2.40052-4.05168,3.51178-7.16283,3.49452-17.39289-.09662-34.78693-.10334-52.17968,.00294-3.37227,.02058-5.72871-1.1703-7.81275-3.76954-8.22012-10.25232-16.57508-20.3966-24.87938-30.58147-.20252-.24839-.40358-.49837-.59546-.75498-1.55265-2.07628-1.57864-2.87568,.03625-4.88778,2.05613-2.5619,4.1828-5.06706,6.25549-7.61582,6.52183-8.01975,13.1093-15.98814,19.50035-24.11091,1.88583-2.39684,4.00186-3.34232,6.98133-3.30319,8.73527,.11477,17.47303,.04085,26.2098,.03943Z"
          />
        </g>
        <g id="Arbeitszeit">
          <path id="svgClickElementworkdesigns" className="Arbeitszeit"
            onClick={() => {
              setModalContent(23);
              handleModalOpen();
            }}
            d="M343.32373,313.60721c8.8958,.00009,17.79287,.08772,26.68671-.04486,3.35926-.05008,5.68745,1.15926,7.77838,3.79285,8.06971,10.16384,16.31866,20.18543,24.50002,30.26067,.30293,.37304,.61564,.7401,.89316,1.13158,1.3132,1.8526,1.32932,2.42101-.14897,4.24208-4.03944,4.97607-8.12935,9.91122-12.17069,14.88576-4.44582,5.47247-8.9151,10.92739-13.26492,16.47555-2.26445,2.88832-5.12165,4.25846-8.73033,4.18175-5.52468-.11746-11.04943-.37495-16.57335-.35481-10.7341,.0391-21.46768,.23084-32.20149,.35563-.88136,.01025-1.76319-.01941-2.64457-.01036-2.89973,.02971-5.12532-.99486-7.02714-3.37489-8.50208-10.63978-17.16176-21.15367-25.76338-31.71395-1.76137-2.16246-1.81783-2.88762-.07629-5.03185,7.87963-9.70155,15.78819-19.37963,23.68658-29.06591,.91137-1.11769,1.90693-2.17761,2.71763-3.36391,1.21183-1.77333,2.83777-2.39295,4.93013-2.37935,7.69331,.04991,15.38718,.01612,23.08084,.014,1.44255-.00041,2.88513-.00006,4.32768,.00003Z"
          />
        </g>
        <g id="Multispaceoffice">
          <path id="svgClickElementworkdesigns" className="Multispaceoffice"
            onClick={() => {
              setModalContent(311);
              handleModalOpen();
            }}
            d="M62.19255,197.69005c8.73549,0,17.47265,.09799,26.20562-.05051,3.2783-.05573,5.55734,1.08646,7.60268,3.63426,8.22489,10.24554,16.60546,20.36614,24.92704,30.53413,.3551,.43389,.71622,.86516,1.03922,1.32258,1.13749,1.61097,1.19246,2.09215,.00366,3.64018-1.65846,2.15958-3.40858,4.24917-5.13232,6.3582-7.14888,8.74673-14.36323,17.44105-21.41297,26.26687-1.82304,2.28233-3.82834,3.28585-6.74877,3.27132-17.31026-.08605-34.62162-.10686-51.93147,.01327-3.48739,.02419-5.91552-1.2791-8.01312-3.91081-6.64166-8.33282-13.37137-16.5955-20.08067-24.87429-2.06784-2.55155-4.18072-5.06661-6.27042-7.60047-1.07684-1.30573-1.09418-1.6692-.24422-2.68432,3.80287-4.54186,7.62039-9.07177,11.38926-13.64173,5.40314-6.55165,10.80346-13.10632,16.12685-19.72253,1.43254-1.78045,3.09937-2.58537,5.3716-2.57197,9.05574,.05345,18.11198,.02203,27.16804,.02203v-.00622Z"
          />
        </g>
        <g id="Arbeitsraumgestaltung">
          <path id="svgClickElementworkdesigns" className="Arbeitsraumgestaltung"
            onClick={() => {
              setModalContent(310);
              handleModalOpen();
            }}
            d="M62.48617,274.64814c8.65948,0,17.32078,.10219,25.97751-.05205,3.30061-.0588,5.54394,1.15712,7.5773,3.6958,8.11731,10.13457,16.39304,20.14225,24.59848,30.20639,2.77437,3.40281,2.77805,3.43146-.0007,6.85488-8.23412,10.14443-16.48306,20.27685-24.73339,30.40816-.6068,.74512-1.26216,1.45163-1.9109,2.16155-.9095,.99527-2.05247,1.52508-3.39506,1.58582-1.04067,.04703-2.08351,.06335-3.12539,.06376-16.59734,.00646-33.19467,.00816-49.792,.0108-.32072,.00006-.64347-.02581-.96184,.00106-3.44894,.29117-5.95464-1.20453-8.02719-3.89096-3.1818-4.12427-6.51111-8.13545-9.80446-12.17291-5.52221-6.76989-11.06415-13.52375-16.60085-20.28184-1.08587-1.32543-1.05858-1.68943-.05452-2.87176,4.61417-5.43334,9.24447-10.85388,13.79069-16.34382,4.49876-5.43261,8.96225-10.89695,13.31987-16.44261,1.64305-2.09104,3.58688-2.97179,6.20203-2.95308,8.97974,.06429,17.96022,.02472,26.94042,.02472v-.0039Z"
          />
        </g>
        <g id="Selbstorganisation">
          <path id="svgClickElementworkdesigns" className="Selbstorganisation"
            onClick={() => {
              setModalContent(33);
              handleModalOpen();
            }}
            d="M437.18817,197.69196c8.73529-.00029,17.47203,.08928,26.20518-.04732,3.19426-.04997,5.44719,1.12324,7.41892,3.55622,8.52416,10.5183,17.14423,20.95888,25.72797,31.42893,1.40505,1.71382,1.47917,2.52731-.0377,4.47005-2.85932,3.66202-5.81346,7.25028-8.74331,10.85693-5.80923,7.15124-11.68958,14.246-17.40869,21.46847-1.81957,2.29786-3.93839,3.23521-6.8163,3.22534-17.22994-.05904-34.46045-.06966-51.69024,.01004-3.49889,.01618-6.01866-1.19217-8.22552-3.97322-7.71851-9.72676-15.63528-19.2962-23.46916-28.93156-.75776-.93199-1.5029-1.87916-2.18407-2.86738-1.17821-1.70929-1.20643-2.41706,.08319-4.11315,1.69629-2.231,3.49475-4.3849,5.26557-6.55868,6.88158-8.44759,13.83016-16.84179,20.61545-25.36592,1.83616-2.30667,3.9319-3.22495,6.81277-3.19346,8.81437,.09635,17.63051,.03501,26.44594,.03472Z"
          />
        </g>
        <g id="Arbeitsgestaltung">
          <path id="svgClickElementworkdesigns" className="Arbeitsgestaltung"
            onClick={() => {
              setModalContent(1);
              handleModalOpen();
            }}
            d="M249.99006,274.64864c8.73864,.00079,17.47822,.07184,26.21546-.03852,2.97499-.03758,5.09305,.90893,6.98958,3.29883,7.37288,9.29081,14.92744,18.43756,22.42272,27.63113,1.16435,1.42818,2.41823,2.78519,3.54772,4.23952,1.34676,1.73408,1.3689,2.54563-.00449,4.28042-3.18386,4.02155-6.41746,8.00407-9.65953,11.97909-5.47077,6.70762-11.03539,13.34045-16.40911,20.12461-1.92367,2.42853-4.13543,3.46029-7.21102,3.44626-17.15624-.07815-34.31299-.03637-51.46957-.04051-.72079-.00018-1.444-.02023-2.16192-.08062-2.17967-.18334-3.91562-1.08114-5.33018-2.84343-6.22098-7.75019-12.52363-15.43483-18.78518-23.15252-2.27212-2.80051-4.51393-5.62563-6.76487-8.44329-.34994-.43804-.69955-.87996-1.00628-1.34824-1.0612-1.62006-1.09126-2.12136,.12363-3.64882,3.09315-3.88894,6.22326-7.74881,9.36416-11.59944,5.57234-6.83157,11.22829-13.59635,16.71101-20.49885,1.86009-2.34178,3.93983-3.40815,6.9723-3.35807,8.8164,.14567,17.63677,.05167,26.45557,.05246Z"
          />
        </g>
        <g id="Eigenverantwortung">
          <path id="svgClickElementworkdesigns" className="Eigenverantwortung"
            onClick={() => {
              setModalContent(34);
              handleModalOpen();
            }}
            d="M437.43924,349.57686c-8.49653,.00161-16.99432-.08352-25.489,.04192-3.59171,.05302-6.20877-1.18351-8.46317-4.02859-7.66356-9.67163-15.5032-19.20375-23.26942-28.79418-.75618-.93383-1.50616-1.87676-2.19444-2.86061-1.22161-1.74623-1.23946-2.397,.06904-4.09282,1.90898-2.474,3.87893-4.90171,5.85193-7.32554,6.77854-8.32759,13.6175-16.60674,20.32145-24.99384,1.60664-2.01001,3.42706-2.89945,5.96002-2.8934,18.03495,.04301,36.0702,.05384,54.10504-.01292,2.59938-.0096,4.36175,1.05496,5.92857,2.98714,7.21722,8.89991,14.49117,17.75385,21.75566,26.61543,1.37119,1.67266,2.79674,3.30076,4.1884,4.95681,1.94507,2.31459,1.97743,2.99416,.02255,5.40228-4.39372,5.4125-8.83613,10.78548-13.234,16.19466-4.29667,5.28476-8.60603,10.56031-12.82045,15.91024-1.6484,2.09253-3.73642,2.89325-6.28077,2.89052-6.09184-.00658-12.18367,.00018-18.27551,.0012-2.7253,.00044-5.4506,.00006-8.17589,.0017Z"
          />
        </g>

        <g id="Demokratisierung">
          <path id="svgClickElementworkdesigns" className="Demokratisierung"
            onClick={() => {
              setModalContent(32);
              handleModalOpen();
            }}
            d="M343.56814,158.73146c8.81937,.00056,17.63985,.0791,26.45745-.04471,2.84561-.03994,4.86656,.9892,6.61715,3.13591,8.35769,10.24884,16.7762,20.44815,25.17239,30.66562,.25445,.30963,.50475,.62283,.75139,.93875,1.70819,2.18803,1.76471,2.79598,.00646,5.05968-2.45793,3.16449-4.99042,6.27176-7.5215,9.37872-6.3279,7.7677-12.68972,15.5079-18.99754,23.29184-1.31006,1.61662-2.9021,2.46587-4.96411,2.47989-.88186,.00599-1.76363,.02877-2.64545,.02887-16.75682,.00179-33.51363,.00132-50.27045,.00122-.32072,0-.64441-.03005-.96167,.00273-2.91476,.30103-5.029-.89315-6.79645-3.20325-2.96997-3.88175-6.12255-7.62385-9.19864-11.42444-5.34529-6.60421-10.69091-13.20813-16.03608-19.81245-.30258-.37384-.61822-.73845-.90042-1.12719-1.2735-1.75431-1.32615-2.34531,.01744-4.05761,2.32526-2.96326,4.72193-5.87073,7.10178-8.79092,6.27869-7.70426,12.64714-15.33761,18.79868-23.14194,1.93071-2.44947,4.05004-3.48533,7.15282-3.43286,8.7367,.14774,17.47755,.0516,26.21675,.05215Z"
          />
        </g>
        <g id="VirtuelleTeamarbeit">
          <path id="svgClickElementworkdesigns" className="VirtuelleTeamarbeit"
            onClick={() => {
              setModalContent(312);
              handleModalOpen();
            }}
            d="M155.97493,233.66705c-8.41727-.00084-16.83456,.00602-25.2518-.00906-1.11911-.002-2.24755-.04455-3.35488-.19326-1.77387-.23819-3.00987-1.31784-4.10893-2.68504-5.22197-6.49608-10.49236-12.95324-15.73832-19.43006-3.43008-4.23487-6.86997-8.46212-10.26268-12.72679-1.95198-2.45366-1.93645-2.73676,.02497-5.15649,8.62946-10.64581,17.28653-21.26931,25.90159-31.92673,1.52038-1.88079,3.34851-2.8219,5.79923-2.81728,17.95673,.03394,35.91355,.02519,53.87034,.01017,2.47445-.00207,4.46203,.78849,6.0782,2.77748,8.59127,10.57311,17.24542,21.09514,25.87682,31.63565,.35542,.43403,.69793,.87861,1.0525,1.31334,.82549,1.01218,.8448,2.04172,.04625,3.06899-1.13117,1.45514-2.25605,2.91601-3.42117,4.34376-7.60024,9.31349-15.26909,18.57191-22.78051,27.95634-2.13343,2.6654-4.52764,3.9564-7.99975,3.89196-8.57465-.15912-17.15424-.05218-25.73185-.053Z"
          />
        </g>
        <g id="KollaborativesArbeiten">
          <path id="svgClickElementworkdesigns" className="KollaborativesArbeiten"
            onClick={() => {
              setModalContent(31);
              handleModalOpen();
            }}
            d="M249.77103,119.77075c8.57605,0,17.15398,.10318,25.72724-.05144,3.36886-.06076,5.73552,1.13708,7.82727,3.7289,8.35296,10.34989,16.83284,20.59748,25.27419,30.87598,1.98427,2.41609,2.04944,3.19421,.0604,5.63877-6.72583,8.26609-13.48583,16.50439-20.22637,24.7585-1.87527,2.29632-3.76341,4.58353-5.58156,6.92466-1.64487,2.11806-3.70518,3.09605-6.42539,3.08835-17.63289-.04997-35.26601-.04169-52.89898-.01072-2.94445,.00517-5.13303-1.07617-7.01219-3.41652-8.02632-9.99616-16.18197-19.88853-24.28637-29.82207-.50634-.62063-.98974-1.26094-1.46361-1.90691-1.68412-2.29582-1.71315-2.63896,.03022-4.80442,3.06499-3.80703,6.17284-7.57958,9.2685-11.36185,5.58233-6.82049,11.24388-13.57823,16.71162-20.48927,1.82658-2.30873,3.87197-3.22857,6.78665-3.18357,8.73428,.13487,17.472,.04854,26.20837,.04854v-.01695Z"
          />
        </g>
        {/*
        <g id="Gestaltungsbereiche">
            <path id="svgClickElementworkdesigns" className="Gestaltungsbereiche"
              d="M469.1469,547.8186c-9.05429,.00082-18.10939-.06647-27.16236,.04227-2.66736,.032-4.48173-.92992-6.13539-2.9877-7.37558-9.17822-14.87764-18.25481-22.32226-27.37766-1.26592-1.5513-2.47828-3.14629-3.70882-4.72624-1.62896-2.09136-1.65195-2.67575,.07403-4.82001,2.91312-3.61894,5.87148-7.20153,8.81423-10.79657,5.58103-6.81815,11.23619-13.57762,16.70807-20.48221,1.91706-2.41902,4.06833-3.44905,7.17543-3.42812,16.42514,.11062,32.85148,.0534,49.27739,.04806,1.52204-.0005,3.04955,.0276,4.56477-.08655,2.38067-.17929,4.12683,.75228,5.59895,2.59927,4.0937,5.13633,8.25988,10.21518,12.41895,15.29911,4.31112,5.26958,8.64761,10.5185,12.97294,15.77646,.20356,.24742,.41228,.49085,.60997,.74286,1.9597,2.49913,1.94214,3.23113-.0303,5.64295-8.72232,10.66505-17.472,21.30815-26.11979,32.03332-1.45527,1.80489-3.05325,2.56724-5.33278,2.54945-9.13386-.07134-18.26861-.02953-27.40303-.02871Z"
            />
              </g>*/}
        {/*
        <g id="Gestaltungsmethoden">
            <path id="svgClickElementworkdesigns" className="Gestaltungsmethoden"
              d="M715.94428,547.82066c-9.05731-.00018-18.11544-.07081-27.17128,.04462-2.58147,.03294-4.22702-.85653-5.84162-2.90385-7.39256-9.37368-14.98168-18.59248-22.49845-27.86822-1.21071-1.49405-2.45121-2.96609-3.60877-4.50042-1.44763-1.91897-1.44334-2.52391,.09297-4.44588,3.35206-4.19364,6.73278-8.36473,10.12807-12.52361,5.21932-6.39303,10.54241-12.70369,15.65108-19.18353,1.93515-2.45454,4.08789-3.58488,7.21813-3.24771,2.29951,.24769,4.64341,.09832,6.96753,.09911,14.82834,.00496,29.65661,.00214,44.48495,.00335,.96176,.00009,1.92405,.03382,2.88522,.01371,1.99123-.0416,3.48456,.75821,4.74378,2.33344,5.25332,6.57193,10.57048,13.09302,15.88148,19.61866,3.33814,4.10146,6.69208,8.19023,10.06811,12.26057,1.86943,2.254,1.98031,3.37598,.10313,5.71106-3.31312,4.12148-6.7255,8.16293-10.06459,12.26362-5.21139,6.40002-10.46336,12.76921-15.5557,19.263-1.74839,2.22957-3.7052,3.16055-6.5536,3.11511-8.97479-.14332-17.95335-.05284-26.93043-.05302Z"
            />
        </g>
        */}
        <g id="MobileDevices">
          <path id="svgClickElementworkdesigns" className="MobileDevices"
            onClick={() => {
              setModalContent(39);
              handleModalOpen();
            }}
            d="M62.33274,352.57031c8.89657-.0015,17.79421,.07609,26.68908-.0465,2.83998-.03913,4.8207,.95937,6.58432,3.14185,8.46024,10.46939,17.02062,20.85789,25.54517,31.27538,.15223,.18604,.30482,.37199,.45093,.56281,1.53263,2.00182,1.56116,2.46564,.01689,4.3982-2.50089,3.12982-5.03332,6.2345-7.56561,9.33907-6.22934,7.63696-12.52266,15.22281-18.66527,22.92879-1.81446,2.27622-3.92468,3.40865-6.79302,3.32134-4.32235-.13158-8.64596-.27796-12.96962-.29912-9.45645-.0463-18.91339-.04659-28.36987-.00549-3.68239,.01597-7.36526,.16376-11.0457,.30778-2.96625,.11611-5.16234-1.07116-7.02331-3.3938-8.91794-11.13028-17.91104-22.20058-26.91901-33.25835-.8341-1.02387-.7915-1.74755,.01184-2.71277,9.22507-11.08395,18.4439-22.17325,27.61487-33.30194,1.41835-1.7211,3.13021-2.28506,5.2678-2.27461,9.05665,.04439,18.11363,.01882,27.1705,.01735Z"
          />
        </g>
        <g id="Arbeitsmittel">
          <path id="svgClickElementworkdesigns" className="Arbeitsmittel"
            onClick={() => {
              setModalContent(38);
              handleModalOpen();
            }}
            d="M156.33629,390.56452c8.65853,.00047,17.31848,.0898,25.97487-.04618,3.19191-.05011,5.36915,1.09644,7.36824,3.58201,8.28722,10.3041,16.75072,20.4665,25.1475,30.68253,.35603,.43326,.68344,.89009,1.03946,1.32337,.89244,1.08605,.90705,2.16842,.03026,3.27264-1.14627,1.44359-2.26042,2.91313-3.42574,4.34104-7.6013,9.31397-15.28376,18.56309-22.7781,27.96217-2.29284,2.87552-4.86082,4.23682-8.51622,3.58389-1.24918-.22309-2.55743-.15242-3.83874-.15327-13.94984-.0091-27.8997-.01588-41.84951,.00705-1.75954,.00288-3.52087,.15145-5.27772,.28171-2.91962,.2166-5.26667-.66847-7.17126-3.03446-8.54375-10.61353-17.16575-21.16409-25.75849-31.73819-.20215-.24877-.39905-.50233-.58706-.76185-1.32047-1.82286-1.38005-2.2779-.00244-4.03341,2.32522-2.96298,4.72664-5.86636,7.10846-8.78466,6.13179-7.51313,12.3449-14.96168,18.36678-22.56174,2.17637-2.74676,4.62003-4.05771,8.19536-3.98235,8.65469,.18249,17.31582,.05927,25.97436,.05971Z"
          />
        </g>

        <g id="FlexibleArbeitszeiten">
          <path id="svgClickElementworkdesigns" className="FlexibleArbeitszeiten"
            onClick={() => {
              setModalContent(35);
              handleModalOpen();
            }}
            d="M437.35611,352.56737c8.73497,0,17.47223,.11485,26.20365-.06089,3.15265-.06344,5.33563,1.1211,7.20864,3.45548,4.11103,5.12368,8.27312,10.2067,12.4368,15.28792,4.31582,5.26688,8.67172,10.50092,12.9826,15.77179,1.9032,2.32698,1.94443,2.83524,.03829,5.20327-6.63095,8.23758-13.31255,16.43442-19.97724,24.64479-2.22157,2.73687-4.48387,5.44153-6.66322,8.21148-1.31531,1.67175-3.01727,2.26636-5.05743,2.22655-4.08442-.07968-8.16926-.17996-12.25412-.19047-10.41767-.02677-20.83557-.02997-31.25321,.00543-2.72019,.00925-5.43917,.22241-8.15998,.28618-1.27667,.02992-2.56611,.01104-3.83247-.13528-1.73978-.20104-3.12848-1.05334-4.25448-2.44509-8.71738-10.77485-17.47144-21.51996-26.19658-32.28853-2.0001-2.46851-2.03108-3.08983,.02279-5.64128,5.92772-7.36373,11.90788-14.68528,17.87565-22.01672,2.62977-3.23072,5.34065-6.3977,7.89599-9.68587,1.45938-1.87793,3.20997-2.66961,5.57778-2.652,9.13507,.06799,18.27096,.0276,27.40653,.0276v-.00437Z"
          />
        </g>

        <g id="WorkLifeBalance">
          <path id="svgClickElementworkdesigns" className="WorkLifeBalance"
            onClick={() => {
              setModalContent(36);
              handleModalOpen();
            }}
            d="M343.35962,390.56514c8.81373-.0005,17.62866,.08276,26.44054-.04562,3.08587-.04495,5.26736,.99348,7.19857,3.4174,7.18718,9.02066,14.5245,17.92184,21.81645,26.85892,1.26554,1.55107,2.60188,3.04424,3.87396,4.59008,1.64596,2.00015,1.68088,2.77483,.02561,4.83524-4.01324,4.99568-8.06466,9.96077-12.11361,14.92765-4.7069,5.77391-9.48416,11.49176-14.11362,17.32688-1.78721,2.25256-4.02232,2.97625-6.72935,2.95637-16.02014-.11766-32.0404-.22006-48.06077-.29713-1.43674-.00693-2.87286,.25432-4.31221,.30173-2.7963,.09212-5.09243-.79975-6.92894-3.0925-8.36295-10.44059-16.80823-20.81524-25.22407-31.21349-.20156-.2491-.41008-.49276-.60641-.74585-1.76788-2.27898-1.74827-2.92872,.13126-5.22352,8.06968-9.85297,16.13842-19.70667,24.20555-29.56173,.81185-.99178,1.6701-1.95273,2.4019-3.0012,1.0769-1.5429,2.5457-2.0541,4.35223-2.0349,3.60516,.03831,7.21108,.00367,10.81668,.00261,5.60875-.00164,11.21748-.00044,16.82624-.00094Z"
          />
        </g>
        {/**
         *<g id="AspekteBeeinflussungsfaktoren">
            <path id="svgClickElementworkdesigns" className="AspekteBeeinflussungsfaktoren"
              d="M592.67089,547.82071c-9.05408-.00059-18.10898-.06793-27.16171,.04216-2.57301,.03124-4.31406-.84385-5.92173-2.85559-7.95078-9.94891-16.03136-19.79419-24.06219-29.67928-.60592-.74591-1.19779-1.50468-1.76589-2.2796-1.69242-2.30872-1.70498-2.8752,.0289-5.06326,3.03469-3.82931,6.07742-7.65258,9.15474-11.44766,5.49893-6.78166,11.0617-13.51189,16.51952-20.32629,1.63386-2.0399,3.51927-2.96803,6.18008-2.95496,16.10471,.07918,32.20995,.04207,48.31501,.03124,1.60103-.00106,3.21176,.00787,4.80099-.15463,2.80499-.28688,4.93877,.68124,6.71217,2.87943,5.02909,6.23386,10.14452,12.39811,15.2277,18.58834,3.45654,4.20926,6.91684,8.41552,10.37292,12.62513,.35561,.43326,.69824,.87731,1.03981,1.32184,1.01937,1.32695,1.00369,2.65232-.04117,3.95256-1.65553,2.06012-3.31718,4.11543-4.98581,6.16504-7.02955,8.63441-14.14355,17.20194-21.0413,25.94021-1.89051,2.39497-3.92227,3.32754-6.93194,3.27287-8.81076-.16011-17.6264-.05701-26.4401-.05754Z"
            />
        </g>
         */}

        <g id="FlexiblerArbeitsortMobilesArbeiten">
          <path id="svgClickElementworkdesigns" className="FlexiblerArbeitsortMobilesArbeiten"
            onClick={() => {
              setModalContent(37);
              handleModalOpen();
            }}
            d="M249.7449,429.52521c8.81722,0,17.63547,.07794,26.45105-.04236,3.08193-.04207,5.24081,1.00816,7.18553,3.43939,8.40861,10.51225,16.98381,20.89124,25.48612,31.32872,1.91007,2.34483,1.91277,2.63062,.04701,4.93068-8.73336,10.76639-17.50697,21.50032-26.19951,32.29945-1.63968,2.03705-3.65467,2.61081-6.11959,2.60323-13.30585-.04081-26.61188-.02495-39.91787-.01415-4.64868,.00376-9.29796,.01204-13.94573,.09101-2.23176,.03799-4.05087-.61785-5.48826-2.39626-8.61327-10.65692-17.25256-21.29276-25.88158-31.93697-.20172-.2488-.3876-.5107-.57654-.76966-1.6268-2.22931-1.66117-2.59748,.1312-4.81143,5.14301-6.35249,10.32433-12.67398,15.49054-19.00765,3.54542-4.34671,7.15245-8.64524,10.60709-13.0629,1.47175-1.88204,3.19304-2.68878,5.55773-2.67213,9.05721,.06391,18.11513,.02583,27.17278,.02583v-.00479Z"
          />
        </g>
        <Modal key={"contentModal"}
          open={modalOpen}
          onClose={handleModalClose}
        >
          {getContent(modalContent)}
        </Modal>
      </g>
    </svg >
  )
};
