import React from "react";
import { useState } from "react";
import { makeStyles, Typography, Button, Input, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@material-ui/core";
import patternData from './data/pattern.json'

import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SaveIcon from '@material-ui/icons/Save';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';



// get date for generic filename
const current = new Date();
const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`;

// styling for the menu
const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: "column",
    },
    header: {
        width: "320px"
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexDirection: "column",
        height: "340px",
    },
    button: {
        paddingTop: "15px",
        paddingLeft: "40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "50px",
        width: "320px",
    },

});

export default function SaveView({ setSelectedFilter, setShownPatterns, setSelectedPatterns,
    setSelectedStrategy, setUserText, LOCAL_STORAGE_KEY, setSnackBarOpen, setSnackBarMessage,
    setTabState, handleMenuClose, computeShownCards, generatePdfDocument, projectName, setProjectName, setForceUpdate, forceUpdate }) {
    // This component is responsible for the menu on the top right, which allows the user to save, import and reset his business model

    const classes = useStyles();

    // States for the data to import and the filename for saving
    const [importData, setImportData] = useState("");



    // State for the reset dialog warning
    const [dialogOpen, setDialogOpen] = useState(false);


    // Reset business model if dialog is confirmed
    const handleDialogClose = (confirmed) => {
        setDialogOpen(false);
        if (confirmed)
            resetModel();
    };

    const handleDownload = () => {
        // This function handles the download of the project files in json format

        const element = document.createElement("a");
        element.href = `data:text/json;charset=utf-8,${encodeURIComponent((localStorage.getItem(LOCAL_STORAGE_KEY)))}`
        element.download = (projectName !== "" ? date + "_" + projectName + ".json" : date + "_bm2vc_result.json");
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }



    const resetModel = () => {
        // This function resets the business model and gives feedback with a snackbar

        setSelectedFilter(-1);
        setSelectedStrategy(-1);
        // reset border colors
        setShownPatterns(patternData.filter(function (pattern) {
            pattern.isCompatible = false;
            pattern.suitability = 0;
            return true
        }));
        setSelectedPatterns([]);
        setUserText([]);
        setSnackBarMessage("Geschäftsmodell zurückgesetzt");
        setSnackBarOpen(true);
        setTabState(0);
        handleMenuClose();
        setProjectName('');
    }

    const handleImport = (data) => {
        // This function handles the import of a business model
        const fileReader = new FileReader();
        fileReader.onload = e => {
            // Try to parse input data into json and return an error if its not possible
            try {
                JSON.parse(e.target.result)
            } catch (e) {
                setSnackBarMessage("Ausgewählte Datei ist nicht im gültigen Format");
                setSnackBarOpen(true);
                return
            }
            // Apply state from input data
            var newState = JSON.parse(e.target.result);
            setSelectedPatterns(newState[0]);
            setShownPatterns(computeShownCards(newState[0], newState[1]));
            setSelectedStrategy(newState[1]);
            setUserText(newState[2]);
            setProjectName(newState[3]);
            setSnackBarMessage("Geschäftsmodell wurde importiert");
            setSnackBarOpen(true);
            setForceUpdate(!forceUpdate);
        }
        fileReader.readAsText(data, "UTF-8")
        handleMenuClose();
    }


    //  Whenever a project name changes, the projectName state has to be updated accordingly
    const handleProjectNameChange = (text) => {
        setProjectName(text)
    };



    return (
        <div
            className={classes.root} key="root">
            <Typography variant="subtitle2"
                className={classes.header}>
                Hier können Sie ihr Geschäftsmodell speichern, importieren oder zurücksetzen.
            </Typography>
            <div className={classes.buttonContainer}>
                {/* Get filename input */}
                <TextField
                    inputProps={{
                        maxLength: 40,
                    }}
                    value={projectName}
                    fullWidth={true}
                    placeholder="Projektname"
                    onChange={(evt) => {
                        handleProjectNameChange(evt.target.value);
                    }} />

                {/* Allow download of the current state with a download button */}
                <Button
                    onClick={() => (handleDownload())}
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                >
                    Speichern
                </Button>

                {/* Allow download of the current state as a pdf file */}
                <Button
                    style={{ backgroundColor: "#357a38" }}
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => (generatePdfDocument())}
                    startIcon={<PictureAsPdfIcon />}
                >
                    Als PDF exportieren
                </Button>
                {/* File input */}
                <Input
                    fullWidth={true}
                    type="file"
                    onChange={(e) => {
                        setImportData(e.target.files[0])
                    }}
                >
                </Input>

                {/* Button to import file  */}
                <Button
                    className={classes.button}
                    variant="contained"
                    color="default"
                    startIcon={<CloudUploadIcon />}
                    onClick={(e) => {
                        if (importData !== "")
                            handleImport(importData)
                        else {
                            setSnackBarMessage("Keine Datei zum Importieren ausgewählt");
                            setSnackBarOpen(true);
                        }

                    }}
                >
                    Importieren
                </Button>

                {/* Button to reset business model */}
                <Button
                    onClick={() => {
                        setDialogOpen(true);
                    }}
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                >
                    Zurücksetzen
                </Button>


            </div>

            {/* Warning dialog when the user wants to reset the business model */}
            <Dialog
                open={dialogOpen}
                onClose={() => { handleDialogClose(false) }}
            >
                <DialogTitle id="alert-dialog-title">{"Möchten Sie das Geschäftsmodell wirklich zurücksetzen?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Wenn Sie das Geschäftsmodell zurücksetzen werden alle Eingaben, sowie die ausgewählten Muster und die Normstrategie, entfernt.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { handleDialogClose(false) }} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={() => { handleDialogClose(true) }} color="primary" autoFocus>
                        Bestätigen
                    </Button>
                </DialogActions>
            </Dialog>
        </div>);
}