import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Paper, IconButton, Modal, Button, TextField, ListSubheader, Typography, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import InfoIcon from '@material-ui/icons/Info';
import TextTruncate from 'react-text-truncate';



const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
    },
    rootInner: {
        display: "flex",
        flexDirection: "row",
        minHeight: "75vh",
        padding: 10,
        alignItems: "flex-start",
        alignContents: "center",
        width: "100%"
    },
    partialModels: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
    },
    columnWidth: {
        width: "16vw",
        //minWidth: "235px"
    },
    elementPaper: {
        marginBottom: "5px",
        //marginRight: "10px",
        padding: "7px",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
        //minHeight: "250px"
    },
    elementTitleBar: {
        color: "#ffffff",
        // backgroundColor: "#7fa5be",
        backgroundColor: "#8ca8cf",
        border: "2px solid #8ca8cf",
        borderRadius: "3px",
        boxShadow: "0px 3px 8px 0px rgba(0,0,0,0.4)",
        marginBottom: "20px",
    },
    partialModelInfoIcon: {
        color: "white",
        marginLeft: "auto",
        marginRight: -6
    },
    elementTitleBarTextContainer: {
        display: "flex",
        alignItems: "center"
    },
    elementNameMargin: {
        width: "100%",
        fontSize: 16,
        marginTop: "3px"

    },
    elementDescription: {
        margin: "5px",
        marginBottom: "15px"
    },
    riskElement: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
    },
    modalPaper: {
        maxHeight: "95vh",
        overflow: "auto",
        width: 650,
        //height: 930,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),

        // align items in center
        display: "flex",
        alignItems: "center",
        flexDirection: "column",

        //position the modal in center of the screen
        position: "absolute",
        top: "50%",
        left: "50%",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
    patternImage: {
        marginTop: "25px",
        padding: "5px",
        border: "1px solid #000000",
        borderRadius: "1px",
    },

}));

export default function ModelView({ selectedPatterns, userText, setUserText, forceUpdate,
    setForceUpdate, BUSINESS_MODEL, PARTIAL_MODELS, ELEMENT_ICONS, sortPatterns, contentPaperRef }) {
    // This Component contains the Geschäftsmodell tab, in which the user can further specify his business model and see the effects of his selected patterns

    const classes = useStyles();


    // Automatic scrolling to top, whenever tab changes
    useEffect(() => {
        window.scrollTo(0, 0);
        contentPaperRef.current.scrollTo(0, 0);
    }, [contentPaperRef]);

    // This array will contain the selected pattern contents, which are sorted in business model elements
    var sortedPatterns = sortPatterns(selectedPatterns);

    // States for the information modals, which show further information about the patterns 
    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalContent, setModalContent] = React.useState("");

    // Handle opening of information modals
    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };


    //  Whenever a text field changes, the userText state has to be updated accordingly
    const handleTextFieldChange = (text, id) => {
        var newUserText = userText;
        newUserText[id] = text;
        setUserText(newUserText);
    };

    return (
        <div className={classes.root} >
            <div className={classes.rootInner}>
                {/* Construct input cards from the specified business model, consisting of partial models and business model elements */}
                {PARTIAL_MODELS.map((pModel) => {
                    return (

                        <div key={pModel.id} className={classes.partialModels}>
                            <ListSubheader style={{ fontWeight: "bold" }} disableSticky={true} component="div">
                                {pModel.name}
                            </ListSubheader>
                            {BUSINESS_MODEL[pModel.id].map((element) => {
                                return (
                                    <Paper key={element.id}
                                        className={classes.elementPaper}
                                        style={{ minHeight: (window.innerHeight - pModel.count * 5) * 0.8 / pModel.count + "px" }}
                                    >
                                        <div className={classes.columnWidth}>
                                            <ListSubheader className={classes.elementTitleBar}
                                                align="center"
                                                disableSticky={true}
                                                component="div">
                                                <div key="element.id" className={classes.elementTitleBarTextContainer}>
                                                    {ELEMENT_ICONS[element.id]}
                                                    <div className={classes.elementNameMargin}>
                                                        <TextTruncate
                                                            line={1}
                                                            truncateText=""
                                                            text={element.name}
                                                        />

                                                    </div>
                                                    <Tooltip title={<Typography style={{ fontSize: 16, textAlign: "center" }}>{element.description}</Typography>}>
                                                        <IconButton
                                                            className={classes.partialModelInfoIcon}
                                                            variant="contained"
                                                            size="small"
                                                            color="primary" >
                                                            <InfoOutlinedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </ListSubheader>

                                            {/*
                                            <Typography align="center" className={classes.elementDescription} variant="subtitle2">
                                                {element.description}
                                            </Typography>
                                            /*}

                                            {/* Insert selected pattern contents from the sortedPatterns array with an IconButton, that shows the pattern info modal*/}
                                            {sortedPatterns[element.id].map((pattern) => (
                                                <TextField
                                                    label={pattern.id + ": " + pattern.name}
                                                    multiline
                                                    variant="filled"
                                                    fullWidth
                                                    value={pattern.contents[element.id]}
                                                    InputProps={{
                                                        readOnly: true,
                                                        endAdornment:
                                                            <IconButton

                                                                onClick={() => {
                                                                    setModalContent(pattern.id);
                                                                    handleModalOpen();
                                                                }}
                                                                size="small"
                                                                color="primary" >

                                                                <InfoIcon />
                                                            </IconButton>
                                                    }}
                                                />
                                            ))}

                                            {/* Textfield for the user input  */}
                                            <TextField
                                                InputProps={{ style: { fontSize: 15 } }}
                                                // Workaround to force component update when project is imported
                                                key={forceUpdate}
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rowsMax="15"
                                                defaultValue={userText[element.id]}
                                                onChange={(evt) => {
                                                    handleTextFieldChange(evt.target.value, element.id)
                                                }}
                                                on
                                            />
                                        </div>
                                    </Paper>
                                )
                            })}
                        </div>
                    )
                })}
            </div>

            {/* Risk element */}
            <div key={"risiko"} className={classes.riskElement}>
                <Paper style={{ width: "96.35%", padding: "7px" }}>
                    <ListSubheader className={classes.elementTitleBar} disableSticky={true} component="div">
                        <div className={classes.elementTitleBarTextContainer}>
                            {ELEMENT_ICONS[13]}
                            <div className={classes.elementNameMargin}
                                style={{
                                    display: "flex",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}>
                                {BUSINESS_MODEL[5].name}
                            </div>
                            <Tooltip title={<Typography style={{ fontSize: 16, textAlign: "center" }}>{BUSINESS_MODEL[5].description}</Typography>}>
                                <IconButton
                                    className={classes.partialModelInfoIcon}
                                    variant="contained"
                                    size="small"
                                    color="primary" >
                                    <InfoOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </ListSubheader>

                    {/*
                    <Typography align="center" className={classes.elementDescription} variant="subtitle2">
                        {BUSINESS_MODEL[5].description}
                    </Typography>
                    */}
                    <TextField
                        InputProps={{ style: { fontSize: 15 } }}
                        // Workaround to force component update when project is imported
                        key={forceUpdate}
                        variant="outlined"
                        fullWidth
                        multiline
                        rowsMax="15"
                        defaultValue={userText[13]}
                        onChange={(evt) => {
                            handleTextFieldChange(evt.target.value, 13)
                        }}
                    />
                </Paper>
            </div>


            {/* This is the pattern information modal */}
            {/* The content of the modal changes with the state variable modalContent */}
            <Modal key={"patternModal"}
                open={modalOpen}
                onClose={handleModalClose}
            >
                <div className={classes.modalPaper}>
                    <Typography variant={"h5"}> Weitere Informationen zum Muster</Typography>
                    <img className={classes.patternImage} height={745} src={"/cards/" + modalContent + ".png"} alt={"Kein Bild gefunden"} />
                    <Typography>
                        <Link
                            href="https://www.researchgate.net/publication/317234245_Pattern_based_business_model_development_-_identification_structuring_and_application_of_business_model_patterns"
                            target="_blank" rel="noreferrer"
                            variant="inherit">
                            Quelle: Echterhoff et al.
                        </Link>
                    </Typography>
                    <Button
                        style={{ marginTop: "20px" }}
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={handleModalClose}>
                        Ansicht schließen
                    </Button>
                </div>
            </Modal>
        </div>
    )
}