import * as React from "react";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  outerDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "15px"
  },
  innerDiv: {
    maxWidth: "800px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  header: {
    //paddingBottom: "15px",
    fontWeight: "bold"
  },
  textHeader: {
    marginTop: "30px",
    fontWeight: "bold",
    width: "100%"
  },
  sourceList: {
    listStyle: "square",
    marginLeft: "35px"
  },
  textJustify: {
    textAlign: "justify"
  },
}))








const PatternApplicationImage = (props) => {


  const classes = useStyles();


  return (

    <div className={classes.outerDiv}>
      <div className={classes.innerDiv}>

        <Typography gutterBottom className={classes.header} align="center" variant="h3">
          Anwendung
        </Typography>
        <br />
        <Typography variant="subtitle1" className={classes.textJustify}>
          Klicken Sie auf die einzelnen Moderationspläne, um sie herunterzuladen.
          <br />
          <br />
          <br />
        </Typography>


        <Link to="/interactions">
          <Button
            style={{marginBottom: "50px"}}
            size="large"
            variant="contained"
          >
            Zurück
          </Button>
        </Link>

        <svg
          width="800px"
          data-name="clickLayer"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="-10 -10 913.37668 371.94744"
          {...props}
        >
          {/* Background image */}
          <image
            width={4315}
            height={1700}
            transform="translate(.022) scale(.20703)"
            xlinkHref="/interactions/plans.jpg"
          />


          {/* Paths for clicking specific elements, hover effect is done via CSS with the id property */}

          <g
            id="clickingPaths"
            fillOpacity="0"
            stroke="#3b91cf"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="1"
          >
            <g id="planerisch"
            >
              <a
                href="/interactions/planerisch.pdf"
                download="WSR-Moderationsplan_planerisch.pdf"
              >
                <path id="svgClickElementThick" d="M0,0h291.52v351.95h-291.51Z" />
              </a>
            </g>
            <g id="gestalterisch">
              <a
                href="/interactions/gestalterisch.pdf"
                download="WSR-Moderationsplan_gestalterisch.pdf"
              >
                <path id="svgClickElementThick" d="M301.01,0h291.52v351.95h-291.51Z" />
              </a>
            </g>
            <g id="analytisch">
              <a
                href="/interactions/analytisch.pdf"
                download="WSR-Moderationsplan_analytisch.pdf"
              >
                <path id="svgClickElementThick" d="M601.86,0h291.52v351.95h-291.51Z" />
              </a>
            </g>
          </g>
        </svg>
      </div>
    </div >
  )
};

export default PatternApplicationImage;