import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import PatternCircleImage from "./patterncircleImage";

const useStyles = makeStyles((theme) => ({
    outerDiv: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "15px"
    },
    innerDiv: {
        maxWidth: "800px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    header: {
        //paddingBottom: "15px",
        fontWeight: "bold"
    },
    textHeader: {
        marginTop: "30px",
        fontWeight: "bold",
        width: "100%"
    },
    sourceList: {
        listStyle: "square",
        marginLeft: "35px"
    },
    textJustify: {
        textAlign: "justify"
    },
}))



export default function Interactions() {
    // This component shows the information page about the usage of the application

    const classes = useStyles();

    return (
        <div className={classes.outerDiv}>
            <div className={classes.innerDiv}>

                <Typography gutterBottom className={classes.header} align="center" variant="h3">
                    Wertschöpfungsstruktur
                </Typography>
                <br />
                <Typography variant="subtitle1" className={classes.textJustify}>
                    Die Wertschöpfungsstruktur beschreibt die zwischenbetriebliche Wertschöpfung eines Smart
                    Service-Geschäfts. Dabei werden vor allem die Beziehungen und Interaktionen zwischen
                    den Wertschöpfungspartnern, die für die Planung, Entwicklung, Erbringung und Abrechnung
                    eines Smart Services eine Kooperation miteinander eingehen, untersucht. Die Smart
                    Service-Strategie, das Smart Service-Geschäftsmodell und das initiale
                    Wertschöpfungssystem stellen die Grundlagen für die Wertschöpfungsstruktur dar und somit
                    für das zukünftige Wertschöpfungssystem. Zur Entwicklung des zukünftigen
                    Wertschöpfungssystems für einen Smart Service-Anbieter wurden generische
                    Wertschöpfungsrollen in Kartenform entwickelt. Die Wertschöpfungsrollen beschreiben
                    mögliche Interessensgruppen, die in dem Wertschöpfungssystem untereinander interagiere
                    und Informationen oder Leistungen miteinander austauschen. Jede Wertschöpfungsrolle
                    bringt einen Wertbeitrag in das Wertschöpfungssystem ein und stellt unterschiedliche
                    Schlüsselressourcen und -aktivitäten zur Verfügung. Für die Anwendung der
                    Wertschöpfungsrollen wurden unterschiedliche Workshopkonzepte entwickelt und in
                    Moderationspläne überführt. Die Workshopkonzepte orientieren sich dabei an dem unten
                    dargestellten Vorgehensmodell.
                </Typography>

                <Typography variant="h4" align="left" className={classes.textHeader}>
                    Navigation
                    <br/>
                    <br/>
                </Typography>

                <PatternCircleImage />

                <Typography variant="h4" align="left" className={classes.textHeader}>
                    Vorgehensmodell
                </Typography>

                <img alt="Kein Bild gefunden" src="/interactions/approach.png" width={"1000px"} style={{marginTop: "30px"}}/>

                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Phase 1: Wertorientierung und -angebot definieren
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    <p>
                        Die Smart Service-Strategie und das Smart Service-Geschäftsmodell bilden die Basis für das
                        initiale Wertschöpfungssystem, welches zugleich als Grundlage für das zukünftige
                        Wertschöpfungssystem dient. Eine Auswahl an Normstrategien und
                        Geschäftsmodellmustern werden im BM2VC Implementer für Smart Services bereitgestellt.
                    </p>
                </Typography>


                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Phase 2: Wertschöpfungsrollen identifizieren
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    <p>
                        Wertschöpfungsrollen werden anhand ihres Wertbeitrags, der Schlüsselaktivitäten und
                        Schlüsselressourcen beschrieben. Basierend dem Smart Service-Geschäftsmodell werden
                        die dafür benötigten Wertschöpfungsrollen ermittelt und ausgewählt.
                    </p>
                </Typography>

                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Phase 3: Ausgewählte Wertschöpfungsrollen präzisieren
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    <p>
                        Die in den Wertschöpfungsrollen aufgeführten generischen Schlüsselaktivitäten und
                        Schlüsselressourcen werden individuell angepasst und ergänzt.
                    </p>
                </Typography>

                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Phase 4: Ausgewählte Wertschöpfungsrollen allokieren
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    <p>
                        Jede für den Smart Service-Geschäft relevante Wertschöpfungsrolle muss entweder Fremd-
                        oder Eigenbesetzt werden. Anhand dieser Entscheidung wird die Wertschöpfungstiefe des
                        zukünftigen Wertschöpfungssystems festgelegt. Anschließend werden die Stakeholder für
                        die einzelnen Wertschöpfungsrollen ermittelt.
                    </p>
                </Typography>

                <Typography variant="h5" align="left" className={classes.textHeader}>
                    Phase 5: Beziehungen ausgestalten
                </Typography>
                <Typography className={classes.textJustify} variant="subtitle1">
                    <p>
                        Die Stakeholder und die von ihnen auszuführenden Wertschöpfungsrollen werden über
                        Beziehungen miteinander in Verbindung gebracht. Dabei werden die im
                        Wertschöpfungssystem stattfindenden Leistungs-, Informations- und Geldflüsse spezifiziert.
                    </p>
                </Typography>
            </div>
        </div >
    );
}
