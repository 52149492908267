import * as React from "react";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  outerDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "15px"
  },
  innerDiv: {
    maxWidth: "800px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  header: {
    //paddingBottom: "15px",
    fontWeight: "bold"
  },
  textHeader: {
    marginTop: "30px",
    fontWeight: "bold",
    width: "100%"
  },
  sourceList: {
    listStyle: "square",
    marginLeft: "35px"
  },
  textJustify: {
    textAlign: "justify"
  },
}))








const PatternFinderImage = (props) => {


  const classes = useStyles();

  const StyledTooltip = withStyles((theme) => ({
    tooltip: {
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: "none",
      fontSize: theme.typography.pxToRem(12),
    },
  }))(Tooltip);

  const CardTooltip = (props) => {
    return (<StyledTooltip
      placement="right"
      enterDelay={100}
      leaveDelay={20}
      maxWidth="none"
      title={<img width="300px" src={props.imagePath} alt="Bild nicht gefunden" />} >
      {props.children}
    </StyledTooltip>
    )

  };


  return (

    <div className={classes.outerDiv}>
      <div className={classes.innerDiv}>

        <Typography gutterBottom className={classes.header} align="center" variant="h3">
          Dokumentation
        </Typography>
        <br />
        <Typography variant="subtitle1" className={classes.textJustify}>
          Fahren Sie mit der Maus über die einzelnen Karten oder Kategorien der Wertschöpfungsrollen um weitere Informationen zu erhalten.
          <br />
          <br />
          <br />
        </Typography>


        <Link to="/interactions">
          <Button
            style={{ marginBottom: "50px" }}
            size="large"
            variant="contained"
          >
            Zurück
          </Button>
        </Link>


        <svg
          width={1100}
          id="main"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1278.39994 719.09997"
          {...props}
        >

          {/* Background image */}

          <g id="bgImage">
            <image
              width={8000}
              height={4500}
              transform="scale(0.1598)"
              xlinkHref="/interactions/patternfinder.png"
            />
          </g>

          {/* Paths for clicking specific elements, hover effect is done via CSS with the id property */}

          <g
            id="clickingPaths"
            fillOpacity="0"
            stroke="#3b91cf"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="0"
          >
            <g id="slices">
              <g id="_9finanz" >
                <CardTooltip imagePath="/interactions/cards/9.png">
                  <path
                    id="svgClickElementShade"
                    d="M631.6,264.15373V127.95667c85.19524-1.41079,143.0663,52.07187,143.0663,52.07187L687.12092,284.361S662.13584,262.94616,631.6,264.15373Z"
                    transform="translate(-1 -0.40999)"
                  />
                </CardTooltip>
              </g>
              <g id="_8leistungen" >
                <CardTooltip imagePath="/interactions/cards/8.png">
                  <path
                    id="svgClickElementShade"
                    d="M576.07946,284.36155l-87.54579-104.333C552.89018,124.18537,631.6,127.95667,631.6,127.95667V264.15306S598.69508,263.80842,576.07946,284.36155Z"
                    transform="translate(-1 -0.40999)"
                  />
                </CardTooltip>
              </g>
              <g id="_7kunden" >
                <CardTooltip imagePath="/interactions/cards/7.png">
                  <path
                    id="svgClickElementShade"
                    d="M546.53761,335.52953,412.40968,311.87916c13.40465-84.14592,76.124-131.85062,76.124-131.85062L576.079,284.361S550.65089,305.24788,546.53761,335.52953Z"
                    transform="translate(-1 -0.40999)"
                  />
                </CardTooltip>
              </g>
              <g id="_6integration" >
                <CardTooltip imagePath="/interactions/cards/6.png">
                  <path
                    id="svgClickElementShade"
                    d="M556.79739,393.71561,438.84727,461.81415c-43.8194-73.07585-26.43759-149.935-26.43759-149.935L546.537,335.52941S540.48367,367.87456,556.79739,393.71561Z"
                    transform="translate(-1 -0.40999)"
                  />
                </CardTooltip>
              </g>
              <g id="_5daten" >
                <CardTooltip imagePath="/interactions/cards/5.png">
                  <path
                    id="svgClickElementShade"
                    d="M602.05813,431.69388,555.476,559.67726c-80.53986-27.81278-116.62872-97.86311-116.62872-97.86311L556.79681,393.716S572.95078,422.38474,602.05813,431.69388Z"
                    transform="translate(-1 -0.40999)"
                  />
                </CardTooltip>
              </g>
              <g id="_4produkt" >
                <CardTooltip imagePath="/interactions/cards/4.png">
                  <path
                    id="svgClickElementShade"
                    d="M661.14182,431.69388,707.724,559.67726c-79.57483,30.4642-152.248,0-152.248,0L602.0579,431.69451S632.86051,443.27251,661.14182,431.69388Z"
                    transform="translate(-1 -0.40999)"
                  />
                </CardTooltip>
              </g>
              <g id="_3expert" >
                <CardTooltip imagePath="/interactions/cards/3.png">
                  <path
                    id="svgClickElementShade"
                    d="M706.40256,393.71561l117.95012,68.09854C782.97684,536.30079,707.724,559.67726,707.724,559.67726L661.14205,431.69451S692.18041,420.76424,706.40256,393.71561Z"
                    transform="translate(-1 -0.40999)"
                  />
                </CardTooltip>
              </g>
              <g id="_2infra" >
                <CardTooltip imagePath="/interactions/cards/2.png">
                  <path
                    id="svgClickElementShade"
                    d="M716.66234,335.52953l134.12793-23.65037c16.18335,83.65595-26.43759,149.935-26.43759,149.935L706.40314,393.716S723.15406,365.3918,716.66234,335.52953Z"
                    transform="translate(-1 -0.40999)"
                  />
                </CardTooltip>
              </g>
              <g id="_1ueber" >
                <CardTooltip imagePath="/interactions/cards/1.png">
                  <path
                    id="svgClickElementShade"
                    d="M687.12049,284.36155l87.54579-104.333c66.17018,53.68172,76.124,131.85062,76.124,131.85062L716.663,335.52941S711.28853,303.06457,687.12049,284.36155Z"
                    transform="translate(-1 -0.40999)"
                  />
                </CardTooltip>
              </g>
            </g>




            <g id="cards">


              <g id="_1_4">

                <CardTooltip imagePath="/interactions/cards/1-4.png">
                  <path id="svgClickElementShade" d="M939.1 136.59h59v84H939.1Z" />
                </CardTooltip>

              </g>
              <g id="_1_2">

                <CardTooltip imagePath="/interactions/cards/1-2.png">
                  <path id="svgClickElementShade" data-name="SVGID" d="M928.6 89.09h59v83H928.6Z" />
                </CardTooltip>

              </g>
              <g id="_1_3" >

                <CardTooltip imagePath="/interactions/cards/1-3.png">
                  <path id="svgClickElementShade" d="M890.6 102.09h59v83H890.6Z" />
                </CardTooltip>

              </g>
              <g id="_1_1" >

                <CardTooltip imagePath="/interactions/cards/1-1.png">
                  <path id="svgClickElementShade" d="M899.6 144.09h59v83H899.6Z" />
                </CardTooltip>

              </g>
              <g id="_2_1" >

                <CardTooltip imagePath="/interactions/cards/2-1.png">
                  <path id="svgClickElementShade" d="M928.6 366.59h59v84H928.6Z" />
                </CardTooltip>

              </g>
              <g id="_2_2" >

                <CardTooltip imagePath="/interactions/cards/2-2.png">
                  <path id="svgClickElementShade" d="M888.6 332.09h59v83H888.6Z" />
                </CardTooltip>

              </g>
              <g id="_3_5" >

                <CardTooltip imagePath="/interactions/cards/3-5.png">
                  <path id="svgClickElementShade" d="M799.6 573.09h59v83H799.6Z" />
                </CardTooltip>

              </g>
              <g id="_3_1" >

                <CardTooltip imagePath="/interactions/cards/3-1.png">
                  <path id="svgClickElementShade" d="M872.6 541.19h59v83H872.6Z" />
                </CardTooltip>

              </g>
              <g id="_3_2" >

                <CardTooltip imagePath="/interactions/cards/3-2.png">
                  <path id="svgClickElementShade" d="M826.6 553.69h59v84H826.6Z" />
                </CardTooltip>

              </g>
              <g id="_3_3" >

                <CardTooltip imagePath="/interactions/cards/3-3.png">
                  <path id="svgClickElementShade" d="M885.6 515.19h59v83H885.6Z" />
                </CardTooltip>

              </g>
              <g id="_3_4" >

                <CardTooltip imagePath="/interactions/cards/3-4.png">
                  <path id="svgClickElementShade" d="M847.6 527.19h59v83H847.6Z" />
                </CardTooltip>

              </g>
              <g id="_4_3" >

                <CardTooltip imagePath="/interactions/cards/4-3.png">
                  <path id="svgClickElementShade" d="M580.6 624.69h60.0667v84H580.6Z" />
                </CardTooltip>

              </g>
              <g id="_4_4" >

                <CardTooltip imagePath="/interactions/cards/4-4.png">
                  <path id="svgClickElementShade" d="M691.6 593.19h59v83H691.6Z" />
                </CardTooltip>

              </g>
              <g id="_4_5" >

                <CardTooltip imagePath="/interactions/cards/4-5.png">
                  <path id="svgClickElementShade" d="M634.6 622.19h59v83H634.6Z" />
                </CardTooltip>

              </g>
              <g id="_4_6" >

                <CardTooltip imagePath="/interactions/cards/4-6.png">
                  <path id="svgClickElementShade" d="M648.6 598.19h59v83H648.6Z" />
                </CardTooltip>

              </g>
              <g id="_4_1" >

                <CardTooltip imagePath="/interactions/cards/4-1.png">
                  <path id="svgClickElementShade" d="M553 593.3689h59.6v83.8211H553Z" />
                </CardTooltip>

                <g id="_4_2" >

                  <CardTooltip imagePath="/interactions/cards/4-2.png">
                    <path id="svgClickElementShade" d="M573.6 602.19h59v83H573.6Z" />
                  </CardTooltip>

                </g>
                <g id="_5_9" >

                  <CardTooltip imagePath="/interactions/cards/5-9.png">
                    <path id="svgClickElementShade" d="M324.6 549.19h59v83H324.6Z" />
                  </CardTooltip>

                </g>
                <g id="_5_1" >

                  <CardTooltip imagePath="/interactions/cards/5-1.png">
                    <path id="svgClickElementShade" d="M375.5 493.19h59v83H375.5Z" />
                  </CardTooltip>

                </g>
                <g id="_5_2" >

                  <CardTooltip imagePath="/interactions/cards/5-2.png">
                    <path id="svgClickElementShade" d="M348.5 508.19h59v83H348.5Z" />
                  </CardTooltip>

                </g>
                <g id="_5_3" >

                  <CardTooltip imagePath="/interactions/cards/5-3.png">
                    <path id="svgClickElementShade" d="M391.5 508.19h59v83H391.5Z" />
                  </CardTooltip>

                </g>
                <g id="_5_4" >

                  <CardTooltip imagePath="/interactions/cards/5-4.png">
                    <path id="svgClickElementShade" d="M344.5 529.19h59v83H344.5Z" />
                  </CardTooltip>

                </g>
                <g id="_5_5" >

                  <CardTooltip imagePath="/interactions/cards/5-5.png">
                    <path id="svgClickElementShade" d="M398 525.19h59.5v83H398Z" />
                  </CardTooltip>

                </g>
                <g id="_5_6" >

                  <CardTooltip imagePath="/interactions/cards/5-6.png">
                    <path id="svgClickElementShade" d="M376.5 561.19h59v83H376.5Z" />
                  </CardTooltip>

                </g>
                <g id="_5_7" >

                  <CardTooltip imagePath="/interactions/cards/5-7.png">
                    <path id="svgClickElementShade" d="M413.5 546.69h59v84H413.5Z" />
                  </CardTooltip>

                </g>
                <g id="_5_8" >

                  <CardTooltip imagePath="/interactions/cards/5-8.png">
                    <path id="svgClickElementShade" d="M449.5 550.19L508.5 550.19L507.799 633.891L448.799 633.891L449.5 550.19z" />
                  </CardTooltip>

                </g>
                <g id="_6_6" >

                  <CardTooltip imagePath="/interactions/cards/6-6.png">
                    <path id="svgClickElementShade" d="M313.5 379.69h59v84H313.5Z" />
                  </CardTooltip>

                </g>
                <g id="_6_1" >

                  <CardTooltip imagePath="/interactions/cards/6-1.png">
                    <path id="svgClickElementShade" d="M265.5 366.09h59v83H265.5Z" />
                  </CardTooltip>

                </g>
                <g id="_6_2" >

                  <CardTooltip imagePath="/interactions/cards/6-2.png">
                    <path id="svgClickElementShade" d="M249.5 340.09h59v83H249.5Z" />
                  </CardTooltip>

                </g>
                <g id="_6_3" >

                  <CardTooltip imagePath="/interactions/cards/6-3.png">
                    <path id="svgClickElementShade" d="M317 338.59h59v84H317Z" />
                  </CardTooltip>

                </g>
                <g id="_6_4" >

                  <CardTooltip imagePath="/interactions/cards/6-4.png">
                    <path id="svgClickElementShade" d="M283.5 326.09h59v83H283.5Z" />
                  </CardTooltip>

                </g>
                <g id="_6_5" >

                  <CardTooltip imagePath="/interactions/cards/6-5.png">
                    <path id="svgClickElementShade" d="M305.5 304.1467h59v83.9433H305.5Z" />
                  </CardTooltip>

                </g>
                <g id="_7_3" >

                  <CardTooltip imagePath="/interactions/cards/7-3.png">
                    <path id="svgClickElementShade" d="M317.5 136.09h59v83H317.5Z" />
                  </CardTooltip>

                </g>
                <g id="_7_1" >

                  <CardTooltip imagePath="/interactions/cards/7-1.png">
                    <path id="svgClickElementShade" d="M327.5 159.59h59v84H327.5Z" />
                  </CardTooltip>

                </g>
                <g id="_7_2" >

                  <CardTooltip imagePath="/interactions/cards/7-2.png">
                    <path id="svgClickElementShade" d="M364.5 125.09h59v83H364.5Z" />
                  </CardTooltip>

                </g>
                <g id="_8_3" >

                  <CardTooltip imagePath="/interactions/cards/8-3.png">
                    <path id="svgClickElementShade" d="M448.5 51.09h59v83H448.5Z" />
                  </CardTooltip>

                </g>
                <g id="_8_1" >

                  <CardTooltip imagePath="/interactions/cards/8-1.png">
                    <path id="svgClickElementShade" d="M473.5 23.09h59v83H473.5Z" />
                  </CardTooltip>

                </g>
                <g id="_8_2" >

                  <CardTooltip imagePath="/interactions/cards/8-2.png">
                    <path id="svgClickElementShade" d="M508.5 31.09h59v83H508.5Z" />
                  </CardTooltip>

                </g>
                <g id="_9_6" >

                  <CardTooltip imagePath="/interactions/cards/9-6.png">
                    <path id="svgClickElementShade" d="M775.6 48.09h59v83H775.6Z" />
                  </CardTooltip>

                </g>
                <g id="_9_1" >

                  <CardTooltip imagePath="/interactions/cards/9-1.png">
                    <path id="svgClickElementShade" d="M738.6 64.09h60v83H738.6Z" />
                  </CardTooltip>

                </g>
                <g id="_9_2" >

                  <CardTooltip imagePath="/interactions/cards/9-2.png">
                    <path id="svgClickElementShade" d="M722.6 33.09h59v83H722.6Z" />
                  </CardTooltip>

                </g>
                <g id="_9_3" >

                  <CardTooltip imagePath="/interactions/cards/9-3.png">
                    <path id="svgClickElementShade" d="M655.6 31.09h60v83H655.6Z" />
                  </CardTooltip>

                </g>
                <g id="_9_4" >

                  <CardTooltip imagePath="/interactions/cards/9-4.png">
                    <path id="svgClickElementShade" d="M690.6 20.09h59v84H690.6Z" />
                  </CardTooltip>

                </g>
                <g id="_9_5" >

                  <CardTooltip imagePath="/interactions/cards/9-5.png">
                    <path id="svgClickElementShade" d="M640.1 17.09h60v83H640.1Z" />
                  </CardTooltip>

                </g>
              </g>

            </g>
          </g>
        </svg >

        <Button
          style={{ marginTop: "50px" }}
          href="/interactions/Wertschoepfungsrollen_Kartenset.pdf"
          download="Wertschoepfungsrollen_Kartenset.pdf"
          size="large"
          variant="contained"
          color="primary"
        >
          Kartenset herunterladen
        </Button>
      </div>
    </div >
  )
};

export default PatternFinderImage;
